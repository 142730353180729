import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storage, client } from '@utils';
import { apiEndpoints, routes, common } from '@constants';
import { resetStoreToInitialState } from '@store/registration/duck';
import { CheckoutSuccess as View } from '@views';

const CheckoutSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, sessionStatus } = useSelector(store => store.registrationStore);

  useEffect(() => {
    const token = storage.getToken();
    // if user has no token (this route is public so it's possible), send him to table number page
    if (!token) {
      history.push(routes.tableNumber);
    } else {
      // if user has a token, send a request to backend to recognize if he already paid his bill
      (async () => {
        const response = await client.unauthorized.post(
          apiEndpoints.checkBillStatus,
          { sessionUserId: user.sessionUserId },
        );

        if (!response.data.isBillCaptured) {
          // if he haven't paid, send him to continue follow our session flow
          history.push(common.routeBySessionStatus[sessionStatus]);
        } else {
          // if he already paid, remove token so he won't be able to
          // visit private routes and leave him in this route
          storage.removeToken();
          dispatch(resetStoreToInitialState());
        }
      })();
    }
  }, [history, dispatch, user.sessionUserId, sessionStatus]);

  return (
    <View />
  );
};

export default CheckoutSuccess;
