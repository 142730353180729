import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CloseMyBill as View } from '@views';
import { routes } from '@constants';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { closeAllBills } from '@store/myBill/duck';

const CloseMyBill = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { closeAllBillsPhase } = useSelector(store => store.myBillStore);
  const { isHeadOfTheTable } = useSelector(store => store.registrationStore);

  const [hasServerError, setHasServerError] = useState(false);

  useEffect(() => {
    if (closeAllBillsPhase === SUCCESS) {
      history.push(routes.billReview);
    } else if (closeAllBillsPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [closeAllBillsPhase]);

  const handleConfirm = () => {
    dispatch(closeAllBills());
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const handleBackUp = () => {
    history.push(routes.myBill);
  };

  return (
    <View
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      isHeadOfTheTable={isHeadOfTheTable}
      onConfirm={handleConfirm}
      onBackUp={handleBackUp}
      isLoading={closeAllBillsPhase === LOADING}
    />
  );
};

export default CloseMyBill;
