/* eslint-disable no-shadow */
import { css } from 'styled-components';

const padding = ({ padding, theme }) => {
  switch (padding) {
    case 'none':
      return css`padding: ${theme.spacing.none};`;
    case 'xxxs':
      return css`padding: ${theme.spacing.xxxs};`;
    case 'xxs':
      return css`padding: ${theme.spacing.xxs};`;
    case 'xs':
      return css`padding: ${theme.spacing.xs};`;
    case 'sm':
      return css`padding: ${theme.spacing.sm};`;
    case 'md':
      return css`padding: ${theme.spacing.md};`;
    case 'lg':
      return css`padding: ${theme.spacing.lg};`;
    case 'xl':
      return css`padding: ${theme.spacing.xl};`;
    case 'xl2':
      return css`padding: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`padding: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`padding: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`padding: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`padding: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const paddingTop = ({ paddingTop, theme }) => {
  switch (paddingTop) {
    case 'none':
      return css`padding-top: ${theme.spacing.none};`;
    case 'xxs':
      return css`padding-top: ${theme.spacing.xxs};`;
    case 'xs':
      return css`padding-top: ${theme.spacing.xs};`;
    case 'sm':
      return css`padding-top: ${theme.spacing.sm};`;
    case 'md':
      return css`padding-top: ${theme.spacing.md};`;
    case 'lg':
      return css`padding-top: ${theme.spacing.lg};`;
    case 'xl':
      return css`padding-top: ${theme.spacing.xl};`;
    case 'xl2':
      return css`padding-top: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`padding-top: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`padding-top: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`padding-top: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`padding-top: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const paddingRight = ({ paddingRight, theme }) => {
  switch (paddingRight) {
    case 'none':
      return css`padding-right: ${theme.spacing.none};`;
    case 'xxs':
      return css`padding-right: ${theme.spacing.xxs};`;
    case 'xs':
      return css`padding-right: ${theme.spacing.xs};`;
    case 'sm':
      return css`padding-right: ${theme.spacing.sm};`;
    case 'md':
      return css`padding-right: ${theme.spacing.md};`;
    case 'lg':
      return css`padding-right: ${theme.spacing.lg};`;
    case 'xl':
      return css`padding-right: ${theme.spacing.xl};`;
    case 'xl2':
      return css`padding-right: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`padding-right: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`padding-right: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`padding-right: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`padding-right: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const paddingBottom = ({ paddingBottom, theme }) => {
  switch (paddingBottom) {
    case 'none':
      return css`padding-bottom: ${theme.spacing.none};`;
    case 'xxs':
      return css`padding-bottom: ${theme.spacing.xxs};`;
    case 'xs':
      return css`padding-bottom: ${theme.spacing.xs};`;
    case 'sm':
      return css`padding-bottom: ${theme.spacing.sm};`;
    case 'md':
      return css`padding-bottom: ${theme.spacing.md};`;
    case 'lg':
      return css`padding-bottom: ${theme.spacing.lg};`;
    case 'xl':
      return css`padding-bottom: ${theme.spacing.xl};`;
    case 'xl2':
      return css`padding-bottom: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`padding-bottom: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`padding-bottom: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`padding-bottom: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`padding-bottom: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const paddingLeft = ({ paddingLeft, theme }) => {
  switch (paddingLeft) {
    case 'none':
      return css`padding-left: ${theme.spacing.none};`;
    case 'xxs':
      return css`padding-left: ${theme.spacing.xxs};`;
    case 'xs':
      return css`padding-left: ${theme.spacing.xs};`;
    case 'sm':
      return css`padding-left: ${theme.spacing.sm};`;
    case 'md':
      return css`padding-left: ${theme.spacing.md};`;
    case 'lg':
      return css`padding-left: ${theme.spacing.lg};`;
    case 'xl':
      return css`padding-left: ${theme.spacing.xl};`;
    case 'xl2':
      return css`padding-left: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`padding-left: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`padding-left: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`padding-left: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`padding-left: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

export default {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
};
