import React from 'react';
import {
  BrowserRouter, Redirect, Switch, Route,
} from 'react-router-dom';
import { routes, common } from '@constants';
import {
  Checkout,
  CheckoutSuccess,
  CloseMyBill,
  HeadOfTheTablePin,
  HeadOfTheTableWelcome,
  MyBill,
  MyOrdo,
  PersonalDetails,
  PhoneNumber,
  BillReview,
  TableMateWelcome,
  TableMatePin,
  TipForServer,
  CustomTip,
  VerificationCode,
  FinalBillReview,
  MenuItemDetails,
  MenuCategories,
  MenuItems,
  StaticMenuItems,
  TableNumber,
  HelpMe,
  ChangeOrdoMenuItemDetails,
  ChangeOrdoMenuCategories,
  ChangeOrdoMenuItems,
  PreAuthorization,
  StaticMenu,
  StaticMenuItemDetails,
} from '@pages';
import { ScrollToTop } from '@atoms';
import AuthorizedRoute from '../AuthorizedRoute';
import UnauthorizedRoute from '../UnauthorizedRoute';

const Router = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <UnauthorizedRoute exact path={routes.tableNumber}>
        <TableNumber />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.staticMenu}>
        <StaticMenu />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.staticMenuItems}>
        <StaticMenuItems />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.staticMenuItemDetails}>
        <StaticMenuItemDetails />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.phoneNumber}>
        <PhoneNumber />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.verificationCode}>
        <VerificationCode />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.personalDetails}>
        <PersonalDetails />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.headOfTheTableWelcome}>
        <HeadOfTheTableWelcome />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.headOfTheTablePin}>
        <HeadOfTheTablePin />
      </UnauthorizedRoute>
      {common.isPreauthAvailable && (
        <UnauthorizedRoute exact path={routes.checkout}>
          <Checkout />
        </UnauthorizedRoute>
      )}
      <UnauthorizedRoute exact path={routes.tableMateWelcome}>
        <TableMateWelcome />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.tableMatePin}>
        <TableMatePin />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.changeOrdoMenuCategories}>
        <ChangeOrdoMenuCategories />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.changeOrdoMenuItems}>
        <ChangeOrdoMenuItems />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.changeOrdoMenuItemDetails}>
        <ChangeOrdoMenuItemDetails />
      </UnauthorizedRoute>
      {common.isPreauthAvailable && (
        <UnauthorizedRoute exact path={routes.preAuthorization}>
          <PreAuthorization />
        </UnauthorizedRoute>
      )}
      <AuthorizedRoute exact path={routes.helpMe}>
        <HelpMe />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.menu}>
        <MenuCategories />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.menuItems}>
        <MenuItems />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.menuItemDetails}>
        <MenuItemDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.myOrdo}>
        <MyOrdo />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.myBill}>
        <MyBill />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.closeMyBill}>
        <CloseMyBill />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.billReview}>
        <BillReview />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.tipForServer}>
        <TipForServer />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.customTip}>
        <CustomTip />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.finalBillReview}>
        <FinalBillReview />
      </AuthorizedRoute>
      <Route exact path={routes.checkoutSuccess}>
        <CheckoutSuccess />
      </Route>
      <Redirect to={routes.tableNumber} />
    </Switch>
  </BrowserRouter>
);

export default Router;
