import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HeadOfTheTablePin as View } from '@views';
import { routes } from '@constants';

const HeadOfTheTablePin = () => {
  const { tableNumber, tablePin, seatNumber } = useSelector(store => store.registrationStore);

  const history = useHistory();

  const handleProceed = () => {
    history.push({
      pathname: routes.menu,
      state: {
        shouldFetchMenu: true,
      },
    });
  };

  const handleBackUp = () => {
    history.push(routes.headOfTheTableWelcome);
  };

  return (
    <View
      tableNumber={tableNumber}
      tablePin={tablePin}
      seatNumber={seatNumber}
      onProceed={handleProceed}
      onBackUp={handleBackUp}
    />
  );
};

export default HeadOfTheTablePin;
