import React from 'react';
import {
  bool, func, string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Typography, Icon, Input, Modal,
} from '@atoms';
import { ReactComponent as Close } from '@assets/remove-item.svg';

const SpecialInstructionsModal = ({
  isModalOpen,
  specialInstructions,
  onSpecialInstructionsChange,
  onSpecialInstructionsClose,
  onModalChangeState,
}) => (
  <Modal
    isOpen={isModalOpen}
    ariaHideApp={false}
    onRequestClose={onSpecialInstructionsClose}
    shouldCloseOnOverlayClick
  >
    <ButtonClose primary onClick={onSpecialInstructionsClose}>
      <Flex justifyCenter itemsCenter>
        <Icon SVG={Close} width="20px" height="20px" />
      </Flex>
    </ButtonClose>
    <InstructionsInput
      as="textarea"
      placeholder="Add your special instructions" // TODO: add correct placehorder
      value={specialInstructions}
      onChange={onSpecialInstructionsChange}
      type="text"
    />
    <Button.Rectangular
      fullWidth
      secondary
      padding="md"
      onClick={onModalChangeState}
      marginTop="xl5"
    >
      <Typography size="lg">
        Attach note to ordo
      </Typography>
    </Button.Rectangular>
  </Modal>
);

const ButtonClose = styled(Button.Circular)`
  ${({ theme: { spacing, sizing: { modal } } }) => css`
    padding: ${spacing.xs};
    position: absolute;
    top: ${modal.buttonClose.top};
    right: ${modal.buttonClose.right};
  `}
`;

const InstructionsInput = styled(Input)`
  ${({ theme: { typography, spacing } }) => css`
    border: 0;
    font-size: ${typography.size.xl};
    font-weight: ${typography.weight.bold};
    padding: ${spacing.xl2} ${spacing.none};
    border-radius: ${spacing.none};
    resize: none;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

SpecialInstructionsModal.propTypes = {
  onSpecialInstructionsClose: func.isRequired,
  specialInstructions: string.isRequired,
  onSpecialInstructionsChange: func.isRequired,
  isModalOpen: bool.isRequired,
  onModalChangeState: func.isRequired,
};

export default SpecialInstructionsModal;
