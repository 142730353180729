import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { connectSocket } from '@store/socket/duck';
import { routes } from '@constants';

const withSocket = Component => () => {
  const { isConnected, tokenError } = useSelector(state => state.socketStore);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isConnected) {
      // TODO: POSSIBLE TABLE NUMBER AND TOKEN STORE ISSUE
      dispatch(connectSocket());
    }
  }, []);

  useEffect(() => {
    if (tokenError) {
      history.push(routes.tableNumber);
    }
  }, [tokenError]);

  return (
    <Component />
  );
};

export default withSocket;
