import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from 'styled-components';
import { ConnectionLost } from '@organisms';
import { Router } from '@router';
import { common } from '@constants';
import { configureStore } from '@store';
import { FontStyle, ResetStyle, theme } from '@styles';

export const { store, persistor } = configureStore();

const stripeOptions = {
  fonts: [{
    family: 'CeraRoundBasic',
    src: `url(/fonts/CeraRoundBasic-Regular.eot),
          url(/fonts/CeraRoundBasic-Regular.ttf),
          url(/fonts/CeraRoundBasic-Regular.woff)`,
    weight: '400',
  }],
  locale: 'en',
};

const stripe = loadStripe(common.STRIPE_PUBLIC_KEY);

const App = () => (
  <Elements stripe={stripe} options={stripeOptions}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ResetStyle />
          <FontStyle />
          <Router />
          <ConnectionLost />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Elements>
);

if (window.Cypress) {
  window.store = store;
}

export default App;
