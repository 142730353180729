import React, { useEffect } from 'react';
import {
  bool, node, func,
} from 'prop-types';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import {
  Flex, Loader, Button, BackUpButton,
} from '@atoms';
import { ErrorToast } from '@molecules';

const StaticMenu = ({
  children,
  isLoading,
  onBackUp,
  hasBackToTopButton,
  hasBackUpButton,
  hasMarginBottom,
  isFooterVisible,
  hasServerError,
  onToastClose,
}) => {
  useEffect(() => {
    document.title = 'Menu';
  }, []);

  return (
    <>
      <Wrapper
        hasMarginBottom={hasMarginBottom}
        isFooterVisible={isFooterVisible}
        id="top"
        column
      >
        <Flex column grow={0}>
          {children}
        </Flex>
        {isFooterVisible && (
          <FooterWrapper padding="sm" justifyBetween>
            <BackUpButton
              onBackUp={onBackUp}
              isShown={hasBackUpButton}
            />
          </FooterWrapper>
        )}
        {hasBackToTopButton && !isLoading && (
          <Flex selfCenter padding="xl4">
            <Link to="top" spy smooth>
              <Button.Rectangular
                primary
                gradient
                padding="xl2"
                paddingLeft="xl6"
                paddingRight="xl6"
              >
                Back to top
              </Button.Rectangular>
            </Link>
          </Flex>
        )}
      </Wrapper>
      {isLoading && <Loader />}
      {hasServerError && <ErrorToast onToastClose={onToastClose} />}
    </>
  );
};

const Wrapper = styled(Flex)`
  ${({ hasMarginBottom, isFooterVisible, theme: { sizing } }) => css`
    position: relative;

    ${isFooterVisible && hasMarginBottom && `
      min-height: calc(100% - ${sizing.footer.height});
      margin-bottom: ${sizing.footer.height};
    `}
  `}
`;

const FooterWrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    width: 100%;
    position: fixed;
    bottom: 0;
    pointer-events: none;

    ${Button.Circular}, ${Button.Rectangular} {
      pointer-events: all;
    }

    .help {
      &:active {
        svg {
          path {
            fill: ${color.strawberry};
            stroke: ${color.white};
          }
        }
      }
    }
  `}
`;

StaticMenu.defaultProps = {
  onToastClose: () => {},
  hasBackUpButton: true,
  hasBackToTopButton: false,
  isLoading: false,
  onBackUp: () => {},
  isFooterVisible: true,
  hasMarginBottom: true,
  hasServerError: false,
};

StaticMenu.propTypes = {
  hasBackUpButton: bool,
  hasBackToTopButton: bool,
  children: node.isRequired,
  isLoading: bool,
  onBackUp: func,
  isFooterVisible: bool,
  hasMarginBottom: bool,
  onToastClose: func,
  hasServerError: bool,
};

export default StaticMenu;
