import React, { useState, useEffect } from 'react';
import {
  useStripe, useElements, CardElement,
} from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Checkout as View } from '@views';
import { client } from '@utils';
import { apiEndpoints, routes, common } from '@constants';
import { preAuthorizationSocket } from '@hocs';
import { clearPreauthStatus } from '@store/registration/duck';

const Checkout = () => {
  const {
    user, tableNumber, preauthError,
  } = useSelector(state => state.registrationStore);

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (stripe) {
      const paymentReq = stripe.paymentRequest(common.STRIPE_PAYMENT_REQUEST_OBJECT);

      // Check the availability of the Payment Request API.
      paymentReq.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(paymentReq);
        }
      });

      paymentReq.on('paymentmethod', async event => {
        setIsLoading(true);
        await client.unauthorized.post(apiEndpoints.preAuthorization, {
          paymentMethodId: event.paymentMethod.id,
          userId: user.id,
          tableNumber,
          firstName: user.firstName,
          phoneNumber: user.phoneNumber,
          currentUserDate: new Date().toISOString(),
          currentWeekDay: new Date().getDay(),
          userTimezoneOffset: new Date().getTimezoneOffset(),
        });
      });
    }
  }, [stripe]);

  useEffect(() => {
    if (user.preauthStatus === common.PREAUTH_SUCCESS) {
      setIsLoading(false);
      history.push({
        pathname: routes.menu,
        state: {
          shouldFetchMenu: true,
        },
      });
    }
    if (user.preauthStatus === common.PREAUTH_FAILED) {
      setIsLoading(false);
      setError(preauthError);
    }
  }, [user.preauthStatus]);

  const handleCardChange = event => {
    setIsConfirmDisabled(!event.complete);
  };

  const handlePay = async () => {
    setIsLoading(true);
    dispatch(clearPreauthStatus());
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    await client.unauthorized.post(apiEndpoints.preAuthorization, {
      paymentMethodId: result.paymentMethod.id,
      userId: user.id,
      tableNumber,
      firstName: user.firstName,
      phoneNumber: user.phoneNumber,
      currentUserDate: new Date().toISOString(),
      currentWeekDay: new Date().getDay(),
      userTimezoneOffset: new Date().getTimezoneOffset(),
    });
  };

  return (
    <View
      paymentRequest={paymentRequest}
      isConfirmDisabled={isConfirmDisabled}
      onCardChange={handleCardChange}
      isLoading={isLoading}
      onPay={handlePay}
      error={error}
    />
  );
};

export default preAuthorizationSocket(Checkout);
