import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PhoneNumber as View } from '@views';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { addPhoneNumberToUser, submitPhoneNumber } from '@store/registration/duck';
import { routes } from '@constants';
import { isClientError } from '@helpers/errorHandling';

const PhoneNumber = () => {
  const { submitPhoneNumberError, submitPhoneNumberPhase, tableNumber } = useSelector(store => (
    store.registrationStore
  ));

  const dispatch = useDispatch();
  const history = useHistory();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [isError, setIsError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [error, setError] = useState('');
  const [isTooltipOpened, setTooltipOpen] = useState(false);

  const isPhoneNumberValid = useMemo(() => (
    Boolean(phoneNumber.length)
  ), [phoneNumber]);

  const handlePhoneNumberChange = e => {
    const { value } = e.target;

    if (e.target.validity.valid) {
      setPhoneNumber(value);
      setIsError(false);
    }
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const handlePhoneNumberSubmit = () => {
    dispatch(submitPhoneNumber({ phoneNumber: `+1${phoneNumber}` }));
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(prevIsTooltipOpen => !prevIsTooltipOpen);
  };

  const handleBackUp = () => {
    history.push(routes.tableNumber);
  };

  const handleRedirectToStaticMenu = () => {
    history.push(routes.staticMenu);
  };

  useEffect(() => {
    if (submitPhoneNumberPhase === SUCCESS) {
      dispatch(addPhoneNumberToUser({ phoneNumber: `+1${phoneNumber}` }));
      history.push(routes.verificationCode);
    } else if (submitPhoneNumberPhase === FAILURE) {
      if (submitPhoneNumberError && isClientError(submitPhoneNumberError.data.status)) { // Case when we get 4** error
        setIsError(true);
        setError(submitPhoneNumberError.data.error);
      } else {
        setHasServerError(true);
      }
    }
  }, [submitPhoneNumberPhase]);

  return (
    <View
      tableNumber={tableNumber}
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      isLoading={submitPhoneNumberPhase === LOADING}
      isError={isError}
      isPhoneNumberValid={isPhoneNumberValid}
      error={error}
      phoneNumber={phoneNumber}
      isTooltipOpened={isTooltipOpened}
      onTooltipOpen={handleTooltipOpen}
      onPhoneNumberChange={handlePhoneNumberChange}
      onPhoneNumberSubmit={handlePhoneNumberSubmit}
      onBackUp={handleBackUp}
      onRedirectToStaticMenu={handleRedirectToStaticMenu}
    />
  );
};

export default PhoneNumber;
