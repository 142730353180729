import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { Typography } from '@atoms';
import { useTimer } from '@hooks';

const PendingOrdoTimer = ({ deliversAt }) => {
  const { minutes } = useTimer(deliversAt);

  const deliveringIn = useMemo(() => (
    minutes + 1 >= 5 ? (
      <Typography data-cy="arriving" color="grannySmith" size="sm" weight="bold" marginBottom="xs">
        arriving in about
        {' '}
        <Typography as="span" color="strawberry" size="sm" weight="bold">
          {`${minutes + 1} mins`}
        </Typography>
      </Typography>
    ) : (
      <Typography data-cy="arriving-soon" color="grannySmith" size="sm" weight="bold" marginBottom="xs">
        arriving soon
      </Typography>
    )
  ), [minutes]);

  return deliveringIn;
};

PendingOrdoTimer.propTypes = {
  deliversAt: string.isRequired,
};

export default PendingOrdoTimer;
