import React from 'react';
import { bool, func } from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Typography, Icon, Modal,
} from '@atoms';
import { ReactComponent as Close } from '@assets/remove-item.svg';

const AddToOrdoModal = ({
  onGoBack,
  onCompleteOrdo,
  isAddToOrdoModalOpen,
  onAddToOrdoModalClose,
}) => (
  <Modal
    isOpen={isAddToOrdoModalOpen}
    ariaHideApp={false}
    onRequestClose={onAddToOrdoModalClose}
    shouldCloseOnOverlayClick
  >
    <ButtonClose primary onClick={onAddToOrdoModalClose}>
      <Flex justifyCenter itemsCenter>
        <Icon SVG={Close} width="20px" height="20px" />
      </Flex>
    </ButtonClose>
    <Typography centered size="xl" weight="black">
      This item has been added to your ordo
    </Typography>
    <Button.Rectangular
      fullWidth
      secondary
      padding="xl"
      onClick={onCompleteOrdo}
      marginTop="xl5"
    >
      <Typography size="lg">
        Complete my ordo
      </Typography>
    </Button.Rectangular>
    <Button.Rectangular
      fullWidth
      primaryInverted
      padding="xl"
      onClick={onGoBack}
      marginTop="md"
    >
      <Typography size="lg">
        Add more items
      </Typography>
    </Button.Rectangular>
  </Modal>
);

const ButtonClose = styled(Button.Circular)`
  ${({ theme: { spacing, sizing: { modal } } }) => css`
    padding: ${spacing.xs};
    position: absolute;
    top: ${modal.buttonClose.top};
    right: ${modal.buttonClose.right};
  `}
`;

AddToOrdoModal.propTypes = {
  onCompleteOrdo: func.isRequired,
  onGoBack: func.isRequired,
  onAddToOrdoModalClose: func.isRequired,
  isAddToOrdoModalOpen: bool.isRequired,
};

export default AddToOrdoModal;
