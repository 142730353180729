/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import io from 'socket.io-client';
import { common } from '@constants';
import { storage } from '@utils';
import {
  CONNECT_SOCKET,
  DISCONNECT_SOCKET,
  CONNECT_PRE_AUTH_SOCKET,
} from './duck';
import eventHandler from './eventHandler';

const middleware = () => {
  let socket = null;

  return ({ getState, dispatch }) => next => action => {
    const { type } = action;

    switch (type) {
      case CONNECT_SOCKET:
        if (socket !== null) {
          socket.disconnect();
        }

        const { tableNumber } = getState().registrationStore;
        const token = storage.getToken();

        // eslint-disable-next-line no-console
        console.log(common.BASE_URL);

        socket = io(common.BASE_URL, {
          transports: ['websocket'],
          query: `token=${token}&tableNumber=${tableNumber}`,
        });

        eventHandler(socket, dispatch);
        break;

      case CONNECT_PRE_AUTH_SOCKET:
        if (socket !== null) {
          socket.disconnect();
        }

        const { id } = getState().registrationStore.user;

        socket = io(common.BASE_URL, {
          transports: ['websocket'],
          query: `userId=${id}`,
        });

        eventHandler(socket, dispatch);
        break;

      case DISCONNECT_SOCKET:
        if (socket !== null) {
          socket.disconnect();
        }

        socket = null;
        break;

      default:
        return next(action);
    }
  };
};

export default middleware();
