import React from 'react';
import styled, { css } from 'styled-components';
import { bool, string, number } from 'prop-types';
import { Typography, Flex } from '@atoms';

const BillSection = ({
  tip, title, amount, isBolder,
}) => (
  <Wrapper justifyBetween tip={tip}>
    <Typography size="xl" weight={isBolder ? 'black' : 'bold'}>
      {title}
    </Typography>
    <Typography size="xl" weight={isBolder ? 'black' : 'bold'}>
      {`$${(amount / 100).toFixed(2)}`}
    </Typography>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ tip, theme: { color, spacing } }) => css`
    padding: ${spacing.xl2} ${tip ? spacing.xl5 : spacing.none};
    border-bottom: 1px solid ${color.dust};
  `}
`;

BillSection.defaultProps = {
  isBolder: false,
  tip: false,
};

BillSection.propTypes = {
  title: string.isRequired,
  amount: number.isRequired,
  isBolder: bool,
  tip: bool,
};

export default BillSection;
