import React, { useMemo } from 'react';
import {
  arrayOf, bool, exact, func, string, number,
} from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  Button, Flex, Typography, Icon,
} from '@atoms';
import { Ordo } from '@templates';
import { OrdoToPay } from '@organisms';
import { ReactComponent as HeaderIcon } from '@assets/my-bill.svg';
import { ReactComponent as Bill } from '@assets/bill.svg';

const MyBill = ({
  onToastClose,
  hasServerError,
  onBackUp,
  hasBackUpButton,
  hasConfirmBillButton,
  isLoading,
  userId,
  headOfTheTableId,
  sessionOrdos,
  onDragEnd,
  onBillConfirm,
  onPayForTheWholeTable,
}) => {
  const memoizedSessionOrdos = useMemo(() => (
    sessionOrdos.map(({
      ordoToPay, ordoUserFirstName, ordoUserId, isPayedUp,
    }) => (
      <OrdoToPay
        onPayForTheWholeTable={onPayForTheWholeTable}
        key={uuidv4()}
        userId={userId}
        isPayedUp={isPayedUp}
        ordoToPay={ordoToPay}
        ordoUserFirstName={ordoUserFirstName}
        ordoUserId={ordoUserId}
        headOfTheTableId={headOfTheTableId}
      />
    ))
  ), [sessionOrdos]);

  const confirmButtonText = 'Close My Bill';

  return (
    <Ordo
      pageTitle="My bill"
      isLoading={isLoading}
      hasServerError={hasServerError}
      onToastClose={onToastClose}
      hasBackUpButton={hasBackUpButton}
      onBackUp={onBackUp}
      title="My bill"
      headerIconSrc={HeaderIcon}
      footerChild={hasConfirmBillButton && (
        <Button.Rectangular
          secondaryInverted
          fullWidth
          padding="md"
          onClick={onBillConfirm}
        >
          <Flex itemsCenter justifyCenter>
            <Icon SVG={Bill} width="21px" height="28px" />
            <Typography size="lg" weight="black" marginLeft="sm">
              {confirmButtonText}
            </Typography>
          </Flex>
        </Button.Rectangular>
      )}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        {memoizedSessionOrdos}
      </DragDropContext>
    </Ordo>
  );
};

MyBill.propTypes = {
  hasServerError: bool.isRequired,
  onToastClose: func.isRequired,
  onBackUp: func.isRequired,
  hasBackUpButton: bool.isRequired,
  hasConfirmBillButton: bool.isRequired,
  isLoading: bool.isRequired,
  userId: number.isRequired,
  headOfTheTableId: number.isRequired,
  sessionOrdos: arrayOf(exact({
    isPayedUp: bool,
    ordoToPay: arrayOf(exact({
      id: number,
      name: string,
      price: number,
      size: string,
      specialInstructions: string,
      isVoided: bool,
      optionValues: arrayOf(exact({
        name: string,
        price: number,
        quantity: number,
        isRequired: bool,
      })),
    })),
    ordoUserFirstName: string,
    ordoUserId: number,
  })).isRequired,
  onDragEnd: func.isRequired,
  onBillConfirm: func.isRequired,
  onPayForTheWholeTable: func.isRequired,
};

export default MyBill;
