import React from 'react';
import styled, { css } from 'styled-components';
import { bool, func } from 'prop-types';
import {
  Flex, Typography, Icon, Button,
} from '@atoms';
import { ReactComponent as Back } from '@assets/back.svg';

const BackUpButton = ({ isShown, onBackUp }) => (
  <BackUpFlex
    column
    grow={0}
    selfStart
    itemsCenter
    isShown={isShown}
  >
    <Button.Circular
      diameter={58}
      primaryInverted
      marginBottom="xxs"
      onClick={onBackUp}
    >
      <Flex itemsCenter justifyCenter>
        <Icon SVG={Back} width="25px" height="25px" />
      </Flex>
    </Button.Circular>
    <Button.Rectangular padding="xs" onClick={onBackUp}>
      <Typography size="sm" weight="bold">
        BACK UP
      </Typography>
    </Button.Rectangular>
  </BackUpFlex>
);

const BackUpFlex = styled(Flex)`
  ${({ isShown }) => css`
    visibility: ${isShown ? 'visible' : 'hidden'};
  `}
`;

BackUpButton.defaultProps = {
  isShown: true,
};

BackUpButton.propTypes = {
  isShown: bool,
  onBackUp: func.isRequired,
};

export default BackUpButton;
