import axios from 'axios';
import { common, routes } from '@constants';
import { resetStoreToInitialState } from '@store/registration/duck';
import { storage } from '@utils';
import { reportError, reportMessage } from '../sentry';
import { store } from '../App';

const options = {
  baseURL: common.BASE_URL,
};

const tokenHeaderInterceptor = config => {
  const token = storage.getToken();
  const userId = storage.getSessionUserId();
  reportMessage(`Initiating request (${config.method}/ ${config.url})`, 'userId', userId, config.data);

  const configWithTokenHeader = {
    ...config,
    headers: {
      ...config.headers,
      token: token || '',
    },
  };

  return configWithTokenHeader;
};

const successInterceptor = response => {
  const userId = storage.getSessionUserId();
  reportMessage(
    `Request successful (${response.config.method}/ ${response.config.url})`,
    'userId',
    userId,
    response.config.data ? JSON.parse(response.config.data) : {},
  );
  return response;
};

const errorInterceptor = error => {
  const { status } = error.response;

  const userId = storage.getSessionUserId();
  reportError(
    `Request failed (${error.response.config.method}/ ${error.response.config.url})`,
    'userId',
    userId,
    error.response.config.data ? JSON.parse(error.response.config.data) : {},
  );

  if (status === common.UNAUTHORIZED) {
    storage.clear();
    store.dispatch(resetStoreToInitialState());
    window.location.href = routes.tableNumber;
  }

  return Promise.reject(error);
};

const requestUnauthorizedInterceptor = config => {
  const tableNumber = storage.getTableNumber();
  reportMessage(`Initiating request (${config.method}/ ${config.url})`, 'tableNumber', tableNumber, config.data);
  return config;
};

const successUnauthorizedInterceptor = response => {
  const tableNumber = storage.getTableNumber();
  reportMessage(
    `Request successful (${response.config.method}/ ${response.config.url})`,
    'tableNumber',
    tableNumber,
    response.config.data ? JSON.parse(response.config.data) : {},
  );
  return response;
};
const errorUnauthorizedInterceptor = error => {
  const tableNumber = storage.getTableNumber();
  reportError(
    `Request failed (${error.response.config.method}/ ${error.response.config.url})`,
    'tableNumber',
    tableNumber,
    error.response.config.data ? JSON.parse(error.response.config.data) : {},
  );

  return Promise.reject(error);
};

const authorized = axios.create(options);
authorized.interceptors.request.use(tokenHeaderInterceptor);
authorized.interceptors.response.use(successInterceptor, errorInterceptor);

const unauthorized = axios.create(options);
unauthorized.interceptors.request.use(requestUnauthorizedInterceptor);
unauthorized.interceptors.response.use(successUnauthorizedInterceptor, errorUnauthorizedInterceptor);

export default {
  authorized,
  unauthorized,
};
