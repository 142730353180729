import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomTip as View } from '@views';
import { routes } from '@constants';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { confirmTip } from '@store/myBill/duck';
import { isTipValid, roundTip, formatTip } from '@helpers/customTip';

const CustomTip = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [hasServerError, setHasServerError] = useState(false);

  const { confirmTipPhase, userBill } = useSelector(store => store.myBillStore);

  useEffect(() => {
    if (confirmTipPhase === SUCCESS) {
      history.push(routes.finalBillReview);
    } else if (confirmTipPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [confirmTipPhase]);

  const [tipPercent, setTipPercent] = useState(userBill.tip.tipPercent ? roundTip(userBill.tip.tipPercent) : 20);
  const [tipInCash, setTipInCash] = useState(userBill.tip.tipInCash
    ? roundTip(userBill.tip.tipInCash / 100)
    : roundTip((userBill.subtotal / 100) * 0.2));

  const handleRangeChange = e => {
    const { value } = e.target;
    const cash = (userBill.subtotal * value) / 10000;
    setTipPercent(value);
    setTipInCash(roundTip(cash));
  };

  const handleTipInCashChange = e => {
    const cash = e.target.value.replace(',', '.').replace('$', '');
    if (isTipValid(cash)) {
      const percent = (cash / userBill.subtotal) * 10000;
      setTipInCash(formatTip(cash));
      setTipPercent(roundTip(percent));
    }
  };

  const handleTipPercentChange = e => {
    const value = e.target.value.replace(',', '.');
    const percent = value.includes('%') ? value.replace('%', '') : value.slice(0, -1);
    if (isTipValid(percent)) {
      const cash = (userBill.subtotal * percent) / 10000;
      setTipPercent(formatTip(percent));
      setTipInCash(roundTip(cash));
    }
  };

  const handleConfirmTip = () => {
    const tip = {
      tipInCash: Math.round(parseFloat(tipInCash) * 100),
    };

    dispatch(confirmTip(tip));
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const handleBackUp = () => (userBill.subtotal ? history.push(routes.tipForServer) : history.push(routes.billReview));

  return (
    <View
      hasServerError={hasServerError}
      onToastClose={handleToastClose}
      onRangeChange={handleRangeChange}
      onTipPercentChange={handleTipPercentChange}
      onTipInCashChange={handleTipInCashChange}
      onConfirmTip={handleConfirmTip}
      subtotal={userBill.subtotal}
      onBackUp={handleBackUp}
      isLoading={confirmTipPhase === LOADING}
      tipInCash={tipInCash.toString()}
      tipPercent={tipPercent.toString()}
    />
  );
};

export default CustomTip;
