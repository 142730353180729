import { apiEndpoints } from '@constants';
import { client } from '@utils';

const loadMenu = () => {
  const currentDate = new Date();

  return client.authorized.get(apiEndpoints.menu, {
    params: {
      clientTimestamp: currentDate.toISOString(),
      clientWeekDay: currentDate.getDay(),
      timezoneOffset: currentDate.getTimezoneOffset(),
    },
  });
};

const loadStaticMenu = () => {
  const currentDate = new Date();

  return client.unauthorized.get(apiEndpoints.staticMenu, {
    params: {
      clientTimestamp: currentDate.toISOString(),
      clientWeekDay: currentDate.getDay(),
      timezoneOffset: currentDate.getTimezoneOffset(),
    },
  });
};

const loadMenuForStaff = token => client.unauthorized.get(apiEndpoints.menuStaff, {
  headers: {
    token,
  },
});

export {
  loadMenu,
  loadStaticMenu,
  loadMenuForStaff,
};
