import React from 'react';
import { func, string } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Typography,
} from '@atoms';
import { tipOptions } from '@constants';

const TipOptions = ({ selectedAmount, onTipAmountSelect }) => (
  <Flex justifyBetween>
    {tipOptions.map(({ amount, label }) => (
      <Wrapper
        key={uuidv4()}
        grow={0}
        column
        itemsCenter
        active={selectedAmount === amount}
      >
        <TipOption
          id={amount}
          onClick={onTipAmountSelect}
        >
          <Typography size="xl" weight="bold">
            {`${amount}%`}
          </Typography>
        </TipOption>
        <Typography size="xs" weight="bold" marginTop="xl3">
          {label}
        </Typography>
      </Wrapper>
    ))}
  </Flex>
);

const TipOption = styled(Button.Rectangular)`
  ${({ theme: { color, border, spacing } }) => css`
    padding: ${spacing.md} ${spacing.sm};
    color: ${color.black};
    box-shadow: none;
    background: ${color.lightGrey};
    border-radius: ${border.radius.tip};

    :active {
      color: ${color.white};
      background: ${color.grannySmith};
    }
  `}
`;

const Wrapper = styled(Flex)`
  ${({
    active,
    theme: { color, sizing },
  }) => css`
    max-width: ${sizing.tip.maxWidth};
    text-align: center;

    ${active && `
      color: ${color.grannySmith};

      ${TipOption} {
        color: ${color.white};
        background: ${color.grannySmith};
      }
    `}
  `}
`;

TipOptions.propTypes = {
  selectedAmount: string.isRequired,
  onTipAmountSelect: func.isRequired,
};

export default TipOptions;
