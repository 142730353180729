export const getMenuCategories = categories => categories.sortedIds.reduce((acc, id) => {
  if (categories.byId[id].active && categories.byId[id].available) {
    acc.push({
      title: categories.byId[id].name,
      id,
    });
  }

  return acc;
}, []);

export const getMenuSubcategories = (
  subcategories,
  categories,
  selectedCategoryId,
) => categories.byId[selectedCategoryId].subcategoryIds.reduce((acc, id) => {
  if (subcategories[id].active && subcategories[id].available) {
    acc.push({
      title: subcategories[id].name,
      id,
    });
  }

  return acc;
}, []);

export const getMenuItems = (
  categories,
  selectedCategoryId,
  items,
  sizes,
  subcategories,
) => categories.byId[selectedCategoryId].itemIds.reduce((acc, itemId) => {
  const {
    name, subtitle, image, subcategoryId, sizeIds, active, available,
  } = items[itemId];

  // display item if it's active, available and its subcategory is active and available or doesn't exist
  if (
    active
    && available
    && (
      !subcategoryId
      || (
        subcategoryId
        && subcategories[subcategoryId].active
        && subcategories[subcategoryId].available
      )
    )
  ) {
    const selectedSizeId = sizeIds.find(sizeId => sizes[sizeId].specialPrice)
    || sizeIds.find(sizeId => sizes[sizeId].preSelected)
    || sizeIds[0];

    const { price, specialPrice } = sizes[selectedSizeId];

    acc.push({
      name: name.toUpperCase(),
      price,
      specialPrice,
      subtitle: subtitle.toUpperCase(),
      imageSrc: image[0]?.src,
      id: itemId,
      subcategoryId: subcategoryId ? subcategoryId.toString() : '',
    });
  }

  return acc;
}, []);

export const getIsItemInViewPort = (htmlElement, topOffset, bottomOffset) => {
  if (!htmlElement) return false;
  const elementBounding = htmlElement.getBoundingClientRect();

  if (
    elementBounding.top + elementBounding.height > topOffset
    && elementBounding.bottom + bottomOffset < (window.innerHeight || document.documentElement.clientHeight)
  ) {
    return true;
  }

  return false;
};
