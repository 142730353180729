import {
  refreshBillState, applyDiscount, voidItemFromBill,
  resetStoreToInitialState as resetMyBillStoreToInitialState, updateUserBill,
} from '@store/myBill/duck';
import { events, common } from '@constants';
import { storage } from '@utils';
import {
  setSessionStatus, setIsUserLeavingEarlier,
  resetStoreToInitialState as resetRegistrationStoreToInitialState, handlePreauthSuccess, handlePreauthFailed,
} from '@store/registration/duck';
import {
  setPendingOrdos, setMyOrdo, updateArrivalTime, moveItemToOrdoHistory, setOrdoStatus, voidItemFromOrdo,
  resetStoreToInitialState as resetMyOrdoStoreToInitialState, updateOrdos,
} from '@store/myOrdo/duck';
import {
  updateItemActivity, updateCategoryActivity, updateSubcategoryActivity,
  resetStoreToInitialState as resetMenuStoreToInitialState,
} from '@store/menu/duck';

import {
  socketConnected, socketPreAuthConnected, socketDisconnected, setTokenError,
} from './duck';

const eventHandler = (socket, dispatch) => {
  socket.on(events.connect, () => {
    if (socket.connected) {
      if (socket.query && socket.query.includes('userId')) {
        dispatch(socketPreAuthConnected());
      } else {
        dispatch(socketConnected());
      }
    }
  });

  socket.on(events.preAuthorizationConfirm, data => {
    window.open(data.confirmationUrl);
  });

  socket.on(events.preAuthorizationSuccess, data => {
    const userId = parseInt(storage.getUserId(), 10);
    if (parseInt(data.userId, 10) === userId) {
      dispatch(handlePreauthSuccess(data));

      if (data.token) {
        storage.setToken(data.token);
      }

      if (data.sessionUserId) {
        storage.setSessionUserId(data.sessionUserId);
      }
    }
  });

  socket.on(events.preAuthorizationFailed, data => {
    const userId = parseInt(storage.getUserId(), 10);
    if (parseInt(data.userId, 10) === userId) {
      dispatch(handlePreauthFailed(data.error));
    }
  });

  socket.on(events.join, ({
    sessionStatus, pendingOrdos, orderedItems, ordoHistory, closingOrdoTimestamp, isPayedUp,
  }) => {
    const items = orderedItems.map(ordo => ordo.users.map(user => user.items)).flat(2);
    dispatch(setMyOrdo({
      orderedItems: items, pendingOrdos, ordoHistory, closingAt: closingOrdoTimestamp,
    }));
    dispatch(setSessionStatus({ sessionStatus, isPayedUp }));
  });

  socket.on(events.timerIsUp, data => {
    dispatch(setPendingOrdos(data.ordo));
  });

  socket.on(events.updateArrivalTime, data => {
    dispatch(updateArrivalTime(data));
  });

  socket.on(events.moveItemToOrdoHistory, data => {
    dispatch(moveItemToOrdoHistory(data));
  });

  socket.on(events.redirectToMyBill, () => {
    dispatch(setIsUserLeavingEarlier({ isLeavingEarlier: false }));
    dispatch(setSessionStatus({ sessionStatus: common.BILL_SPLITTING }));
  });

  socket.on(events.updateOrdoStatus, data => {
    dispatch(setOrdoStatus(data));
  });

  socket.on(events.refreshBillState, data => {
    dispatch(refreshBillState(data));
  });

  socket.on(events.redirectToPayMyBill, () => {
    dispatch(setSessionStatus({ sessionStatus: common.CLOSING }));
  });

  socket.on(events.disconnect, () => {
    dispatch(socketDisconnected());
  });

  socket.on(events.tokenError, error => {
    dispatch(setTokenError(error));
    storage.removeToken();
    dispatch(resetMenuStoreToInitialState());
    dispatch(resetMyBillStoreToInitialState());
    dispatch(resetMyOrdoStoreToInitialState());
    dispatch(resetRegistrationStoreToInitialState());
  });

  socket.on(events.joinedRoom, () => {});

  // menu editor events
  socket.on(events.updateItemActivity, data => {
    dispatch(updateItemActivity(data));
  });

  socket.on(events.updateCategoryActivity, data => {
    dispatch(updateCategoryActivity(data));
  });

  socket.on(events.updateSubcategoryActivity, data => {
    dispatch(updateSubcategoryActivity(data));
  });

  socket.on(events.applyDiscount, data => {
    dispatch(applyDiscount(data));
  });

  socket.on(events.voidItem, data => {
    dispatch(voidItemFromOrdo(data));
    dispatch(voidItemFromBill(data));
  });

  socket.on(events.changeOrdoInClosingSession, data => {
    dispatch(updateUserBill(data));
  });

  socket.on(events.changeOrdoInBillingSession, data => {
    dispatch(updateOrdos(data));
  });
};

export default eventHandler;
