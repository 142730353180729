import React from 'react';
import { func, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, Flex, Typography } from '@atoms';
import { FooterPin } from '@molecules';
import { Registration } from '@templates';

const hint = `The person above
is the Head of the Table (HOT). They have the pin on their screen,
and can show it to you—It’ll look like this:
`;

const TableMateWelcome = ({
  headOfTheTableFirstName,
  tableNumberToJoin,
  onTableJoin,
  onWrongTable,
}) => (
  <Registration tableNumber={tableNumberToJoin} progressValue={80} hasBackUpButton={false}>
    <Flex column itemsCenter>
      <Typography
        color="grannySmith"
        size="xl3"
        weight="black"
        marginBottom="xl5"
        data-cy="tableNumber"
      >
        {`TABLE ${tableNumberToJoin}`}
      </Typography>
      <Typography size="xl4" weight="black" marginBottom="xl5">
        <Typography data-cy="headOfTheTableFirstName" as="span" color="strawberry" size="xl4" weight="black">
          {`${headOfTheTableFirstName}’s`}
        </Typography>
        {' '}
        {`at Table ${tableNumberToJoin}.
        Would you like to join their table?`}
      </Typography>
      <Button.Rectangular
        primary
        gradient
        fullWidth
        padding="xl2"
        marginBottom="xl2"
        onClick={onTableJoin}
        data-cy="iHavePin"
      >
        They gave me the secret pin
      </Button.Rectangular>
      <Button.Rectangular
        primaryInverted
        fullWidth
        padding="xl2"
        marginBottom="xl5"
        onClick={onWrongTable}
        data-cy="wrongTable"
      >
        Whoops! Wrong table
      </Button.Rectangular>
      <HintContainer padding="xl4" column itemsCenter>
        <Typography data-cy="tooltip-hint" size="lg" weight="bold">
          {hint}
        </Typography>
        <Flex marginTop="xl">
          <FooterPin isTablePinVisible tableNumber={tableNumberToJoin} tablePin="9876" />
        </Flex>
      </HintContainer>
    </Flex>
  </Registration>
);

const HintContainer = styled(Flex)`
  ${({ theme: { color } }) => css`
    border: 1px solid ${color.dust};
    border-radius: 20px;
  `}
`;

TableMateWelcome.propTypes = {
  headOfTheTableFirstName: string.isRequired,
  tableNumberToJoin: string.isRequired,
  onTableJoin: func.isRequired,
  onWrongTable: func.isRequired,
};

export default TableMateWelcome;
