import React, { useEffect } from 'react';
import {
  bool, node, func, number, string,
} from 'prop-types';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import { Flex, Loader, Button } from '@atoms';
import { ErrorToast } from '@molecules';
import { Footer } from '@organisms';

const Menu = ({
  isPlaceOrdoVisible,
  tablePin,
  tableNumber,
  seatNumber,
  onToastClose,
  hasServerError,
  children,
  isLoading,
  onBackUp,
  isFooterVisible,
  hasBackToTopButton,
  hasBackUpButton,
  hasMarginBottom,
  selectedMenuItemsQuantity,
  ordoClosingAt,
  onOrdoPlace,
  isStatic,
}) => {
  useEffect(() => {
    document.title = 'Menu';
  }, []);

  return (
    <>
      <Wrapper
        hasMarginBottom={hasMarginBottom}
        isFooterVisible={isFooterVisible}
        id="top"
        column
      >
        <Flex column grow={0}>
          {children}
        </Flex>
        {isFooterVisible && (
          <Footer
            isTablePinVisible
            seatNumber={seatNumber}
            tablePin={tablePin}
            selectedMenuItemsQuantity={selectedMenuItemsQuantity}
            ordoClosingAt={ordoClosingAt}
            onOrdoPlace={onOrdoPlace}
            isPlaceOrdoVisible={isPlaceOrdoVisible}
            onBackUp={onBackUp}
            hasBackUpButton={hasBackUpButton}
            tableNumber={tableNumber}
            isHelpMeVisible={!isStatic}
          />
        )}
        {hasBackToTopButton && !isLoading && (
          <Flex selfCenter padding="xl4">
            <Link to="top" spy smooth>
              <Button.Rectangular
                primary
                gradient
                padding="xl2"
                paddingLeft="xl6"
                paddingRight="xl6"
              >
                Back to top
              </Button.Rectangular>
            </Link>
          </Flex>
        )}
      </Wrapper>
      {isLoading && <Loader />}
      {hasServerError && <ErrorToast onToastClose={onToastClose} />}
    </>
  );
};

const Wrapper = styled(Flex)`
  ${({ hasMarginBottom, isFooterVisible, theme: { sizing } }) => css`
    position: relative;

    ${isFooterVisible && hasMarginBottom && `
      min-height: calc(100% - ${sizing.footer.height});
      margin-bottom: ${sizing.footer.height};
    `}
  `}
`;

Menu.defaultProps = {
  onToastClose: () => {},
  hasServerError: false,
  hasBackUpButton: true,
  hasBackToTopButton: false,
  isLoading: false,
  onBackUp: () => {},
  isFooterVisible: true,
  hasMarginBottom: true,
  isStatic: false,
};

Menu.propTypes = {
  isPlaceOrdoVisible: bool.isRequired,
  tableNumber: string.isRequired,
  tablePin: string.isRequired,
  seatNumber: string.isRequired,
  onToastClose: func,
  hasServerError: bool,
  hasBackUpButton: bool,
  hasBackToTopButton: bool,
  children: node.isRequired,
  isLoading: bool,
  onBackUp: func,
  isFooterVisible: bool,
  hasMarginBottom: bool,
  selectedMenuItemsQuantity: number.isRequired,
  ordoClosingAt: string.isRequired,
  onOrdoPlace: func.isRequired,
  isStatic: bool,
};

export default Menu;
