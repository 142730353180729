import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { sentryReduxEnhancer } from '../sentry';
import socket from './socket/middleware';
import rootReducer from './rootReducer';

const configureStore = () => {
  const middleware = [thunk, socket];

  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer),
  );

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
