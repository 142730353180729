const events = {
  connect: 'connect',
  join: 'join',
  joinedRoom: 'joinedRoom',
  moveItemToOrdoHistory: 'moveItemToOrdoHistory',
  redirectToMyBill: 'redirectToMyBill',
  redirectToPayMyBill: 'redirectToPayMyBill',
  updateArrivalTime: 'updateArrivalTime',
  updateOrdoStatus: 'updateOrdoStatus',
  refreshBillState: 'refreshBillState',
  timerIsUp: 'timerIsUp',
  disconnect: 'disconnect',
  tokenError: 'tokenError',
  applyDiscount: 'applyDiscount',
  // menu editor events
  updateItemActivity: 'updateItemActivity',
  updateCategoryActivity: 'updateCategoryActivity',
  updateSubcategoryActivity: 'updateSubcategoryActivity',
  voidItem: 'voidItem',
  changeOrdoInClosingSession: 'changeOrdoInClosingSession',
  changeOrdoInBillingSession: 'changeOrdoInBillingSession',
  preAuthorizationConfirm: 'preAuthorizationConfirm',
  preAuthorizationSuccess: 'preAuthorizationSuccess',
  preAuthorizationFailed: 'preAuthorizationFailed',
};

export default events;
