import {
  bool, func, oneOf,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { marginMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const Input = styled.input`
  ${({
    isError,
    theme: {
      border, color, spacing, typography,
    },
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    width: 100%;
    border: 1.5px solid;
    border-color: ${isError ? color.strawberry : color.black}}
    border-radius: ${border.radius.default};
    padding: ${spacing.xl};
    font-family: ${typography.family};
    font-size: ${typography.size.xl2};
    font-weight: ${typography.weight.black};
    line-height: normal;
    text-align: center;
    outline: none;

    ::placeholder {
      color: ${color.stone};
    }
  `}
`;

Input.defaultProps = {
  isError: false,
  type: 'text',
  onChange: () => null,
};

Input.propTypes = {
  isError: bool,
  type: oneOf([
    'email',
    'number',
    'tel',
    'text',
  ]),
  onChange: func,
};

export default Input;
