/* eslint-disable no-shadow */
import { css } from 'styled-components';

const margin = ({ margin, theme }) => {
  switch (margin) {
    case 'none':
      return css`margin: ${theme.spacing.none};`;
    case 'xxs':
      return css`margin: ${theme.spacing.xxs};`;
    case 'xs':
      return css`margin: ${theme.spacing.xs};`;
    case 'sm':
      return css`margin: ${theme.spacing.sm};`;
    case 'md':
      return css`margin: ${theme.spacing.md};`;
    case 'lg':
      return css`margin: ${theme.spacing.lg};`;
    case 'xl':
      return css`margin: ${theme.spacing.xl};`;
    case 'xl2':
      return css`margin: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`margin: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`margin: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`margin: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`margin: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const marginTop = ({ marginTop, theme }) => {
  switch (marginTop) {
    case 'none':
      return css`margin-top: ${theme.spacing.none};`;
    case 'xxs':
      return css`margin-top: ${theme.spacing.xxs};`;
    case 'xs':
      return css`margin-top: ${theme.spacing.xs};`;
    case 'sm':
      return css`margin-top: ${theme.spacing.sm};`;
    case 'md':
      return css`margin-top: ${theme.spacing.md};`;
    case 'lg':
      return css`margin-top: ${theme.spacing.lg};`;
    case 'xl':
      return css`margin-top: ${theme.spacing.xl};`;
    case 'xl2':
      return css`margin-top: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`margin-top: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`margin-top: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`margin-top: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`margin-top: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const marginRight = ({ marginRight, theme }) => {
  switch (marginRight) {
    case 'none':
      return css`margin-right: ${theme.spacing.none};`;
    case 'xxs':
      return css`margin-right: ${theme.spacing.xxs};`;
    case 'xs':
      return css`margin-right: ${theme.spacing.xs};`;
    case 'sm':
      return css`margin-right: ${theme.spacing.sm};`;
    case 'md':
      return css`margin-right: ${theme.spacing.md};`;
    case 'lg':
      return css`margin-right: ${theme.spacing.lg};`;
    case 'xl':
      return css`margin-right: ${theme.spacing.xl};`;
    case 'xl2':
      return css`margin-right: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`margin-right: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`margin-right: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`margin-right: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`margin-right: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const marginBottom = ({ marginBottom, theme }) => {
  switch (marginBottom) {
    case 'none':
      return css`margin-bottom: ${theme.spacing.none};`;
    case 'xxs':
      return css`margin-bottom: ${theme.spacing.xxs};`;
    case 'xs':
      return css`margin-bottom: ${theme.spacing.xs};`;
    case 'sm':
      return css`margin-bottom: ${theme.spacing.sm};`;
    case 'md':
      return css`margin-bottom: ${theme.spacing.md};`;
    case 'lg':
      return css`margin-bottom: ${theme.spacing.lg};`;
    case 'xl':
      return css`margin-bottom: ${theme.spacing.xl};`;
    case 'xl2':
      return css`margin-bottom: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`margin-bottom: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`margin-bottom: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`margin-bottom: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`margin-bottom: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

const marginLeft = ({ marginLeft, theme }) => {
  switch (marginLeft) {
    case 'none':
      return css`margin-left: ${theme.spacing.none};`;
    case 'xxs':
      return css`margin-left: ${theme.spacing.xxs};`;
    case 'xs':
      return css`margin-left: ${theme.spacing.xs};`;
    case 'sm':
      return css`margin-left: ${theme.spacing.sm};`;
    case 'md':
      return css`margin-left: ${theme.spacing.md};`;
    case 'lg':
      return css`margin-left: ${theme.spacing.lg};`;
    case 'xl':
      return css`margin-left: ${theme.spacing.xl};`;
    case 'xl2':
      return css`margin-left: ${theme.spacing.xl2};`;
    case 'xl3':
      return css`margin-left: ${theme.spacing.xl3};`;
    case 'xl4':
      return css`margin-left: ${theme.spacing.xl4};`;
    case 'xl5':
      return css`margin-left: ${theme.spacing.xl5};`;
    case 'xl6':
      return css`margin-left: ${theme.spacing.xl6};`;
    default:
      return null;
  }
};

export default {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
};
