import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MyOrdo as View } from '@views';
import { routes, common } from '@constants';
import { LOADING, FAILURE, SUCCESS } from '@constants/requestPhase';
import { withSocket } from '@hocs';
import { getCurrentOrdo, getOrderedItems, getClosedOrdos } from '@helpers/myOrdo';
import { createPrintDetails } from '@helpers/printing';
import { setIsUserLeavingEarlier, getOneTimeToken } from '@store/registration/duck';
import { removeItemFromOrdo, placeOrdo, closeAllBills } from '@store/myOrdo/duck';

const MyOrdo = () => {
  const {
    currentOrdo,
    orderedItems,
    pendingOrdos,
    ordoHistory,
    placeOrdoPhase,
    closeAllBillsPhase,
    ordo,
  } = useSelector(store => store.myOrdoStore);

  const { user, isHeadOfTheTable, getOneTimeTokenPhase } = useSelector(store => store.registrationStore);
  // eslint-disable-next-line no-unused-vars
  const { tableNumber, seatNumber } = useSelector(state => state.registrationStore);
  const [itemToEditId, setItemToEditId] = useState(null);
  const [hasServerError, setHasServerError] = useState(false);

  const {
    categories, optionValues, items, sizes, options,
  } = useSelector(store => store.menuStore);

  const isOrdoPlacedByCurrentUser = Boolean(orderedItems.length);
  const isOrdoPlaced = ((ordo.status === common.ORDO_CLOSING) || isOrdoPlacedByCurrentUser);
  const isConfirmAllBillsDisabled = isHeadOfTheTable
    ? Boolean(ordo.status === common.ORDO_CLOSING)
    : Boolean(orderedItems.length);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (placeOrdoPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [placeOrdoPhase]);

  useEffect(() => {
    if (closeAllBillsPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [closeAllBillsPhase]);

  useEffect(() => {
    if (getOneTimeTokenPhase === FAILURE) {
      setHasServerError(true);
    }
    if (getOneTimeTokenPhase === SUCCESS) {
      const itemToEdit = orderedItems.find(item => item.id === itemToEditId);
      const encodedItem = encodeURIComponent(JSON.stringify(itemToEdit));
      history.push(`${routes.changeOrdoMenuItems}/${itemToEdit.menuItemId}
      ?menuItem=${encodedItem}&token=${user.oneTimeToken}`);
    }
  }, [getOneTimeTokenPhase]);

  const handleClosingOrdoItemEdit = itemId => () => {
    setItemToEditId(itemId);
    dispatch(getOneTimeToken());
  };

  const handleBackUp = () => {
    history.push(routes.menu);
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const handleBillConfirm = () => {
    if (isHeadOfTheTable) {
      dispatch(closeAllBills());
    } else {
      dispatch(setIsUserLeavingEarlier({ isLeavingEarlier: true }));
      history.push(routes.myBill);
    }
  };

  const handlePlaceOrdo = () => {
    const menuItems = currentOrdo.map(curOrdo => ({
      id: curOrdo.id,
      specialInstructions: curOrdo.specialInstructions,
      size: curOrdo.size,
    }));
    const printDetails = createPrintDetails(
      getCurrentOrdo(currentOrdo, items, optionValues, sizes, options),
      user,
      categories,
      tableNumber,
    );
    dispatch(placeOrdo({ menuItems, printDetails }));
  };

  const handleItemRemove = ordoItemKey => () => {
    dispatch(removeItemFromOrdo(ordoItemKey));
  };

  const memoizedCurrentOrdo = useMemo(() => (
    getCurrentOrdo(currentOrdo, items, optionValues, sizes, options)
  ), [currentOrdo]);

  const memoizedOrderedItems = useMemo(() => (
    getOrderedItems(orderedItems, sizes, items, optionValues, options)
  ), [orderedItems]);

  const memoizedPendingOrdos = useMemo(() => (
    getClosedOrdos(pendingOrdos, sizes, items, optionValues, options)
  ), [pendingOrdos]);

  const memoizedOrdoHistory = useMemo(() => (
    getClosedOrdos(ordoHistory, sizes, items, optionValues, options)
  ), [ordoHistory]);

  return (
    <View
      onClosingOrdoItemEdit={handleClosingOrdoItemEdit}
      hasServerError={hasServerError}
      onToastClose={handleToastClose}
      isOrdoPlaced={isOrdoPlaced}
      closingAt={ordo.closingAt}
      pendingOrdos={memoizedPendingOrdos}
      isOrdoPlacedByCurrentUser={isOrdoPlacedByCurrentUser}
      isConfirmAllBillsDisabled={isConfirmAllBillsDisabled}
      isHeadOfTheTable={isHeadOfTheTable}
      onBillConfirm={handleBillConfirm}
      isLoading={(placeOrdoPhase === LOADING) || (closeAllBillsPhase === LOADING)
      || getOneTimeTokenPhase === LOADING}
      userId={user.sessionUserId}
      orderedItems={memoizedOrderedItems}
      currentOrdo={memoizedCurrentOrdo}
      ordoHistory={memoizedOrdoHistory}
      onOrdoPlace={handlePlaceOrdo}
      onDelete={() => {}}
      onBackUp={handleBackUp}
      onItemRemove={handleItemRemove}
    />
  );
};

export default withSocket(MyOrdo);
