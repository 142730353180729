export const getMenuCategories = categories => categories.sortedIds.reduce((acc, key) => {
  if (categories.byId[key].active && categories.byId[key].available) {
    acc.push({
      title: categories.byId[key].name,
      imageSrc: categories.byId[key].image,
      id: key,
    });
  }

  return acc;
}, []);
