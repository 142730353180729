import React from 'react';
import styled, { css } from 'styled-components';
import {
  Flex, Typography, Button, Icon,
} from '@atoms';
import { useNetwork } from '@hooks';
import { getRestaurantRelatedData } from '@helpers/registration';

const ConnectionLost = () => {
  const isOnline = useNetwork();

  const onReconnectClick = () => {
    window.location.reload();
  };

  const { logo } = getRestaurantRelatedData();

  return !isOnline && (
    <Wrapper padding="xl" column itemsCenter justifyStart>
      <Flex grow={0} justifyCenter padding="xl6">
        <Icon SVG={logo} width="154px" height="20px" />
      </Flex>
      <Typography centered marginTop="xl5" marginBottom="xl3" weight="black" size="xl3">
        Looks like you&apos;ve lost your Internet connection :(
      </Typography>
      <Button.Rectangular onClick={onReconnectClick} padding="lg" fullWidth primary>
        Try to reconnect
      </Button.Rectangular>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background: ${color.white};
  `}
`;

export default ConnectionLost;
