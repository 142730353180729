import React from 'react';
import { bool, func, number } from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Icon, Typography,
} from '@atoms';
import { ReactComponent as Minus } from '@assets/minus.svg';
import { ReactComponent as Plus } from '@assets/plus.svg';

const OptionQuantityCounter = ({
  id, optionId, quantity, onOptionQuantityDecrement, onOptionQuantityIncrement, allowValueMultipleSelection,
}) => (
  <Wrapper isShown={allowValueMultipleSelection && quantity} selfCenter padding="xxxs" selfEnd grow={0} itemsCenter>
    <Flex grow={0} itemsCenter>
      <Button.Circular primary diameter={14} onClick={onOptionQuantityDecrement(id, optionId)}>
        <Flex itemsCenter justifyCenter>
          <Icon SVG={Minus} width="6px" height="2px" className="strawberry" />
        </Flex>
      </Button.Circular>
      <Typography color="black" paddingLeft="xs" paddingRight="xs" size="sm" weight="black">
        {quantity}
      </Typography>
      <Button.Circular secondary diameter={14} onClick={onOptionQuantityIncrement(id, optionId)}>
        <Flex itemsCenter justifyCenter>
          <Icon SVG={Plus} width="5.5px" height="5.5px" className="grannySmith" />
        </Flex>
      </Button.Circular>
    </Flex>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ isShown, theme: { border, color } }) => css`
    visibility: ${isShown ? 'visible' : 'hidden'};
    border-radius: ${border.radius.default};
    background: ${color.white};
    border: 1px solid ${color.dust};
  `}
`;

OptionQuantityCounter.propTypes = {
  allowValueMultipleSelection: bool.isRequired,
  id: number.isRequired,
  optionId: number.isRequired,
  quantity: number.isRequired,
  onOptionQuantityDecrement: func.isRequired,
  onOptionQuantityIncrement: func.isRequired,
};

export default OptionQuantityCounter;
