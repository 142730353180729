import React from 'react';
import {
  bool, func, string, number, oneOfType, oneOf, node,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Flex, Button, Typography, Icon, BackUpButton,
} from '@atoms';
import {
  PlaceOrdo, FooterPin, FooterTimer,
} from '@molecules';
import { ReactComponent as Chat } from '@assets/chat.svg';
import { routes } from '@constants';

const Footer = ({
  seatNumber,
  children,
  onBackUp,
  hasBackUpButton,
  hasHelpMeButton,
  isPlaceOrdoVisible,
  selectedMenuItemsQuantity,
  ordoClosingAt,
  onOrdoPlace,
  tableNumber,
  isHelpMeVisible,
}) => {
  const history = useHistory();

  const onHelpMe = () => {
    history.push(routes.helpMe);
  };

  return (
    <Wrapper padding="sm" justifyBetween>
      <BackUpButton
        onBackUp={onBackUp}
        isShown={hasBackUpButton}
      />
      <Flex
        selfStart
        marginRight={children ? 'sm' : 'none'}
        marginLeft={children ? 'sm' : 'none'}
      >
        {children || (
          <>
            <FooterTimer
              ordoClosingAt={ordoClosingAt}
            />
            <PlaceOrdo
              isPlaceOrdoVisible={isPlaceOrdoVisible}
              selectedMenuItemsQuantity={selectedMenuItemsQuantity}
              ordoClosingAt={ordoClosingAt}
              onOrdoPlace={onOrdoPlace}
            />
            <FooterPin
              isTablePinVisible={!!tableNumber}
              seatNumber={seatNumber}
              tableNumber={tableNumber}
            />
          </>
        )}
      </Flex>
      {isHelpMeVisible && (
        <HelpMeFlex
          hasHelpMeButton={hasHelpMeButton}
          itemsCenter
          column
          grow={0}
          selfEnd
        >
          <Button.Circular
            className="help"
            diameter={58}
            primaryInverted
            marginBottom="xxs"
            onClick={onHelpMe}
          >
            <Flex itemsCenter justifyCenter>
              <Icon SVG={Chat} width="25px" height="25px" />
            </Flex>
          </Button.Circular>
          <Button.Rectangular onClick={onHelpMe} padding="xs">
            <Typography size="sm" weight="bold">
              HELP ME!
            </Typography>
          </Button.Rectangular>
        </HelpMeFlex>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    width: 100%;
    position: fixed;
    bottom: 0;
    pointer-events: none;

    ${Button.Circular}, ${Button.Rectangular} {
      pointer-events: all;
    }

    .help {
      &:active {
        svg {
          path {
            fill: ${color.strawberry};
            stroke: ${color.white};
          }
        }
      }
    }
  `}
`;

const HelpMeFlex = styled(Flex)`
  ${({ hasHelpMeButton }) => css`
    visibility: ${hasHelpMeButton ? 'visible' : 'hidden'};
  `}
`;

Footer.defaultProps = {
  seatNumber: '',
  selectedMenuItemsQuantity: 0,
  ordoClosingAt: '',
  onOrdoPlace: () => {},
  tableNumber: '',
  isPlaceOrdoVisible: false,
  hasHelpMeButton: true,
  hasBackUpButton: true,
  onBackUp: () => {},
  children: null,
  isHelpMeVisible: true,
};

Footer.propTypes = {
  seatNumber: string,
  tableNumber: string,
  children: oneOfType([oneOf([null]), node]),
  selectedMenuItemsQuantity: number,
  ordoClosingAt: string,
  onOrdoPlace: func,
  isPlaceOrdoVisible: bool,
  hasHelpMeButton: bool,
  hasBackUpButton: bool,
  onBackUp: func,
  isHelpMeVisible: bool,
};

export default Footer;
