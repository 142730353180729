import { ReactComponent as MadisonsLogo } from '@assets/madisons-logo.svg';
import { ReactComponent as GolfutureLogo } from '@assets/golfuture-logo.svg';
import { ReactComponent as GeorgesLogo } from '@assets/georges-logo.svg';
import { ReactComponent as BoredBrewingLogo } from '@assets/boredbrewing-logo.svg';
import { restaurantContactPhone } from '@constants/common';

export const getParameterByName = (name, url) => {
  const formattedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${formattedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getRestaurantRelatedData = () => {
  switch (window.location.host) {
    case 'golfuture.ordo.menu':
      return {
        logo: GolfutureLogo,
        contactPhone: restaurantContactPhone.golfuture,
      };
    case 'georges.ordo.menu':
      return {
        logo: GeorgesLogo,
        contactPhone: restaurantContactPhone.georges,
      };
    case 'boredbrewing.ordo.menu':
      return {
        logo: BoredBrewingLogo,
        contactPhone: restaurantContactPhone.georges,
        logoWidth: '400px',
        logoHeight: '150px',
      };
    default:
      return {
        logo: MadisonsLogo,
        contactPhone: restaurantContactPhone.madisons,
        logoWidth: '300px',
        logoHeight: '150px',
      };
  }
};
