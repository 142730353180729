import React, { memo, useCallback } from 'react';
import {
  arrayOf, bool, exact, string, number, func,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Flex, Icon, Typography, Button,
} from '@atoms';
import { OrdoItem } from '@molecules';
import { ReactComponent as Crown } from '@assets/crown.svg';
import { ReactComponent as Placeholder } from '@assets/paid.svg';

const OrdoToPay = ({
  userId,
  isPayedUp,
  ordoToPay,
  ordoUserFirstName,
  ordoUserId,
  headOfTheTableId,
  onPayForTheWholeTable,
}) => {
  const ordoUserFirstNameWhoPays = (
    ordoUserId === userId
      ? "I'm paying for:"
      : `${ordoUserFirstName}'s paying for:`
  );

  const ordoToPayTitle = useCallback(() => (
    isPayedUp
      ? `${ordoUserFirstName}'s all paid up!`
      : ordoUserFirstNameWhoPays
  ), [isPayedUp, ordoUserFirstName]);

  const ConditionalDraggableWrapper = ({ condition, wrapper, children }) => (
    condition ? wrapper(children) : children
  );

  return (
    <Wrapper column padding="xl5">
      <Flex itemsCenter marginBottom="xl">
        {headOfTheTableId === ordoUserId && (
          <Icon SVG={Crown} width="20px" height="20px" marginRight="sm" />
        )}
        {isPayedUp && (
          <Icon SVG={Placeholder} width="55px" height="55px" marginRight="sm" />
        )}
        <Typography size="xl" weight="black">
          {ordoToPayTitle()}
        </Typography>
      </Flex>
      {!ordoToPay.length && userId === headOfTheTableId && !isPayedUp && (
        <Typography size="sm" color="stone" weight="bold" marginBottom="lg">
          Add something! Drag an item here
        </Typography>
      )}
      <Droppable isDropDisabled={isPayedUp} droppableId={ordoUserId.toString()}>
        {provided => (
          <Flex column ref={provided.innerRef} {...provided.droppableProps}>
            {ordoToPay.map(({
              id, name, price, optionValues, size, isRequired, specialInstructions, isVoided,
            }, index) => (
              <ConditionalDraggableWrapper
                condition={userId === headOfTheTableId && !isPayedUp && !isVoided}
                key={id}
                wrapper={children => (
                  <Draggable draggableId={id.toString()} index={index}>
                    {({ innerRef, dragHandleProps, draggableProps }) => (
                      <Flex
                        ref={innerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        {children}
                      </Flex>
                    )}
                  </Draggable>
                )}
              >
                <OrdoItem
                  specialInstructions={specialInstructions}
                  isRequired={isRequired}
                  size={size}
                  isPayedUp={isPayedUp || isVoided}
                  name={name}
                  price={price}
                  optionValues={optionValues}
                />
              </ConditionalDraggableWrapper>
            ))}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
      {(headOfTheTableId === ordoUserId) && (headOfTheTableId === userId) && (
        <PayForTheWholeTableButton secondaryInverted>
          <Typography size="xs" weight="bold" onClick={onPayForTheWholeTable}>
            Pay for the whole table
          </Typography>
        </PayForTheWholeTableButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
    border-bottom: 1px solid ${color.dust};
    position: relative;

    &:nth-of-type(2n-1) {
      background: ${color.lightGrey};
    }

    &:nth-of-type(2n) {
      border: none;
    }
  `}
`;

const PayForTheWholeTableButton = styled(Button.Rectangular)`
  ${({ theme: { spacing, sizing } }) => css`
    padding: ${spacing.xs} ${spacing.xl};
    border-radius: ${sizing.payForTheWholeTableButton.borderRadius};
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    bottom: 0;
    right: ${sizing.payForTheWholeTableButton.right};;
  `}
`;

OrdoToPay.defaultProps = {
  isPayedUp: false,
};

OrdoToPay.propTypes = {
  onPayForTheWholeTable: func.isRequired,
  userId: number.isRequired,
  isPayedUp: bool,
  ordoToPay: arrayOf(exact({
    id: number,
    name: string,
    price: number,
    specialInstructions: string,
    isVoided: bool,
    size: string,
    optionValues: arrayOf(exact({
      name: string,
      price: number,
      quantity: number,
      isRequired: bool,
    })),
  })).isRequired,
  ordoUserFirstName: string.isRequired,
  ordoUserId: number.isRequired,
  headOfTheTableId: number.isRequired,
};

export default memo(OrdoToPay);
