import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { common } from '@constants';
import { storage } from '@utils';
import * as api from './api';

// < ----- ACTIONS ----- > //
const addPhoneNumberToUserAction = 'ADD_PHONE_NUMBER_TO_USER';
const addTableNumberAction = 'ADD_TABLE_NUMBER';
export const resetStoreToInitialStateAction = 'RESET_STORE_TO_INITIAL_STATE';
export const setSessionStatusAction = 'SET_SESSION_STATUS';
const setIsUserLeavingEarlierAction = 'SET_IS_USER_LEAVING_EARLIER';
const saveVerificationCodeAction = 'SAVE_VERIFICATION_CODE';
const clearOneTimeTokenAction = 'CLEAR_ONE_TIME_TOKEN';
const handlePreauthSuccessAction = 'HANDLE_PREAUTH_SUCCESS';
const handlePreauthFailedAction = 'HANDLE_PREAUTH_FAILED';
const clearPreauthStatusAction = 'CLEAR_PREAUTH_STATUS';

const getOneTimeTokenRoutine = createRoutine(
  'GET_ONE_TIME_TOKEN',
);

const submitPhoneNumberRoutine = createRoutine(
  'SUBMIT_PHONE_NUMBER',
);

const submitVerificationCodeRoutine = createRoutine(
  'SUBMIT_VERIFICATION_CODE',
);

const submitPersonalDetailsRoutine = createRoutine(
  'SUBMIT_PERSONAL_DETAILS',
);

const submitTablePinRoutine = createRoutine(
  'SUBMIT_TABLE_PIN',
);

const verifyTableNumberRoutine = createRoutine(
  'VERIFY_TABLE_NUMBER',
);

// < ----- ACTION CREATORS ----- > //
export const addPhoneNumberToUser = data => ({
  type: addPhoneNumberToUserAction,
  payload: data,
});

export const setSessionStatus = data => ({
  type: setSessionStatusAction,
  payload: data,
});

export const addTableNumber = data => ({
  type: addTableNumberAction,
  payload: data,
});

export const resetStoreToInitialState = () => ({
  type: resetStoreToInitialStateAction,
});

export const setIsUserLeavingEarlier = data => ({
  type: setIsUserLeavingEarlierAction,
  payload: data,
});

export const saveVerificationCode = ({ verificationCode }) => ({
  type: saveVerificationCodeAction,
  payload: { verificationCode },
});

export const clearOneTimeToken = () => ({
  type: clearOneTimeTokenAction,
});

export const handlePreauthSuccess = data => ({
  type: handlePreauthSuccessAction,
  payload: data,
});

export const handlePreauthFailed = data => ({
  type: handlePreauthFailedAction,
  payload: data,
});

export const clearPreauthStatus = () => ({
  type: clearPreauthStatusAction,
});

export const getOneTimeToken = () => async dispatch => {
  try {
    dispatch(getOneTimeTokenRoutine.request());

    const response = await api.getOneTimeToken();

    return dispatch(getOneTimeTokenRoutine.success(response.data));
  } catch (error) {
    return dispatch(getOneTimeTokenRoutine.failure(error.response));
  } finally {
    dispatch(getOneTimeTokenRoutine.fulfill());
  }
};

export const submitPhoneNumber = data => async dispatch => {
  try {
    dispatch(submitPhoneNumberRoutine.request());

    await api.submitPhoneNumber(data);

    return dispatch(submitPhoneNumberRoutine.success());
  } catch (error) {
    return dispatch(submitPhoneNumberRoutine.failure(error.response));
  } finally {
    dispatch(submitPhoneNumberRoutine.fulfill());
  }
};

export const submitVerificationCode = data => async dispatch => {
  try {
    dispatch(submitVerificationCodeRoutine.request());

    const response = await api.submitVerificationCode(data);

    if (response.data.token) {
      storage.setToken(response.data.token);
    }

    if (response.data.sessionUserId) {
      storage.setSessionUserId(response.data.sessionUserId);
    }

    if (response.data.user.id) {
      storage.setUserId(response.data.user.id);
    }

    return dispatch(submitVerificationCodeRoutine.success(response.data));
  } catch (error) {
    return dispatch(submitVerificationCodeRoutine.failure(error.response));
  } finally {
    dispatch(submitVerificationCodeRoutine.fulfill());
  }
};

export const submitPersonalDetails = data => async dispatch => {
  try {
    dispatch(submitPersonalDetailsRoutine.request());

    const response = await api.submitPersonalDetails(data);

    if (response.data.token) {
      storage.setToken(response.data.token);
    }

    if (response.data.sessionUserId) {
      storage.setSessionUserId(response.data.sessionUserId);
    }

    if (response.data.user.id) {
      storage.setUserId(response.data.user.id);
    }

    return dispatch(submitPersonalDetailsRoutine.success(response.data));
  } catch (error) {
    return dispatch(submitPersonalDetailsRoutine.failure(error.response));
  } finally {
    dispatch(submitPersonalDetailsRoutine.fulfill());
  }
};

export const submitTablePin = data => async dispatch => {
  try {
    dispatch(submitTablePinRoutine.request());

    const response = await api.submitTablePin(data);

    storage.setToken(response.data.token);

    if (response.data.sessionUserId) {
      storage.setSessionUserId(response.data.sessionUserId);
    }

    return dispatch(submitTablePinRoutine.success(response.data));
  } catch (error) {
    return dispatch(submitTablePinRoutine.failure(error.response));
  } finally {
    dispatch(submitTablePinRoutine.fulfill());
  }
};

export const verifyTableNumber = data => async dispatch => {
  try {
    dispatch(verifyTableNumberRoutine.request());

    const response = await api.verifyTableNumber(data);

    return dispatch(verifyTableNumberRoutine.success(response));
  } catch (error) {
    return dispatch(verifyTableNumberRoutine.failure(error.response));
  } finally {
    dispatch(verifyTableNumberRoutine.fulfill());
  }
};

// < ----- STATE ----- > //
export const registrationStorePersistWhitelist = [
  'seatNumber', 'user', 'tableNumber', 'tablePin', 'headOfTheTableFirstName', 'isHeadOfTheTable', 'sessionStatus',
];

const initialState = {
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    isLeavingEarlier: false,
    oneTimeToken: '',
    preauthStatus: '',
  },
  tableNumber: '',
  tablePin: '',
  headOfTheTableFirstName: '',
  isHeadOfTheTable: false,
  sessionStatus: '',
  seatNumber: '',

  getOneTimeTokenPhase: INIT,
  getOneTimeTokenError: null,

  verifyTableNumberPhase: INIT,
  verifyTableNumberError: null,

  submitPhoneNumberPhase: INIT,
  submitPhoneNumberError: null,

  submitVerificationCodePhase: INIT,
  submitVerificationCodeError: null,

  submitPersonalDetailsPhase: INIT,
  submitPersonalDetailsError: null,

  submitTablePinPhase: INIT,
  submitTablePinError: null,

  verificationCode: null,

  preauthError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;

    // CLEAR PREAUTH STATUS
    case clearPreauthStatusAction:
      return {
        ...state,
        user: {
          ...state.user,
          preauthStatus: '',
        },
      };

    // HANDLE PREAUTH FAILED
    case handlePreauthFailedAction:
      return {
        ...state,
        user: {
          ...state.user,
          preauthStatus: common.PREAUTH_FAILED,
        },
        preauthError: payload,
      };

    // CLEAR ONE TIME TOKEN
    case clearOneTimeTokenAction:
      return {
        ...state,
        user: {
          ...state.user,
          oneTimeToken: '',
        },
      };

    // SAVE USER PHONE NUMBER
    case addPhoneNumberToUserAction:
      return {
        ...state,
        user: {
          ...state.user,
          email: state.user.phoneNumber === payload.phoneNumber ? state.user.email : '',
          phoneNumber: payload.phoneNumber,
        },
      };

    // SET SESSION STATUS
    case setSessionStatusAction:
      return {
        ...state,
        sessionStatus: payload.isPayedUp || state.sessionStatus === common.CLOSING
          ? common.CLOSING
          : payload.sessionStatus,
      };

    // SAVE TABLE NUMBER
    case addTableNumberAction:
      return {
        ...state,
        tableNumber: payload.tableNumber,
      };

    // SET IS USER LEAVING EARLIER
    case setIsUserLeavingEarlierAction:
      return {
        ...state,
        user: {
          ...state.user,
          ...(state.sessionStatus !== common.CLOSING && { isLeavingEarlier: payload.isLeavingEarlier }),
        },
      };

      // GET ONE TIME TOKEN
    case getOneTimeTokenRoutine.REQUEST:
      return {
        ...state,
        getOneTimeTokenPhase: LOADING,
      };
    case getOneTimeTokenRoutine.SUCCESS:
      return {
        ...state,
        getOneTimeTokenPhase: SUCCESS,
        user: {
          ...state.user,
          oneTimeToken: payload.oneTimeToken,
        },
      };
    case getOneTimeTokenRoutine.FAILURE:
      return {
        ...state,
        getOneTimeTokenPhase: FAILURE,
        getOneTimeTokenError: payload,
      };
    case getOneTimeTokenRoutine.FULFILL:
      return {
        ...state,
        getOneTimeTokenPhase: initialState.getOneTimeTokenPhase,
        getOneTimeTokenError: initialState.getOneTimeTokenError,
      };

    // VERIFY TABLE NUMBER
    case verifyTableNumberRoutine.REQUEST:
      return {
        ...state,
        verifyTableNumberPhase: LOADING,
      };
    case verifyTableNumberRoutine.SUCCESS:
      return {
        ...state,
        verifyTableNumberPhase: SUCCESS,
      };
    case verifyTableNumberRoutine.FAILURE:
      return {
        ...state,
        verifyTableNumberPhase: FAILURE,
        verifyTableNumberError: payload,
      };
    case verifyTableNumberRoutine.FULFILL:
      return {
        ...state,
        verifyTableNumberPhase: initialState.verifyTableNumberPhase,
        verifyTableNumberError: initialState.verifyTableNumberError,
      };

    // SUBMIT PHONE NUMBER
    case submitPhoneNumberRoutine.REQUEST:
      return {
        ...state,
        submitPhoneNumberPhase: LOADING,
      };
    case submitPhoneNumberRoutine.SUCCESS:
      return {
        ...state,
        submitPhoneNumberPhase: SUCCESS,
      };
    case submitPhoneNumberRoutine.FAILURE:
      return {
        ...state,
        submitPhoneNumberPhase: FAILURE,
        submitPhoneNumberError: payload,
      };
    case submitPhoneNumberRoutine.FULFILL:
      return {
        ...state,
        submitPhoneNumberPhase: initialState.submitPhoneNumberPhase,
        submitPhoneNumberError: initialState.submitPhoneNumberError,
      };

    // SAVE VERIFICATION CODE TO STORE
    case saveVerificationCodeAction:
      return {
        ...state,
        verificationCode: payload.verificationCode,
      };

    // SUBMIT VERIFICATION CODE
    case submitVerificationCodeRoutine.REQUEST:
      return {
        ...state,
        submitVerificationCodePhase: LOADING,
      };
    case submitVerificationCodeRoutine.SUCCESS:
      return {
        ...state,
        submitVerificationCodePhase: SUCCESS,
        user: {
          ...state.user,
          ...payload.user,
          sessionUserId: payload.sessionUserId ? payload.sessionUserId : 0,
        },
        headOfTheTableFirstName: payload.headOfTheTableFirstName ? payload.headOfTheTableFirstName : '',
        tablePin: payload.tablePin ? payload.tablePin : '',
        isHeadOfTheTable: payload.isHeadOfTheTable,
        seatNumber: payload.seatNumber ? payload.seatNumber.toString() : '',
      };
    case submitVerificationCodeRoutine.FAILURE:
      return {
        ...state,
        submitVerificationCodePhase: FAILURE,
        submitVerificationCodeError: payload,
      };
    case submitVerificationCodeRoutine.FULFILL:
      return {
        ...state,
        submitVerificationCodePhase: initialState.submitVerificationCodePhase,
        submitVerificationCodeError: initialState.submitVerificationCodeError,
      };

    // HANDLE PREAUTH SUCCESS
    case handlePreauthSuccessAction:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
          sessionUserId: payload.sessionUserId ? payload.sessionUserId : 0,
          preauthStatus: common.PREAUTH_SUCCESS,
        },
        headOfTheTableFirstName: payload.headOfTheTableFirstName,
        tablePin: payload.isHeadOfTheTable ? payload.tablePin : '',
        isHeadOfTheTable: payload.isHeadOfTheTable,
        seatNumber: payload.seatNumber ? payload.seatNumber : '',
      };

    // SUBMIT PERSONAL DETAILS
    case submitPersonalDetailsRoutine.REQUEST:
      return {
        ...state,
        submitPersonalDetailsPhase: LOADING,
      };
    case submitPersonalDetailsRoutine.SUCCESS:
      return {
        ...state,
        submitPersonalDetailsPhase: SUCCESS,
        user: {
          ...state.user,
          ...payload.user,
          sessionUserId: payload.sessionUserId ? payload.sessionUserId : 0,
        },
        headOfTheTableFirstName: payload.headOfTheTableFirstName,
        tablePin: payload.isHeadOfTheTable ? payload.tablePin : '',
        isHeadOfTheTable: payload.isHeadOfTheTable,
        seatNumber: payload.seatNumber ? payload.seatNumber : '',
      };
    case submitPersonalDetailsRoutine.FAILURE:
      return {
        ...state,
        submitPersonalDetailsPhase: FAILURE,
        submitPersonalDetailsError: payload,
      };
    case submitPersonalDetailsRoutine.FULFILL:
      return {
        ...state,
        submitPersonalDetailsPhase: initialState.submitPersonalDetailsPhase,
        submitPersonalDetailsError: initialState.submitPersonalDetailsError,
      };

    // SUBMIT TABLE PIN
    case submitTablePinRoutine.REQUEST:
      return {
        ...state,
        submitTablePinPhase: LOADING,
      };
    case submitTablePinRoutine.SUCCESS:
      return {
        ...state,
        submitTablePinPhase: SUCCESS,
        user: {
          ...state.user,
          sessionUserId: payload.sessionUserId,
        },
        tablePin: payload.tablePin,
      };
    case submitTablePinRoutine.FAILURE:
      return {
        ...state,
        submitTablePinPhase: FAILURE,
        submitTablePinError: payload,
      };
    case submitTablePinRoutine.FULFILL:
      return {
        ...state,
        submitTablePinPhase: initialState.submitTablePinPhase,
        submitTablePinError: initialState.submitTablePinError,
      };

    default:
      return state;
  }
};

export default store;
