import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@atoms';
import { useTimer } from '@hooks';
import { storage } from '@utils';
import { reportError } from '../../../sentry';

const CurrentOrdoTimer = ({ closingAt }) => {
  const { minutes, seconds } = useTimer(closingAt);
  const closingIn = parseInt(seconds, 10) ? minutes + 1 : minutes;
  const userId = storage.getSessionUserId();

  if (!closingIn && closingIn !== 0) {
    reportError('Timer of ordered items is invalid', 'userId', userId);
  }

  return (
    <Typography as="span" color="strawberry" size="md" weight="bold" marginBottom="xs">
      { closingIn < 2 ? ' 1 minute ' : ` ${closingIn} minutes `}
    </Typography>
  );
};

CurrentOrdoTimer.propTypes = {
  closingAt: string.isRequired,
};

export default CurrentOrdoTimer;
