import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuCategories as View } from '@views';
import { routes } from '@constants';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { loadMenu, setSelectedCategory } from '@store/menu/duck';
import { getMenuCategories } from '@helpers/menuCategories';
import { withSocket } from '@hocs';

const MenuCategories = () => {
  const { categories, loadMenuPhase } = useSelector(state => state.menuStore);
  const { tablePin, tableNumber, seatNumber } = useSelector(state => state.registrationStore);
  const { currentOrdo, ordo } = useSelector(state => state.myOrdoStore);

  const [hasServerError, setHasServerError] = useState(false);

  const itemsSelected = currentOrdo.length ? currentOrdo.length : 0;

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.shouldFetchMenu || !categories.sortedIds.length) {
      dispatch(loadMenu());
    }
  }, []);

  useEffect(() => {
    if (loadMenuPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [loadMenuPhase]);

  const handleOrdoPlace = () => {
    history.push(routes.myOrdo);
  };

  const handleCategorySelect = categoryId => () => {
    dispatch(setSelectedCategory({
      categoryId,
    }));
    history.push(routes.menuItems);
  };

  const memoizedCategories = useMemo(() => getMenuCategories(categories), [categories]);

  return (
    <View
      isPlaceOrdoVisible
      seatNumber={seatNumber}
      tableNumber={tableNumber}
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      selectedMenuItemsQuantity={itemsSelected}
      ordoClosingAt={ordo.closingAt}
      onOrdoPlace={handleOrdoPlace}
      tablePin={tablePin}
      isLoading={loadMenuPhase === LOADING}
      categories={memoizedCategories}
      onCategorySelect={handleCategorySelect}
    />
  );
};

export default withSocket(MenuCategories);
