import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableMatePin as View } from '@views';
import { common, requestPhase, routes } from '@constants';
import { submitTablePin } from '@store/registration/duck';
import { isClientError } from '@helpers/errorHandling';

const TableMatePin = () => {
  const { tableNumber, submitTablePinPhase, submitTablePinError } = useSelector(store => store.registrationStore);
  const phoneNumber = useSelector(store => store.registrationStore.user.phoneNumber);

  const dispatch = useDispatch();
  const history = useHistory();

  const [tablePin, setTablePin] = useState('');
  const [isError, setIsError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [error, setError] = useState('');

  const isTablePinValid = useMemo(() => (
    Boolean(tablePin.length && tablePin.length === common.TABLE_PIN_LENGTH)
  ), [tablePin]);

  const handleTablePinChange = e => {
    if (e.target.validity.valid) {
      setTablePin(e.target.value);
      setIsError(false);
    }
  };

  const handleTablePinSubmit = () => {
    dispatch(submitTablePin({
      tableNumber,
      phoneNumber,
      tablePin,
    }));
  };

  const handleBackUp = () => {
    history.push(routes.tableMateWelcome);
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  useEffect(() => {
    if (submitTablePinPhase === requestPhase.SUCCESS) {
      history.push({
        pathname: routes.menu,
        state: {
          shouldFetchMenu: true,
        },
      });
    } else if (submitTablePinPhase === requestPhase.FAILURE) {
      if (submitTablePinError && isClientError(submitTablePinError.data.status)) { // Case when we get 4** error
        setIsError(true);
        setError(submitTablePinError.data.error);
      } else {
        setHasServerError(true);
      }
    }
  }, [submitTablePinPhase]);

  return (
    <View
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      onBackUp={handleBackUp}
      isLoading={submitTablePinPhase === requestPhase.LOADING}
      isFormValid={isTablePinValid}
      isError={isError}
      error={error}
      tableNumber={tableNumber}
      tablePin={tablePin}
      onTablePinChange={handleTablePinChange}
      onTablePinSubmit={handleTablePinSubmit}
    />
  );
};

export default TableMatePin;
