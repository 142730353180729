import React from 'react';
import styled, { css } from 'styled-components';
import { string, bool } from 'prop-types';
import {
  Flex, Button, Typography, Circle,
} from '@atoms';

const FooterPin = ({ seatNumber, tableNumber, isTablePinVisible }) => (
  <Wrapper isTablePinVisible={isTablePinVisible} selfCenter column itemsCenter>
    <Circle
      diameter={58}
      marginBottom="xxs"
      backgroundColor="white"
      itemsCenter
      justifyCenter
      column
    >
      {seatNumber && (
        <PinContainer className="end-arrow" justifyCenter itemsCenter selfStart>
          <Typography paddingTop="xs" color="strawberry" data-cy="footerPin" size="md" weight="bold">
            SEAT
          </Typography>
        </PinContainer>
      )}
      <NumberContainer seatNumber={seatNumber} justifyCenter itemsCenter>
        <Typography
          paddingBottom={seatNumber ? 'xs' : 'none'}
          color={seatNumber ? 'white' : 'strawberry'}
          data-cy="footerPin"
          size={seatNumber ? 'md' : 'xl'}
          weight="bold"
        >
          {seatNumber}
        </Typography>
      </NumberContainer>
    </Circle>
    <Button.Rectangular
      padding="xs"
    >
      <Typography size="sm" weight="bold">
        {tableNumber}
      </Typography>
    </Button.Rectangular>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ isTablePinVisible }) => css`
    visibility: ${isTablePinVisible ? 'visible' : 'hidden'};
    pointer-events: all;
  `}
`;

const PinContainer = styled(Flex)`
  ${({ theme: { color } }) => css`
    background-color: ${color.white};
    border-radius: 29px 29px 0 0;
    height: 50%;
    width: 100%;
  `}
`;

const NumberContainer = styled(Flex)`
  ${({ seatNumber, theme: { color } }) => css`
    background-color: ${seatNumber ? color.strawberry : color.white};
    border-radius: ${seatNumber ? '0 0 29px 29px' : '50%'};
    ${seatNumber && 'height: 50%;'}
    width: 100%;
  `}
`;

FooterPin.propTypes = {
  tableNumber: string.isRequired,
  seatNumber: string.isRequired,
  isTablePinVisible: bool.isRequired,
};

export default FooterPin;
