import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MenuItemDetails as View } from '@views';
import { routes } from '@constants';
import {
  getMenuCategories,
  getMenuSubcategories,
  getMenuOptions,
  getMenuSizes,
  getItemPrices,
  getOptionsWithValuesSelected,
  incrementOptionValueQuantity,
  decrementOptionValueQuantity,
} from '@helpers/menuItemDetails';
import { getCurrentOrdo } from '@helpers/myOrdo';
import { createPrintDetails } from '@helpers/printing';
import { useViewport } from '@hooks';
import { addItemToOrdo, placeOrdo } from '@store/myOrdo/duck';
import { withSocket } from '@hocs';
import { setSelectedCategory } from '@store/menu/duck';
import { reportMessage } from '../../sentry';

const MenuItemDetails = () => {
  const {
    categories, subcategories, options, optionValues, items, sizes, selectedCategoryId,
  } = useSelector(state => state.menuStore);

  const { currentOrdo, ordo } = useSelector(state => state.myOrdoStore);
  const { user } = useSelector(state => state.registrationStore);
  const { tablePin, tableNumber, seatNumber } = useSelector(state => state.registrationStore);
  const itemsSelected = currentOrdo.length ? currentOrdo.length : 0;

  const screenSize = useViewport();
  const history = useHistory();
  const dispatch = useDispatch();
  const { itemId } = useParams();

  const [isDrawerOpened, setDrawerOpen] = useState(true);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [sizeSelected, setSizeSelected] = useState(
    items[itemId].sizeIds.find(sizeId => sizes[sizeId].specialPrice)
  || items[itemId].sizeIds.find(sizeId => sizes[sizeId].preSelected)
  || items[itemId].sizeIds[0],
  );
  const [isAddToOrdoModalOpen, setAddToOrdoModalOpen] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionsList, setOptionsList] = useState(null);

  const [itemPrice, itemSpecialPrice] = optionsList ? getItemPrices(sizes, sizeSelected, optionsList) : [0, 0];

  useEffect(() => {
    const selectedSizeOptions = getMenuOptions(options, sizes, sizeSelected, optionValues, []);
    setOptionsList(selectedSizeOptions);
  }, [sizeSelected]);

  const handleSizeSelect = sizeId => () => {
    reportMessage(`Selected size with id ${sizeId}`, 'userId', user.sessionUserId);
    setSizeSelected(sizeId);
  };

  const handleOptionValueSelect = (optionId, valueId) => () => {
    setOptionsList(prevOptions => getOptionsWithValuesSelected(prevOptions, optionId, valueId));
  };

  const handleModalChangeState = () => {
    setIsModalOpen(modalState => !modalState);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerChangeState = () => {
    setDrawerOpen(prevState => !prevState);
  };

  const handleCategorySelect = categoryId => () => {
    reportMessage(`Selected category with id ${categoryId}`, 'userId', user.sessionUserId);
    dispatch(setSelectedCategory({
      categoryId,
    }));

    history.push(routes.menuItems);
  };

  const handleSubcategorySelect = subcategoryId => () => {
    const categoryIds = Object.keys(categories.byId);

    // Searching for category id of selected subcategory
    const categoryId = categoryIds.filter(id => (
      categories.byId[id].subcategoryIds.includes(subcategoryId)));

    dispatch(setSelectedCategory({
      categoryId,
    }));

    history.push(routes.menuItems);
  };

  const handleOrdoPlace = () => {
    history.push(routes.myOrdo);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDecrement = () => {
    setItemQuantity(prevQuantity => (
      prevQuantity === 1 ? prevQuantity : prevQuantity - 1
    ));
  };

  const handleIncrement = () => {
    setItemQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleOptionQuantityDecrement = (valueId, optionId) => () => {
    setOptionsList(prevOptions => decrementOptionValueQuantity(prevOptions, optionId, valueId));
  };

  const handleOptionQuantityIncrement = (valueId, optionId) => () => {
    setOptionsList(prevOptions => incrementOptionValueQuantity(prevOptions, optionId, valueId));
  };

  const handleAddToOrdoModalClose = () => {
    setAddToOrdoModalOpen(false);
    history.push(routes.menu);
  };

  const handleMenuItemAdd = () => {
    const itemsToAdd = new Array(itemQuantity).fill().reduce(acc => {
      acc.push({
        id: parseInt(itemId, 10),
        ordoItemKey: uuidv4(),
        price: itemSpecialPrice || itemSpecialPrice === 0 ? itemSpecialPrice : itemPrice,
        specialInstructions: specialInstructions === ''
          ? null
          : specialInstructions,
        size: {
          id: sizeSelected,
          options: optionsList.map(option => ({
            id: option.id,
            values: option.valuesSelected,
          })),
        },
      });

      return acc;
    }, []);

    dispatch(addItemToOrdo(itemsToAdd));
    setAddToOrdoModalOpen(true);
  };

  const handleBackUp = () => {
    history.push(routes.menuItems);
  };

  const handleSpecialIntructionsChange = e => {
    setSpecialInstructions(e.target.value);
  };

  const handleSpecialIntructionsClose = () => {
    setIsModalOpen(false);
    setSpecialInstructions('');
  };

  const handleCompleteOrdo = () => {
    const menuItems = currentOrdo.map(curOrdo => ({
      id: curOrdo.id,
      specialInstructions: curOrdo.specialInstructions,
      size: curOrdo.size,
    }));
    const printDetails = createPrintDetails(
      getCurrentOrdo(currentOrdo, items, optionValues, sizes, options),
      user,
      categories,
      tableNumber,
    );
    dispatch(placeOrdo({ menuItems, printDetails }));
    history.push(routes.myOrdo);
  };

  const handleGoBack = () => {
    history.push(routes.menu);
  };

  const handleModalAddMoreItems = () => {
    history.goBack();
  };

  const memoizedCategories = useMemo(() => (
    getMenuCategories(categories)
  ), [selectedCategoryId]);

  const memoizedSubcategories = useMemo(() => (
    getMenuSubcategories(subcategories, categories, selectedCategoryId)
  ), [selectedCategoryId]);

  const memoizedSize = useMemo(() => (
    getMenuSizes(items, itemId, sizes, sizeSelected)
  ), [sizeSelected]);

  // Disable 'Add to ordo button' if item's options are not loaded yet
  const isAddToOrdoDisabled = !optionsList || !!(optionsList.find(option => (
    option.requiredValue && !option.valuesSelected.length
  )));

  return (
    <View
      isBackUpButtonVisible
      isPlaceOrdoVisible
      onCompleteOrdo={handleCompleteOrdo}
      onDrawerChangeState={handleDrawerChangeState}
      onOptionQuantityIncrement={handleOptionQuantityIncrement}
      onOptionQuantityDecrement={handleOptionQuantityDecrement}
      onAddToOrdoModalClose={handleAddToOrdoModalClose}
      isAddToOrdoModalOpen={isAddToOrdoModalOpen}
      onGoBack={handleGoBack}
      tableNumber={tableNumber}
      onDrawerClose={handleDrawerClose}
      seatNumber={seatNumber}
      tablePin={tablePin}
      screenHeight={screenSize.height}
      categories={memoizedCategories}
      subcategories={memoizedSubcategories}
      selectedCategoryId={selectedCategoryId}
      selectedMenuItemsQuantity={itemsSelected}
      ordoClosingAt={ordo.closingAt}
      isAddToOrdoDisabled={isAddToOrdoDisabled}
      // selectedSubcategory
      isModalOpen={isModalOpen}
      size={memoizedSize}
      onSpecialInstructionsClose={handleSpecialIntructionsClose}
      onModalChangeState={handleModalChangeState}
      onSizeSelect={handleSizeSelect}
      onOptionValueSelect={handleOptionValueSelect}
      onCategorySelect={handleCategorySelect}
      onSpecialInstructionsChange={handleSpecialIntructionsChange}
      specialInstructions={specialInstructions}
      onSubcategorySelect={handleSubcategorySelect}
      title={items[itemId].name}
      imageSrc={items[itemId].image[0]?.src}
      price={itemPrice}
      specialPrice={itemSpecialPrice}
      description={items[itemId].description}
      onOrdoPlace={handleOrdoPlace}
      isDrawerOpened={isDrawerOpened}
      optionsList={optionsList || []}
      quantity={itemQuantity}
      onDrawerOpen={handleDrawerOpen}
      onDecrement={handleDecrement}
      onIncrement={handleIncrement}
      onMenuItemAdd={handleMenuItemAdd}
      onBackUp={handleBackUp}
      onModalAddMoreItems={handleModalAddMoreItems}
    />
  );
};

export default withSocket(MenuItemDetails);
