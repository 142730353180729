import React from 'react';
import { func, string } from 'prop-types';
import CurvedArrow from 'react-curved-arrow';
import {
  Flex, Button, Typography,
} from '@atoms';
import { Registration } from '@templates';

const HeadOfTheTablePin = ({
  tableNumber, onBackUp, onProceed, tablePin, seatNumber,
}) => (
  <Registration
    tableNumber={tableNumber}
    tablePin={tablePin}
    seatNumber={seatNumber}
    onBackUp={onBackUp}
    progressValue={100}
    isTablePinVisible
  >
    <Flex column itemsCenter>
      <Typography size="xl4" weight="black" marginBottom="xl3">
        Dining with someone else?
        {' '}
        <Typography
          as="span"
          color="strawberry"
          size="xl4"
          weight="black"
        >
          Share this pin
        </Typography>
        {' '}
        with them:
      </Typography>
      <Typography
        className="start-arrow"
        color="strawberry"
        size="xl6"
        weight="black"
        marginBottom="xl6"
        paddingRight="sm"
        data-cy="pin"
      >
        {tablePin}
      </Typography>
      <CurvedArrow
        fromOffsetX={50}
        toOffsetY={30}
        toOffsetX={15}
        zIndex={-1}
        middleX={170}
        color="#f5333f"
        fromSelector=".start-arrow"
        toSelector=".end-arrow"
      />
      <Button.Circular
        primary
        diameter={208}
        onClick={onProceed}
      >
        <Typography data-cy="ordoNow" size="xl5" weight="black" marginBottom="xs">
          ordo now
        </Typography>
      </Button.Circular>
    </Flex>
  </Registration>
);

HeadOfTheTablePin.propTypes = {
  tableNumber: string.isRequired,
  tablePin: string.isRequired,
  seatNumber: string.isRequired,
  onProceed: func.isRequired,
  onBackUp: func.isRequired,
};

export default HeadOfTheTablePin;
