import React from 'react';
import {
  bool, func, string,
} from 'prop-types';
import {
  Button, Flex, Typography, Input,
} from '@atoms';
import { Registration } from '@templates';

const VerificationCode = ({
  onToastClose,
  hasServerError,
  isLoading,
  isFormValid,
  isError,
  error,
  verificationCode,
  onVerificationCodeChange,
  onVerificationCodeSubmit,
  tableNumber,
  seatNumber,
  onBackUp,
  onRedirectToStaticMenu,
}) => (
  <Registration
    seatNumber={seatNumber}
    tableNumber={tableNumber}
    onToastClose={onToastClose}
    hasServerError={hasServerError}
    progressValue={40}
    onBackUp={onBackUp}
    isLoading={isLoading}
  >
    <Flex column itemsCenter paddingTop="xl6">
      <Typography size="xl4" weight="black">
        A 4-digit phone verification code has been sent to your mobile device.
      </Typography>
      <Flex>
        <Typography color="strawberry" size="xl4" weight="black">
          Enter it below
          {' '}
          <Typography color="black" as="span" size="xl4" weight="black">
            to start.
          </Typography>
        </Typography>
      </Flex>
      {isError && (
        <Flex paddingTop="md">
          <Typography data-cy="error" color="strawberry" size="lg" weight="bold">
            {error}
          </Typography>
        </Flex>
      )}
      <Input
        marginTop="xl2"
        marginBottom="xl2"
        type="text"
        id="verificationCode"
        name="verificationCode"
        placeholder="4321"
        maxLength="4"
        pattern="[0-9]*"
        value={verificationCode}
        isError={isError || !isFormValid}
        onChange={onVerificationCodeChange}
        data-cy="verificationCode-input"
      />
      <Button.Rectangular
        primary
        gradient
        fullWidth
        padding="xl2"
        disabled={isError || !isFormValid}
        onClick={onVerificationCodeSubmit}
        data-cy="next"
      >
        Next
      </Button.Rectangular>
      <Button.Ghost
        padding="xl2"
        marginBottom="xl2"
        data-cy="next"
        onClick={onRedirectToStaticMenu}
      >
        Table Service Menu
      </Button.Ghost>
    </Flex>
  </Registration>
);

VerificationCode.propTypes = {
  seatNumber: string.isRequired,
  tableNumber: string.isRequired,
  onToastClose: func.isRequired,
  hasServerError: bool.isRequired,
  isLoading: bool.isRequired,
  isFormValid: bool.isRequired,
  isError: bool.isRequired,
  error: string.isRequired,
  verificationCode: string.isRequired,
  onVerificationCodeChange: func.isRequired,
  onVerificationCodeSubmit: func.isRequired,
  onBackUp: func.isRequired,
  onRedirectToStaticMenu: func.isRequired,
};

export default VerificationCode;
