const tipOptions = [
  {
    amount: '18',
    label: 'Not bad',
  },
  {
    amount: '20',
    label: 'Pretty good',
  },
  {
    amount: '22',
    label: 'They did great',
  },
  {
    amount: '25',
    label: 'Awesome!',
  },
  {
    amount: '30',
    label: 'Soooo good!',
  },
];

export default tipOptions;
