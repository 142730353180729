import { node } from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex } from '@atoms';
import Placeholder from '@assets/item-placeholder.svg';

const ImageBackground = styled(Flex)`
  ${({ imageSrc }) => css`
    background: linear-gradient(rgba(36, 36, 36, 0.25), rgba(36, 36, 36, 0.25)), url(${imageSrc || Placeholder});
    background-size: cover;
  `}
`;

ImageBackground.propTypes = {
  children: node.isRequired,
};

export default ImageBackground;
