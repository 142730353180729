import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VerificationCode as View } from '@views';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { submitVerificationCode, saveVerificationCode } from '@store/registration/duck';
import { routes, common } from '@constants';
import { isClientError } from '@helpers/errorHandling';

const VerificationCode = () => {
  const {
    user,
    seatNumber,
    tableNumber,
    submitVerificationCodePhase,
    submitVerificationCodeError,
  } = useSelector(store => store.registrationStore);

  const [verificationCode, setVerificationCode] = useState('');
  const [isError, setIsError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const isVerificationCodeValid = useMemo(() => (
    Boolean(verificationCode.length && verificationCode.length === 4)
  ), [verificationCode]);

  const handleVerificationCodeChange = e => {
    if (e.target.validity.valid) {
      setVerificationCode(e.target.value);
      setIsError(false);
    }
  };

  const handleVerificationCodeSubmit = () => {
    const { phoneNumber } = user;

    dispatch(saveVerificationCode({
      verificationCode,
    }));

    dispatch(submitVerificationCode({
      verificationCode,
      phoneNumber,
      tableNumber,
    }));
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const handleBackUp = () => {
    history.push(routes.phoneNumber);
  };

  const handleRedirectToStaticMenu = () => {
    history.push(routes.staticMenu);
  };

  useEffect(() => {
    if (submitVerificationCodePhase === SUCCESS) {
      if (user.email !== '') {
        if (user.preauthStatus === common.PREAUTH_SUCCESS || !common.isPreauthAvailable) {
          history.push({
            pathname: routes.menu,
            state: {
              shouldFetchMenu: true,
            },
          });
        } else {
          history.push(routes.preAuthorization);
        }
      } else {
        history.push(routes.personalDetails);
      }
    } else if (submitVerificationCodePhase === FAILURE) {
      if (submitVerificationCodeError
        && isClientError(submitVerificationCodeError.data.status)) { // Case when we get 4** error
        setIsError(true);
        setError(submitVerificationCodeError.data.error);
      } else {
        setHasServerError(true);
      }
    }
  }, [submitVerificationCodePhase]);

  return (
    <View
      seatNumber={seatNumber ? seatNumber.toString() : ''}
      tableNumber={tableNumber}
      onToastClose={handleToastClose}
      isLoading={submitVerificationCodePhase === LOADING}
      isFormValid={isVerificationCodeValid}
      isError={isError}
      hasServerError={hasServerError}
      error={error}
      verificationCode={verificationCode}
      onVerificationCodeChange={handleVerificationCodeChange}
      onVerificationCodeSubmit={handleVerificationCodeSubmit}
      onBackUp={handleBackUp}
      onRedirectToStaticMenu={handleRedirectToStaticMenu}
    />
  );
};

export default VerificationCode;
