import React, { useEffect } from 'react';
import { bool, node, string } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Redirect, Route, useHistory, useLocation,
} from 'react-router-dom';
import { common, routes } from '@constants';
import { storage } from '@utils';

const AuthorizedRoute = ({ exact, path, children }) => {
  const token = storage.getToken();
  const location = useLocation();
  const sessionStatus = useSelector(store => (
    store.registrationStore.sessionStatus
  ));
  const history = useHistory();

  useEffect(() => {
    if (sessionStatus) {
      history.push(common.routeBySessionStatus[sessionStatus]);
    }
  }, [sessionStatus]);

  if (!token && location.pathname !== routes.helpMe) {
    return <Redirect to={routes.tableNumber} />;
  }

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

AuthorizedRoute.defaultProps = {
  exact: false,
};

AuthorizedRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  children: node.isRequired,
};

export default AuthorizedRoute;
