import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TipForServer as View } from '@views';
import { routes, tipOptions } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { confirmTip } from '@store/myBill/duck';

const TipForServer = () => {
  const { confirmTipPhase, userBill } = useSelector(store => store.myBillStore);

  const tipPercent = tipOptions.find(option => option.amount === userBill.tip.tipPercent.toString());

  const [tipAmount, setTipAmount] = useState(tipPercent ? tipPercent.amount : '20');

  const [hasServerError, setHasServerError] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (confirmTipPhase === SUCCESS) {
      history.push(routes.finalBillReview);
    } else if (confirmTipPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [confirmTipPhase]);

  const handleTipAmountSelect = e => {
    setTipAmount(e.currentTarget.id);
  };

  const handleTipConfirm = () => {
    const tip = {
      tipInCash: Math.round((userBill.subtotal * tipAmount) / 100),
    };

    dispatch(confirmTip(tip));
  };

  const handleCustomAmountSelect = () => {
    history.push(routes.customTip);
  };

  const handleBackUp = () => {
    history.push(routes.billReview);
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  return (
    <View
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      subtotal={userBill.subtotal}
      selectedAmount={tipAmount}
      onTipAmountSelect={handleTipAmountSelect}
      onTipConfirm={handleTipConfirm}
      onCustomAmountSelect={handleCustomAmountSelect}
      onBackUp={handleBackUp}
      isLoading={confirmTipPhase === LOADING}
    />
  );
};

export default TipForServer;
