import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuCategories as View } from '@views';
import { routes } from '@constants';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { loadMenuForStaff, setSelectedCategory } from '@store/menu/duck';
import { getMenuCategories } from '@helpers/menuCategories';
import { getValueFromQueryParams } from '@helpers/common';

const ChangeOrdoMenuCategories = () => {
  const { categories, loadMenuForStaffPhase, loadMenuForStaffError } = useSelector(state => state.menuStore);
  const location = useLocation();
  const token = getValueFromQueryParams(location.search, 'token');
  const menuItemId = getValueFromQueryParams(location.search, 'menuItemId');

  const [hasServerError, setHasServerError] = useState(false);

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push(routes.tableNumber);
    }
    dispatch(loadMenuForStaff(token));
    if (menuItemId) {
      history.push(`${routes.changeOrdoMenuItems}/${menuItemId}${location.search}`);
    }
  }, []);

  useEffect(() => {
    if (loadMenuForStaffPhase === FAILURE) {
      if (loadMenuForStaffError.data && loadMenuForStaffError.data.status === 401) {
        history.push(routes.tableNumber);
      }
      setHasServerError(true);
    }
  }, [loadMenuForStaffPhase]);

  const handleCategorySelect = categoryId => () => {
    dispatch(setSelectedCategory({
      categoryId,
    }));
    history.push(`${routes.changeOrdoMenuItems}${location.search}`);
  };

  const memoizedCategories = useMemo(() => getMenuCategories(categories), [categories]);

  return (
    <View
      isPlaceOrdoVisible={false}
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      isLoading={loadMenuForStaffPhase === LOADING}
      categories={memoizedCategories}
      onCategorySelect={handleCategorySelect}
    />
  );
};

export default ChangeOrdoMenuCategories;
