import React, { useMemo } from 'react';
import {
  arrayOf, exact, string, number, bool,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Flex, Typography } from '@atoms';
import { OrdoItem } from '@molecules';

const OrdoHistory = ({ userId, ordoHistory }) => {
  const memoizedOrdoHistory = useMemo(() => (
    <Flex column>
      {ordoHistory.map(({
        orderedAt, ordoUserFirstName, ordoUserId, ordoItems,
      }, index) => (
        <Flex key={uuidv4()} column marginBottom="xl2">
          <Typography size="sm" weight="bold" marginBottom="sm">
            {`At ${orderedAt}, ${ordoUserId === userId ? 'You' : ordoUserFirstName} ordo'd:`}
          </Typography>
          {ordoItems.map(({
            name, price, optionValues, size, isRequired, specialInstructions, isVoided,
          }) => (
            <OrdoItem
              isPayedUp={isVoided}
              specialInstructions={specialInstructions}
              isRequired={isRequired}
              size={size}
              key={uuidv4()}
              name={name}
              price={price}
              optionValues={optionValues}
            />
          ))}
          {index !== ordoHistory.length - 1 && <Divider />}
        </Flex>
      ))}
    </Flex>
  ), [ordoHistory]);

  return (
    <Wrapper data-cy="ordo-history" column padding="xl5">
      <Typography weight="black" size="xl" marginBottom="sm">
        ordo history:
      </Typography>
      {memoizedOrdoHistory}
    </Wrapper>
  );
};

const Divider = styled.div`
  ${({ theme: { color, spacing } }) => css`
  width: calc(100% - 24px);
  margin: ${spacing.none} ${spacing.md};
  height: 1px;
  background-color: ${color.dust}`}
`;

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
  `}
`;

OrdoHistory.propTypes = {
  userId: number.isRequired,
  ordoHistory: arrayOf(exact({
    deliversAt: string,
    orderedAt: string,
    ordoUserFirstName: string,
    ordoUserId: number,
    ordoItems: arrayOf(exact({
      name: string,
      specialInstructions: string,
      isVoided: bool,
      price: number,
      size: string,
      optionValues: arrayOf(exact({
        name: string,
        price: number,
        quantity: number,
        isRequired: bool,
      })),
    })),
  })).isRequired,
};

export default OrdoHistory;
