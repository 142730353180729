import React, { useCallback, useMemo } from 'react';
import {
  arrayOf, bool, exact, func, number, oneOf, oneOfType, string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Flex, Typography, ImageBackground, Circle, BackUpButton,
} from '@atoms';
import { Drawer, MenuHeader } from '@molecules';
import { MenuItemOptions, MenuItemSizes } from '@organisms';
import { Menu } from '@templates';
import { formatPrice } from '@helpers/myOrdo';

const StaticMenuItemDetails = ({
  isBackUpButtonVisible,
  isLoading,
  isPlaceOrdoVisible,
  onDrawerChangeState,
  onOptionQuantityDecrement,
  onOptionQuantityIncrement,
  tableNumber,
  tablePin,
  seatNumber,
  screenHeight,
  ordoClosingAt,
  onSizeSelect,
  size,
  onOrdoPlace,
  categories,
  subcategories,
  onCategorySelect,
  onSubcategorySelect,
  selectedCategoryId,
  onOptionValueSelect,
  selectedMenuItemsQuantity,
  // selectedSubcategory
  title,
  price,
  specialPrice,
  imageSrc,
  description,
  isDrawerOpened,
  optionsList,
  onDrawerOpen,
  onDrawerClose,
  onBackUp,
}) => {
  const memoizedOptionsList = useMemo(() => (
    optionsList.map(({
      id,
      name,
      maxRequiredValuesAmount,
      allowValueMultipleSelection,
      values,
      valuesSelected,
      requiredValue,
    }) => (
      <MenuItemOptions
        requiredValue={requiredValue}
        allowValueMultipleSelection={allowValueMultipleSelection}
        onOptionQuantityDecrement={onOptionQuantityDecrement}
        onOptionQuantityIncrement={onOptionQuantityIncrement}
        key={id}
        id={id}
        name={name}
        maxRequiredValuesAmount={maxRequiredValuesAmount}
        values={values}
        valuesSelected={valuesSelected}
        onOptionValueSelect={onOptionValueSelect}
      />
    ))
  ), [optionsList]);

  const getItemPriceComponent = useCallback(() => {
    if (!specialPrice && specialPrice !== 0) {
      return (
        <StyledCircle
          diameter={47}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="darkGrey"
        >
          <Typography color="white" size={(price / 100) % 1 ? 'md' : 'xl2'} weight="bold">
            {`$${formatPrice(price)}`}
          </Typography>
        </StyledCircle>
      );
    }

    return (
      <StyledPriceWrapper row justifyEnd>
        <StyledCircle
          diameter={47}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="strawberry"
        >
          <Typography color="white" size={(price / 100) % 1 ? 'md' : 'xl2'} weight="medium">
            {`$${formatPrice(price)}`}
          </Typography>
        </StyledCircle>
        <StyledCircle
          diameter={47}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="white"
        >
          <Typography color="grannySmith" size={(specialPrice / 100) % 1 ? 'md' : 'xl2'} weight="bold">
            {`$${formatPrice(specialPrice)}`}
          </Typography>
        </StyledCircle>
      </StyledPriceWrapper>
    );
  }, [price, specialPrice]);

  return (
    <Menu
      isLoading={isLoading}
      isPlaceOrdoVisible={isPlaceOrdoVisible}
      tablePin={tablePin}
      tableNumber={tableNumber}
      seatNumber={seatNumber}
      onBackUp={onBackUp}
      isFooterVisible={false}
      selectedMenuItemsQuantity={selectedMenuItemsQuantity}
      ordoClosingAt={ordoClosingAt}
      onOrdoPlace={onOrdoPlace}
      hasMarginBottom={false}
    >
      {!isDrawerOpened && (
        <MenuHeader
          categories={categories}
          subcategories={subcategories}
          onCategorySelect={onCategorySelect}
          onSubcategorySelect={onSubcategorySelect}
          selectedCategoryId={selectedCategoryId}
        />
      )}
      <Wrapper
        isDrawerOpened={isDrawerOpened}
        hasSubcategories={Boolean(subcategories.length)}
        screenHeight={screenHeight}
        column
        justifyEnd
        imageSrc={imageSrc}
      >
        <Flex onClick={onDrawerClose} grow={0} column padding="xl5">
          <Flex
            justifyBetween
            itemsCenter
            marginBottom="lg"
          >
            <StyledTypography color="white" size="xl4" weight="black">
              {title.toUpperCase()}
            </StyledTypography>
            {getItemPriceComponent()}
          </Flex>
          <StyledTypography
            color="white"
            size="lg"
            weight="bold"
          >
            {description}
          </StyledTypography>
        </Flex>
        <Drawer isDrawerOpened={isDrawerOpened} onDrawerChangeState={onDrawerChangeState} isStatic>
          <Options onClick={onDrawerOpen} grow={0} isDrawerOpened={isDrawerOpened} column>
            {Boolean(size.sizes.length) && (
              <MenuItemSizes
                sizes={size.sizes}
                sizeSelected={size.sizeSelected}
                onSizeSelect={onSizeSelect}
                isStatic
              />
            )}
            {memoizedOptionsList}
          </Options>
          <FooterWrapper>
            <BackUpButton onBackUp={onBackUp} isShown={isBackUpButtonVisible} />
          </FooterWrapper>
        </Drawer>
      </Wrapper>
    </Menu>
  );
};

const FooterWrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
    background: ${color.white};
    flex: 0;
    padding: 12px;
  `}
`;

const Wrapper = styled(ImageBackground)`
  ${({
    screenHeight, isDrawerOpened, hasSubcategories, theme: { sizing },
  }) => css`
    width: 100%;
    height: calc(${screenHeight}px - ${hasSubcategories
  ? `${sizing.menuBar.height * 2}px`
  : `${sizing.menuBar.height}px`});
    ${isDrawerOpened && `height: ${screenHeight}px;`}
  `}
`;

const Options = styled(Flex)`
  ${({ isDrawerOpened, theme: { color } }) => css`
    overflow-y: ${isDrawerOpened ? 'scroll' : 'hidden'};

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 9px;
      background-color: ${color.warmGrey};
    }
  `}
`;

const StyledTypography = styled(Typography)`
  ${({ theme: { color } }) => css`
    text-shadow: 1px 1px ${color.black};
  `}
`;

const StyledCircle = styled(Circle)`
  ${() => css`
    min-width: 47px;
  `}
`;

const StyledPriceWrapper = styled(Flex)`
  ${({ theme: { spacing } }) => css`
    div:first-child {
      margin-right: ${spacing.md};

      p {
        text-decoration: line-through;
      }
    }
  `}
`;

StaticMenuItemDetails.defaultProps = {
  specialPrice: null,
  imageSrc: '',
  tableNumber: '',
  tablePin: '',
  seatNumber: '',
  onOrdoPlace: () => {},
  ordoClosingAt: '',
  selectedMenuItemsQuantity: 0,
  isLoading: false,
  onDrawerClose: () => {},
  onDrawerChangeState: () => {},
};

StaticMenuItemDetails.propTypes = {
  isBackUpButtonVisible: bool.isRequired,
  isLoading: bool,
  isPlaceOrdoVisible: bool.isRequired,
  onDrawerChangeState: func,
  onOptionQuantityDecrement: func.isRequired,
  onOptionQuantityIncrement: func.isRequired,
  tableNumber: string,
  onDrawerClose: func,
  tablePin: string,
  seatNumber: string,
  screenHeight: number.isRequired,
  ordoClosingAt: string,
  size: exact({
    sizeSelected: number,
    sizes: arrayOf(exact({
      name: string,
      price: number,
      specialPrice: oneOfType([
        number,
        oneOf([null]),
      ]),
      id: number,
    })),
  }).isRequired,
  onSizeSelect: func.isRequired,
  onOrdoPlace: func,
  categories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  subcategories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  onCategorySelect: func.isRequired,
  onSubcategorySelect: func.isRequired,
  selectedCategoryId: number.isRequired,
  onOptionValueSelect: func.isRequired,
  selectedMenuItemsQuantity: number,
  title: string.isRequired,
  price: number.isRequired,
  specialPrice: number,
  imageSrc: string,
  description: string.isRequired,
  isDrawerOpened: bool.isRequired,
  optionsList: arrayOf(exact({
    allowValueMultipleSelection: bool,
    name: string,
    requiredValue: bool.isRequired,
    maxRequiredValuesAmount: number,
    id: number,
    valuesSelected: arrayOf(exact({
      id: number,
      quantity: number,
    })),
    values: arrayOf(exact({
      id: number,
      imageSrc: string,
      name: string,
      price: number,
    })),
  })).isRequired,
  onDrawerOpen: func.isRequired,
  onBackUp: func.isRequired,
};

export default StaticMenuItemDetails;
