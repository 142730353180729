import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button, Flex, Typography, Input,
} from '@atoms';
import { Tooltip } from '@molecules';
import { Registration } from '@templates';

const hint = `Why do we need this you ask?
You’ll get an email confirmation with your order, this will be your receipt : )
If anything were to go wrong with your order or experience and we need to contact you
(Covid tracing, food contamination, or if you are accidentally overbilled
and you need to reach us for a refund - this is how we would do it)
You can request to have any personal data (first name, last name etc.) permanently deleted at any time.`;

const PersonalDetails = ({
  isEmailValid,
  tableNumber,
  seatNumber,
  isLoading,
  isFirstNameValid,
  isError,
  error,
  firstName,
  lastName,
  email,
  isTooltipOpened,
  onToastClose,
  onTooltipOpen,
  onFirstNameChange,
  onLastNameChange,
  onEmailChange,
  onPersonalDetailsSubmit,
  onBackUp,
  hasServerError,
}) => {
  const isFormValid = isFirstNameValid && isEmailValid;

  return (
    <Registration
      onToastClose={onToastClose}
      hasServerError={hasServerError}
      progressValue={60}
      onBackUp={onBackUp}
      tableNumber={tableNumber}
      seatNumber={seatNumber}
      isLoading={isLoading}
    >
      <Flex column itemsCenter>
        <Typography size="xl4" weight="black">
          What&apos;s your name?
        </Typography>
        {isError && (
          <Flex paddingTop="md">
            <Typography color="strawberry" size="lg" weight="bold">
              {error}
            </Typography>
          </Flex>
        )}
        <Input
          marginTop="xl2"
          marginBottom="xl2"
          type="text"
          id="firstName"
          name="firstName"
          placeholder="First Name"
          pattern="[a-zA-Z ]+$"
          value={firstName}
          isError={!isFirstNameValid}
          onChange={onFirstNameChange}
          data-cy="firstName"
        />
        <Input
          marginBottom="xl2"
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Last Name (optional)"
          pattern="[a-zA-Z ]+$"
          value={lastName}
          onChange={onLastNameChange}
          data-cy="lastName"
        />
        <Input
          marginBottom="xl2"
          type="email"
          id="email"
          name="email"
          placeholder="Email (optional)"
          value={email}
          isError={!isEmailValid}
          onChange={onEmailChange}
          data-cy="email"
        />
        <Button.Rectangular
          primary
          gradient
          fullWidth
          padding="xl2"
          marginBottom="xl2"
          disabled={isError || !isFormValid}
          onClick={onPersonalDetailsSubmit}
          data-cy="next"
        >
          Next
        </Button.Rectangular>
        <Tooltip isTooltipOpened={isTooltipOpened} onTooltipOpen={onTooltipOpen}>
          <Typography data-cy="tooltip-hint" color="white" size="lg" weight="bold">
            {hint}
          </Typography>
        </Tooltip>
      </Flex>
    </Registration>
  );
};

PersonalDetails.propTypes = {
  isEmailValid: bool.isRequired,
  tableNumber: string.isRequired,
  seatNumber: string.isRequired,
  onToastClose: func.isRequired,
  hasServerError: bool.isRequired,
  isLoading: bool.isRequired,
  isFirstNameValid: bool.isRequired,
  isError: bool.isRequired,
  error: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  isTooltipOpened: bool.isRequired,
  onTooltipOpen: func.isRequired,
  onFirstNameChange: func.isRequired,
  onLastNameChange: func.isRequired,
  onEmailChange: func.isRequired,
  onPersonalDetailsSubmit: func.isRequired,
  onBackUp: func.isRequired,
};

export default PersonalDetails;
