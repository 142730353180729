import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { storage } from '@utils';
import { common } from '@constants';

const URL = window.location.host;

export const initSentry = () => {
  if (URL.includes('ordo.menu')) {
    Sentry.init({
      dsn: common.SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,
    });
  }
};

export const reportError = (error, tagKey, tagValue, extraInfo) => {
  Sentry.withScope(scope => {
    if (tagKey && tagValue) {
      scope.setTag(tagKey, tagValue);
    }
    if (extraInfo) {
      scope.setExtras(extraInfo);
    }
    scope.setLevel(Sentry.Severity.Error);

    Sentry.captureMessage(error);
  });
};

export const reportMessage = (message, tagKey, tagValue, extraInfo) => {
  Sentry.withScope(scope => {
    if (tagKey && tagValue) {
      scope.setTag(tagKey, tagValue);
    }
    if (extraInfo) {
      scope.setExtras(extraInfo);
    }
    scope.setLevel(Sentry.Severity.Info);

    Sentry.captureMessage(message);
  });
};

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (action.type !== 'persist/REHYDRATE' && !action.type.includes('REQUEST') && !action.type.includes('FULFILL')) {
      const tableNumber = storage.getTableNumber();
      const userId = storage.getSessionUserId();

      if (userId) {
        reportMessage(`Dispatching action: ${action.type}`, 'userId', userId, action.payload || {});
      } else {
        reportMessage(`Dispatching action: ${action.type}`, 'tableNumber', tableNumber, action.payload || {});
      }
    }

    return action;
  },
});
