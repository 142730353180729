import { useEffect, useState } from 'react';

const useNetwork = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('offline', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
    };
  });
  return isOnline;
};

export default useNetwork;
