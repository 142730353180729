import React, { useMemo } from 'react';
import {
  arrayOf, exact, string, number,
} from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styled, { css } from 'styled-components';
import { Flex, Typography } from '@atoms';

const BillItem = ({
  name, price, optionValues, totalItemPrice, size, voidReason, discountReason, discountAmount,
}) => {
  const optionValuesMemoized = useMemo(() => (
    optionValues.map(value => (
      new Array(value.quantity).fill(value).map(() => (
        <Flex key={uuidv4()} justifyBetween>
          <Typography color="warmGrey" size="xl" weight="bold">
            {`+ ${value.name}`}
          </Typography>
          <Typography color="warmGrey" size="xl" weight="bold" marginLeft="xl2">
            {`$${(value.price / 100).toFixed(2)}`}
          </Typography>
        </Flex>
      ))
    ))
  ), [optionValues]);

  return (
    <Wrapper column paddingTop="xl2" paddingBottom="xl2">
      <Flex justifyBetween>
        <Typography color={voidReason ? 'warmGrey' : 'black'} size="xl" weight="bold">
          {`${name} (${size})`}
        </Typography>
        <Typography color={voidReason ? 'warmGrey' : 'black'} size="xl" weight="bold" marginLeft="xl2">
          {`$${(price / 100).toFixed(2)}`}
        </Typography>
      </Flex>
      {Boolean(optionValues.length) && optionValuesMemoized}
      {!voidReason && discountAmount && (
        <Flex justifyBetween>
          <Typography color="strawberry" size="xl" weight="bold" marginTop="md">
            {`Discount: ${discountReason}`}
          </Typography>
          <Typography color="strawberry" size="xl" weight="bold" marginTop="md">
            {`- $${(discountAmount / 100).toFixed(2)}`}
          </Typography>
        </Flex>
      )}
      {totalItemPrice !== price && (
        <Flex selfEnd>
          <Typography color={voidReason ? 'warmGrey' : 'black'} size="xl" weight="bold" marginTop="md">
            {`$${(totalItemPrice / 100).toFixed(2)}`}
          </Typography>
        </Flex>
      )}
      {!!voidReason && (
        <Flex justifyBetween>
          <Typography color="strawberry" size="xl" weight="bold" marginTop="md">
            {`Void: ${voidReason}`}
          </Typography>
          <Typography color="strawberry" size="xl" weight="bold" marginTop="md">
            {`- $${(totalItemPrice / 100).toFixed(2)}`}
          </Typography>
        </Flex>
      )}
    </Wrapper>
  );
};

BillItem.defaultProps = {
  voidReason: '',
  discountReason: '',
  discountAmount: '',
};

BillItem.propTypes = {
  name: string.isRequired,
  price: number.isRequired,
  size: string.isRequired,
  optionValues: arrayOf(exact({
    name: string,
    price: number,
    quantity: number,
  })).isRequired,
  totalItemPrice: number.isRequired,
  voidReason: string,
  discountReason: string,
  discountAmount: number,
};

const Wrapper = styled(Flex)`
  ${({ tip, theme: { color, spacing } }) => css`
    padding: ${spacing.xl2} ${tip ? spacing.xl5 : spacing.none};
    border-bottom: 1px solid ${color.dust};
  `}
`;

export default BillItem;
