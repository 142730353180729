import React, { useCallback, useMemo } from 'react';
import {
  arrayOf, exact, func, string, number, bool,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Flex, Typography, Button } from '@atoms';
import { BillSection, BillItem } from '@molecules';
import { Ordo } from '@templates';
import { ReactComponent as HeaderIcon } from '@assets/my-bill.svg';

const BillReview = ({
  bill: {
    total,
    subtotal,
    tax,
    tip,
    items,
  },
  onConfirm,
  onBackUp,
  hasBackUpButton,
  isLoading,
  hasServerError,
  onToastClose,
}) => {
  const buttonText = useCallback(() => {
    if (total === 0) {
      return 'Finish payment';
    }
    if (total) {
      return 'Confirm payment';
    }
    return 'Confirm';
  }, [total]);

  const memoizedItems = useMemo(() => (
    items.map(({
      name, price, optionValues, totalItemPrice, size, voidReason, discountReason, discountAmount,
    }) => (
      <BillItem
        key={uuidv4()}
        voidReason={voidReason}
        discountReason={discountReason}
        discountAmount={discountAmount}
        name={name}
        price={price}
        size={size}
        optionValues={optionValues}
        totalItemPrice={totalItemPrice}
      />
    ))
  ), [items]);

  return (
    <Ordo
      pageTitle="Pay my bill"
      isLoading={isLoading}
      hasBackUpButton={hasBackUpButton}
      title="Pay my bill"
      headerIconSrc={HeaderIcon}
      onBackUp={onBackUp}
      onToastClose={onToastClose}
      hasServerError={hasServerError}
    >
      {!isLoading && (
        <ZigZagWrapper padding="xl3" paddingTop="none">
          <BillWrapper className="zig zag" column padding="lg">
            <Typography paddingTop="md" paddingBottom="md" size="xl4" weight="black">
              Here&apos;s your bill
            </Typography>
            <BillSection
              title="SUBTOTAL"
              amount={subtotal}
            />
            {Boolean(tip) && (
              <BillSection
                title={`Tip${subtotal ? ` (${tip.tipPercent}%)` : ''}`}
                amount={tip.tipInCash}
              />
            )}
            {Boolean(tax) && (
              <BillSection
                title={`GST (${tax.taxPercent}%)`}
                amount={tax.taxInCash}
              />
            )}
            {Boolean(total) && (
              <BillSection
                title="TOTAL"
                amount={total}
                isBolder
              />
            )}
            {memoizedItems}
            <Button.Rectangular
              onClick={onConfirm}
              secondary
              fullWidth
              padding="xl"
              marginTop="xl6"
              marginBottom="xl6"
            >
              {buttonText()}
            </Button.Rectangular>
          </BillWrapper>
        </ZigZagWrapper>
      )}
    </Ordo>
  );
};

const ZigZagWrapper = styled(Flex)`
  ${({ theme: { color, spacing, sizing } }) => css`
    margin: ${spacing.xl4} ${spacing.none};
    filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 1px 2px);

    .zag:before {
      transform: rotate(180deg);
      bottom: 100%;
      background:
        linear-gradient(135deg, ${color.white} 33.333%, transparent 0),
        linear-gradient(-135deg, ${color.white} 33.333%, transparent 0);
    }

    .zag:after {
      top: 100%;
      background:
        linear-gradient(135deg, ${color.white} 33.333%, transparent 0),
        linear-gradient(-135deg, ${color.white} 33.333%, transparent 0);
    }

    .zig {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: -${sizing.zigzag.height};
        width: 100%;
        height: ${sizing.zigzag.height};
        background-size: calc(${sizing.zigzag.height} * 2) 100%;
        background-position: center;
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: ${sizing.zigzag.height};
        background-size: calc(${sizing.zigzag.height} * 2) 100%;
        background-position: center;
      }
    }
  `}
`;

const BillWrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    background: ${color.white};
  `}
`;

BillReview.defaultProps = {
  hasBackUpButton: true,
  isLoading: false,
  onBackUp: () => {},
  hasServerError: false,
  onToastClose: () => {},
};

BillReview.propTypes = {
  hasServerError: bool,
  onToastClose: func,
  isLoading: bool,
  onBackUp: func,
  hasBackUpButton: bool,
  bill: exact({
    total: number,
    subtotal: number,
    tip: exact({
      tipPercent: number,
      tipInCash: number,
    }),
    tax: exact({
      taxPercent: number,
      taxInCash: number,
    }),
    items: arrayOf(exact({
      name: string,
      price: number,
      size: string,
      voidReason: string,
      discountAmount: number,
      discountReason: string,
      totalItemPrice: number,
      specialInstructions: string,
      optionValues: arrayOf(exact({
        name: string,
        price: number,
        quantity: number,
      })),
    })),
  }).isRequired,
  onConfirm: func.isRequired,
};

export default BillReview;
