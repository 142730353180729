import React from 'react';
import { func } from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Typography, Icon,
} from '@atoms';
import { ReactComponent as Remove } from '@assets/remove-item.svg';

const ErrorToast = ({ onToastClose }) => (
  <Wrapper column itemsCenter justifyCenter>
    <Typography size="md" color="white" weight="black">
      Oops, something went wrong :(
    </Typography>
    <Button.Circular
      primaryInverted
      diameter={20}
      onClick={onToastClose}
    >
      <Flex itemsCenter justifyCenter>
        <Icon SVG={Remove} width="12px" height="12px" />
      </Flex>
    </Button.Circular>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ theme: { color, boxShadow } }) => css`
    background: ${color.strawberry};
    box-shadow: ${boxShadow.default};
    border-radius: 20px 0 20px 0;
    width: 230px;
    height: 50px;
    position: fixed;
    top: 10px;
    right: 10px;

    ${Button.Circular} {
      box-shadow: ${boxShadow.default};
      position: absolute;
      top: -3px;
      right: -3px;

      svg {
        path {
          fill: ${color.strawberry};
        }
      }

      &:active {
        svg {
          path {
            fill: ${color.white};
          }
        }
      }
    }
  `}
`;

ErrorToast.propTypes = {
  onToastClose: func.isRequired,
};

export default ErrorToast;
