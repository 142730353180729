// VARIABLES
const printers = [
  {
    name: 'Taproom',
    url: 'https://tbb-001-bar.ordo.party/prepare',
    items: 'bar',
  },
  {
    name: 'Patio',
    url: 'https://tbb-001-patiooutside.ordo.party/prepare',
    items: 'bar',
  },
  {
    name: 'Kitchen',
    url: 'https://tbb-001-kitchen.ordo.party/prepare',
    items: 'kitchen',
  },
];
const request = (url, body) => new Request(url, {
  method: 'POST',
  headers: new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    printkey: process.env.REACT_APP_PRINT_KEY,
  }),
  body: JSON.stringify(body),
});
// CREATE PRINT DETAILS
export const createPrintDetails = (items, user, categories, tableNumber) => {
  try {
    const getItemCategory = itemID => {
      let itemCategory = '';
      Object.keys(categories.byId).forEach(categoryID => {
        if (categories.byId[categoryID].itemIds.includes(itemID)) {
          itemCategory = categories.byId[categoryID].name;
        }
      });
      return itemCategory;
    };
    const kitchenCategories = [
      'Sustenance',
    ];
    const barItems = [];
    const kitchenItems = [];
    items.forEach(item => {
      const category = getItemCategory(item.id);
      const itemData = {
        printed: [],
        customer: `${user.firstName} ${user.lastName || ''}`.trim(),
        name: item.name,
        category,
        addOns: item.optionValues
          .filter(({ price }) => price > 0)
          .map(({ name, quantity }) => ({ name, quantity })),
        options: item.optionValues
          .filter(({ price }) => price === 0)
          .map(({ name, quantity }) => ({ name, quantity })),
        size: item.size,
        price: item.price,
        note: item.specialInstructions || '',
      };
      barItems.push(itemData);
      if (kitchenCategories.includes(category.replaceAll(/'/g, ''))) {
        kitchenItems.push(itemData);
      }
    });
    const printDetails = {
      table: tableNumber,
      items: {
        bar: barItems,
        kitchen: kitchenItems,
      },
    };
    return printDetails;
  } catch (error) {
    return null;
  }
};
// PREPARE TO PRINT
export const prepareToPrint = data => {
  try {
    const {
      id, table, items,
    } = data;

    printers.forEach(printer => {
      const req = request(printer.url, { id, table, items: items[printer.items] });
      fetch(req)
        .then(response => {
          if (!response.ok) {
            throw new Error(`Network response was not ok for ${printer.name} printer.`);
          }
          return response.json(); // or response.text(), etc., depending on the response type
        })
        .then(result => {
          // Handle the data from the response here
          // eslint-disable-next-line no-console
          console.log(`Data from ${printer.name}:`, result);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(`Fetch error for URL ${printer.name}:`, error);
        });
    });

    return true;
  } catch (error) {
    return false;
  }
};
