export const getMenuCategories = categories => categories.sortedIds.map(id => ({
  title: categories.byId[id].name,
  id,
}));

export const getMenuSubcategories = (
  subcategories,
  categories,
  selectedCategoryId,
) => categories.byId[selectedCategoryId].subcategoryIds.map(id => ({
  title: subcategories[id].name,
  id,
}));

export const getMenuOptions = (options, sizes, sizeSelected, optionValues, itemToEditOptions) => (
  sizes[sizeSelected].optionIds.map(id => ({
    name: options[id].name,
    requiredValue: options[id].requiredValue,
    maxRequiredValuesAmount: options[id].maxRequiredValuesAmount || 0,
    allowValueMultipleSelection: options[id].allowValueMultipleSelection,
    valuesSelected: itemToEditOptions?.length
      ? itemToEditOptions.find(option => option.id === id).values.map(value => ({
        id: value.id,
        quantity: value.quantity,
      })) || []
      : options[id].valueIds.filter(valueId => optionValues[valueId].preSelected).map(valueId => ({
        id: valueId,
        quantity: 1,
      })),
    id,
    values: options[id].valueIds.map(valueId => ({
      name: optionValues[valueId].name,
      price: optionValues[valueId].price,
      id: valueId,
      imageSrc: optionValues[valueId].image,
    })),
  })));

export const getMenuSizes = (items, itemId, sizes, sizeSelected) => ({
  sizeSelected,
  sizes: items[itemId].sizeIds.filter(sizeId => !sizes[sizeId].standard).map(sizeId => ({
    name: sizes[sizeId].name,
    price: sizes[sizeId].price,
    specialPrice: sizes[sizeId].specialPrice,
    id: sizeId,
  })),
});

export const getItemPrices = (sizes, sizeSelected, optionsList) => {
  const optionsPrice = optionsList.reduce((totalPrice, option) => totalPrice
    + option.values.reduce((totalOptionPrice, value) => {
      const currentValue = option.valuesSelected.find(optionValue => optionValue.id === value.id);
      if (currentValue) {
        return totalOptionPrice + value.price * currentValue.quantity;
      }
      return totalOptionPrice;
    }, 0),
  0);

  const itemFullPrice = sizes[sizeSelected].price + optionsPrice;
  const itemSpecialPrice = sizes[sizeSelected].specialPrice || sizes[sizeSelected].specialPrice === 0
    ? sizes[sizeSelected].specialPrice + optionsPrice
    : null;

  return [itemFullPrice, itemSpecialPrice];
};

export const getOptionsWithValuesSelected = (options, optionId, valueId) => options.map(option => {
  // case when this option is required to select and we can select more then 1 value of this option
  if (option.valuesSelected.length < option.maxRequiredValuesAmount
    && option.id === optionId
    && option.requiredValue
    && option.maxRequiredValuesAmount > 1) {
    return ({
      ...option,
      valuesSelected: option.valuesSelected.find(value => value.id === valueId)
        ? option.valuesSelected.filter(value => valueId !== value.id)
        : option.valuesSelected.concat(({
          id: valueId,
          quantity: 1,
        })),
    });
  }

  // case when this option is required to select and we can select more then 1 value of this option
  // but we have already reached the max amount of selected values
  if (option.maxRequiredValuesAmount > 1
    && option.id === optionId
    && option.requiredValue) {
    return ({
      ...option,
      valuesSelected: option.valuesSelected.filter(value => valueId !== value.id),
    });
  }

  // case when this option is required to select and we can select only 1 value of this option
  if (option.requiredValue
    && option.id === optionId) {
    return ({
      ...option,
      valuesSelected: [{
        id: valueId,
        quantity: 1,
      }],
    });
  }

  // case when this option is not required to select and we can select any amount of values
  if (option.id === optionId) {
    return ({
      ...option,
      valuesSelected: option.valuesSelected.find(value => value.id === valueId)
        ? option.valuesSelected.filter(value => valueId !== value.id)
        : option.valuesSelected.concat(({
          id: valueId,
          quantity: 1,
        })),
    });
  }
  return { ...option };
});

export const decrementOptionValueQuantity = (options, optionId, valueId) => {
  const updatedOptions = options.map(option => {
    if (option.id === optionId) {
      return {
        ...option,
        valuesSelected: option.valuesSelected.map(value => {
          if (value.id === valueId) {
            return {
              ...value,
              quantity: value.quantity > 1 ? value.quantity - 1 : value.quantity,
            };
          }
          return value;
        }),
      };
    }

    return option;
  });

  return updatedOptions;
};

export const incrementOptionValueQuantity = (options, optionId, valueId) => {
  const updatedOptions = options.map(option => {
    if (option.id === optionId) {
      return {
        ...option,
        valuesSelected: option.valuesSelected.map(value => {
          if (value.id === valueId) {
            return {
              ...value,
              quantity: value.quantity + 1,
            };
          }
          return value;
        }),
      };
    }

    return option;
  });

  return updatedOptions;
};

