import React from 'react';
import { bool, func, node } from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex, Icon, Typography } from '@atoms';
import { ReactComponent as Arrow } from '@assets/drawer-arrow.svg';

const Drawer = ({
  isDrawerOpened,
  onDrawerChangeState,
  isStatic,
  children,
}) => {
  const drawerHeaderText = isDrawerOpened
    ? 'SELECT OPTIONS OR TAP HERE TO CLOSE'
    : 'TAP TO EXPAND AND SELECT YOUR OPTIONS';

  return (
    <Wrapper
      grow={0}
      column
      isDrawerOpened={isDrawerOpened}
    >
      <Trigger
        grow={0}
        shrink={0}
        column
        isDrawerOpened={isDrawerOpened}
        onClick={onDrawerChangeState}
      >
        <Flex justifyCenter padding="md">
          <Typography color="white" size="sm" weight="black">
            {drawerHeaderText}
          </Typography>
        </Flex>
        <ArrowWrapper justifyCenter padding="xxs">
          <Icon SVG={Arrow} width="10px" height="4.2px" />
        </ArrowWrapper>
      </Trigger>
      <Content isDrawerOpened={isDrawerOpened} column isStatic={isStatic}>
        {children}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ isDrawerOpened }) => css`
    height: ${isDrawerOpened ? '100%' : '55%'};
    transition: height 0.2s ease-out;
    overflow: hidden;
    position: relative;
  `}
`;

const ArrowWrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    background: ${color.dust}; // TODO: grey color on design is #d9d9d9

    svg {
      transform: rotate(180deg);
    }
  `}
`;

const Content = styled(Flex)`
  ${({ isStatic }) => css`
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    overflow: hidden;
    justify-content: ${isStatic ? 'space-between' : 'unset'}
  `}
`;

const Trigger = styled(Flex)`
  ${({ isDrawerOpened, theme: { color } }) => css`
    ${Flex}:first-of-type {
      background: ${color.strawberry};
    }

    ${isDrawerOpened && `
      ${Flex}:first-of-type {
        background: ${color.grannySmith};
      }

      ${ArrowWrapper} {
        svg {
          transform: rotate(360deg);
        }
      }
    `}
  `}
`;

Drawer.defaultProps = {
  isStatic: false,
};

Drawer.propTypes = {
  isDrawerOpened: bool.isRequired,
  onDrawerChangeState: func.isRequired,
  children: node.isRequired,
  isStatic: bool,
};

export default Drawer;
