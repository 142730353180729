import React from 'react';
import {
  bool, func,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, Typography, Flex } from '@atoms';
import { Ordo } from '@templates';
import { ReactComponent as HeaderIcon } from '@assets/my-bill.svg';

const CloseMyBill = ({
  hasServerError,
  onToastClose,
  onConfirm,
  onBackUp,
  isHeadOfTheTable,
  isLoading,
}) => {
  const confirmText = `Are you sure you'd like to ${isHeadOfTheTable
    ? 'close all the bills at the table?'
    : 'confirm your bill?'}`;

  const buttonText = `Yes, ${isHeadOfTheTable
    ? 'close all bills'
    : 'confirm my bill'}`;

  return (
    <Ordo
      pageTitle="Confirm my bill"
      hasServerError={hasServerError}
      onToastClose={onToastClose}
      isLoading={isLoading}
      onBackUp={onBackUp}
      title="My bill"
      headerIconSrc={HeaderIcon}
    >
      <Wrapper column padding="xl5">
        <Typography centered marginBottom="xl4" weight="black" size="xl4">
          {confirmText}
        </Typography>
        {isHeadOfTheTable && (
          <Typography centered marginBottom="xl4" size="md" weight="bold" color="warmGrey">
            This will direct everyone at your table you to the payment screen, so make sure you&apos;re ordo&apos;d up!
          </Typography>
        )}
        <Button.Rectangular
          marginBottom="lg"
          secondary
          fullWidth
          padding="xl3"
          onClick={onConfirm}
        >
          {buttonText}
        </Button.Rectangular>
        <Button.Rectangular
          primaryInverted
          fullWidth
          padding="xl3"
          onClick={onBackUp}
        >
          Not ready yet
        </Button.Rectangular>
      </Wrapper>
    </Ordo>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
  `}
`;

CloseMyBill.propTypes = {
  hasServerError: bool.isRequired,
  onToastClose: func.isRequired,
  isLoading: bool.isRequired,
  onConfirm: func.isRequired,
  onBackUp: func.isRequired,
  isHeadOfTheTable: bool.isRequired,
};

export default CloseMyBill;
