import { css } from 'styled-components';

const circular = ({
  disabled,
  primary,
  secondary,
  primaryInverted,
  secondaryInverted,
  theme: {
    color,
  },
}) => {
  switch (true) {
    case disabled:
      return css`
        background: ${color.stone};
        color: ${color.white};

        svg {
          path {
            fill: ${color.white};
          }
        }
      `;

    case primary:
      return css`
        background: ${color.strawberry};
        color: ${color.white};

        &:active {
          background: ${color.white};
          color: ${color.strawberry};

          svg {
            path {
              fill: ${color.strawberry};
            }
          }
        }
      `;

    case secondary:
      return css`
        background: ${color.grannySmith};
        color: ${color.white};

        &:active {
          background: ${color.white};
          color: ${color.grannySmith};

          svg {
            path {
              fill: ${color.grannySmith};
            }
          }
        }
      `;

    case primaryInverted:
      return css`
        background: ${color.white};
        color: ${color.strawberry};

        &:active {
          background: ${color.strawberry};
          color: ${color.white};

          svg {
            path {
              fill: ${color.white};
            }
          }
        }
      `;

    case secondaryInverted:
      return css`
        background: ${color.white};
        color: ${color.grannySmith};

        &:active {
          background: ${color.white};
          color: ${color.grannySmith};

          svg {
            path {
              fill: ${color.grannySmith};
            }
          }
        }
      `;

    default:
      return css`
        background: ${color.white};
        color: ${color.black};
      `;
  }
};

const rectangular = ({
  disabled,
  secondary,
  gradient,
  secondaryInverted,
  primaryInverted,
  primary,
  theme: {
    color,
  },
}) => {
  switch (true) {
    case disabled:
      return css`
        background: ${color.stone};
        color: #707070;

        svg {
          path {
            fill: #707070;
          }
        }
      `;

    case secondary:
      return css`
        background: ${color.grannySmith};
        color: ${color.white};

        &:active {
          background: ${color.white};
          color: ${color.grannySmith};

          svg {
            path {
              fill: ${color.grannySmith};
            }
          }
        }
      `;

    case primary:
      return css`
        background: ${gradient
    ? `linear-gradient(270deg, ${color.pumpkinOrange} 0%, ${color.strawberry} 43%)`
    : color.strawberry};
        color: ${color.white};

        &:active {
          background: ${color.white};
          color: ${color.strawberry};

          svg {
            path {
              fill: ${color.strawberry};
            }
          }
        }
      `;

    case secondaryInverted:
      return css`
        background: ${color.white};
        color: ${color.grannySmith};

        &:active {
          background: ${color.grannySmith};
          color: ${color.white};

          svg {
            path {
              fill: ${color.white};
            }
          }
        }
      `;

    case primaryInverted:
      return css`
        background: ${color.white};
        color: ${color.strawberry};

        &:active {
          background: ${color.strawberry};
          color: ${color.white};

          svg {
            path {
              fill: ${color.white};
            }
          }
        }
      `;

    default:
      return css`
        background: ${color.white};
        color: ${color.black};
      `;
  }
};

const ghost = ({ theme: { color } }) => {
  switch (true) {
    default:
      return css`
        color: ${color.strawberry};
        background-color: transparent;

        &:active {
          opacity: 0.6;
        }
      `;
  }
};

const variant = {
  circular,
  rectangular,
  ghost,
};

export default variant;
