import styled, { css } from 'styled-components';
import { marginMixin, paddingMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Typography = styled.p`
  ${({
    color,
    size,
    weight,
    centered,
    theme: {
      color: colorFromTheme,
      typography,
    },
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    ${padding}
    ${paddingTop}
    ${paddingRight}
    ${paddingBottom}
    ${paddingLeft}

    ${centered && `
      text-align: center;
    `}

    ${color && `
      color: ${colorFromTheme[color]};
    `}

    ${size && `
      font-size: ${typography.size[size]};
    `}

    ${weight && `
      font-weight: ${typography.weight[weight]};
    `}

    font-family: ${typography.family};
    line-height: normal;
  `}
`;

export default Typography;
