import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button, Flex, Typography, Input,
} from '@atoms';
import { Tooltip } from '@molecules';
import { Registration } from '@templates';

const hint = `To view the menu, enter your phone number and receive a code (
this’ll help you pay when you’re done ordo’ing).
Just browsing? No problem! You only pay for what you ordo.`;

const PhoneNumber = ({
  tableNumber,
  seatNumber,
  error,
  onBackUp,
  isLoading,
  isPhoneNumberValid,
  isError,
  phoneNumber,
  isTooltipOpened,
  onTooltipOpen,
  onPhoneNumberChange,
  onPhoneNumberSubmit,
  hasServerError,
  onToastClose,
  onRedirectToStaticMenu,
}) => (
  <Registration
    seatNumber={seatNumber}
    tableNumber={tableNumber}
    onToastClose={onToastClose}
    hasServerError={hasServerError}
    progressValue={20}
    onBackUp={onBackUp}
    isLoading={isLoading}
  >
    <Flex column itemsCenter paddingTop="xl6">
      <Flex grow={0}>
        <Typography size="xl4" weight="black">
          Enter your
          {' '}
          <Typography as="span" color="strawberry" size="xl4" weight="black" strawberry>
            phone number
          </Typography>
        </Typography>
      </Flex>
      {isError && (
        <Flex paddingTop="md">
          <Typography color="strawberry" size="lg" weight="bold">
            {error}
          </Typography>
        </Flex>
      )}
      <Input
        marginTop="xl5"
        marginBottom="xl2"
        type="tel"
        id="phoneNumber"
        name="phoneNumber"
        placeholder="4035551234"
        maxLength="10"
        pattern="[0-9]*"
        value={phoneNumber}
        isError={isError}
        onChange={onPhoneNumberChange}
        data-cy="phoneNumber-input"
      />
      <Button.Rectangular
        primary
        gradient
        fullWidth
        padding="xl2"
        marginBottom="xl2"
        disabled={isError || !isPhoneNumberValid}
        onClick={onPhoneNumberSubmit}
        data-cy="next"
      >
        Next
      </Button.Rectangular>
      <Button.Ghost
        padding="xl2"
        marginBottom="xl2"
        data-cy="next"
        onClick={onRedirectToStaticMenu}
      >
        Table Service Menu
      </Button.Ghost>
      <Tooltip isTooltipOpened={isTooltipOpened} onTooltipOpen={onTooltipOpen}>
        <Typography data-cy="tooltip-hint" color="white" size="lg" weight="bold">
          {hint}
        </Typography>
      </Tooltip>
    </Flex>
  </Registration>
);

PhoneNumber.propTypes = {
  tableNumber: string.isRequired,
  seatNumber: string.isRequired,
  onToastClose: func.isRequired,
  hasServerError: bool.isRequired,
  error: string.isRequired,
  onBackUp: func.isRequired,
  isLoading: bool.isRequired,
  isPhoneNumberValid: bool.isRequired,
  isError: bool.isRequired,
  phoneNumber: string.isRequired,
  isTooltipOpened: bool.isRequired,
  onTooltipOpen: func.isRequired,
  onPhoneNumberChange: func.isRequired,
  onPhoneNumberSubmit: func.isRequired,
  onRedirectToStaticMenu: func.isRequired,
};

export default PhoneNumber;
