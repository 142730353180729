import { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { calculateTimeLeft } from '@helpers/myOrdo';

const useTimer = timerEndTime => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(timerEndTime));

  useEffect(() => {
    const timerTick = setInterval(() => {
      const { minutes, seconds } = calculateTimeLeft(timerEndTime);
      setTimeLeft({ minutes, seconds });
    }, 1000);
    return () => clearInterval(timerTick);
  }, [timerEndTime]);

  return timeLeft;
};

useTimer.propTypes = {
  timerEndTime: string.isRequired,
};

export default useTimer;
