const routes = {
  tableNumber: '/table-number',
  phoneNumber: '/phone-number',
  verificationCode: '/verification-code',
  personalDetails: '/personal-details',
  headOfTheTableWelcome: '/head-of-the-table-welcome',
  headOfTheTablePin: '/head-of-the-table-pin',
  tableMateWelcome: '/table-mate-welcome',
  tableMatePin: '/table-mate-pin',
  staticMenu: '/static-menu',
  staticMenuItems: '/static-menu/items',
  staticMenuItemDetails: '/static-menu/items/:itemId',
  menu: '/menu',
  menuItems: '/menu/items',
  menuItemDetails: '/menu/items/:itemId',
  myOrdo: '/my-ordo',
  myBill: '/my-bill',
  closeMyBill: '/close-my-bill',
  billReview: '/bill-review',
  tipForServer: '/tip-for-server',
  customTip: '/custom-tip',
  finalBillReview: '/final-bill-review',
  checkout: '/checkout',
  helpMe: '/help-me',
  checkoutSuccess: '/checkout/success',
  changeOrdoMenuCategories: '/change-ordo/menu',
  changeOrdoMenuItems: '/change-ordo/menu/items',
  changeOrdoMenuItemDetails: '/change-ordo/menu/items/:itemId',
  preAuthorization: '/pre-authorization',
};

export default routes;

