import React from 'react';
import styled, { css } from 'styled-components';
import { func } from 'prop-types';
import { Flex, Icon, Typography } from '@atoms';
import { Footer } from '@organisms';

import { getRestaurantRelatedData } from '@helpers/registration';

const HelpMe = ({ onBackUp }) => {
  const { logo, contactPhone: { phoneNumber, phoneNumberUrl } } = getRestaurantRelatedData();

  const isGeorges = window.location.host === 'georges.ordo.menu';

  return (
    <Wrapper column>
      <Flex grow={0} justifyCenter padding={isGeorges ? 'xl3' : 'xl6'}>
        <Icon SVG={logo} width="154px" height={isGeorges ? '70px' : '25px'} />
      </Flex>
      <Flex padding="xl3" column>
        <Typography centered size="xl4" weight="black" marginBottom="xl4">
          Have any question?
          <br />
          Just contact us:
        </Typography>
        <PhoneNumber href={`tel:${phoneNumberUrl}`}>
          <Typography size="xl6" color="strawberry" weight="black">
            {phoneNumber}
          </Typography>
        </PhoneNumber>
      </Flex>
      <Footer onBackUp={onBackUp} hasHelpMeButton={false} hasBackUpButton />
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { sizing } }) => css`
    position: relative;
    min-height: calc(100% - ${sizing.footer.height});
    margin-bottom: ${sizing.footer.height};
    text-align: center;
  `}
`;

const PhoneNumber = styled.a`
  ${() => css`
    text-decoration: none;
  `}
`;

HelpMe.propTypes = {
  onBackUp: func.isRequired,
};

export default HelpMe;
