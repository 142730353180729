import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HeadOfTheTableWelcome as View } from '@views';
import { routes } from '@constants';

const HeadOfTheTableWelcome = () => {
  const { tableNumber, seatNumber } = useSelector(store => store.registrationStore);

  const history = useHistory();

  const handleProceed = () => {
    history.push(routes.headOfTheTablePin);
  };

  return (
    <View
      tableNumber={tableNumber}
      seatNumber={seatNumber}
      onProceed={handleProceed}
    />
  );
};

export default HeadOfTheTableWelcome;
