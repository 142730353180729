import React from 'react';
import {
  arrayOf, exact, func, string, number, bool,
} from 'prop-types';
import { CurrentOrdo, OrdoHistory, PreparingOrdos } from '@organisms';
import { Ordo } from '@templates';
import { ReactComponent as HeaderIcon } from '@assets/my-ordo.svg';
import { common } from '@constants';

const MyOrdo = ({
  onClosingOrdoItemEdit,
  onToastClose,
  hasServerError,
  pendingOrdos,
  isOrdoPlacedByCurrentUser,
  isOrdoPlaced,
  closingAt,
  isLoading,
  onItemRemove,
  userId,
  orderedItems,
  currentOrdo,
  ordoHistory,
  onOrdoPlace,
  onDelete,
  onBackUp,
}) => {
  // eslint-disable-next-line no-unused-vars
  const confirmButtonText = 'Pay';

  return (
    <Ordo
      pageTitle="Ordo'ing"
      isPreauthSeen={common.isPreauthAvailable}
      // footerChild={(
      //   <Button.Rectangular
      //     secondaryInverted
      //     disabled={isConfirmAllBillsDisabled}
      //     fullWidth
      //     padding="md"
      //     onClick={onBillConfirm}
      //   >
      //     <Flex itemsCenter justifyCenter>
      //       <Icon SVG={Bill} width="21px" height="28px" />
      //       <Typography size="lg" weight="black" marginLeft="sm">
      //         {confirmButtonText}
      //       </Typography>
      //     </Flex>
      //   </Button.Rectangular>
      // )}
      isLoading={isLoading}
      onBackUp={onBackUp}
      title="ordo'ing"
      headerIconSrc={HeaderIcon}
      hasServerError={hasServerError}
      onToastClose={onToastClose}
    >
      <CurrentOrdo
        closingAt={closingAt}
        isOrdoPlaced={isOrdoPlaced}
        isOrdoPlacedByCurrentUser={isOrdoPlacedByCurrentUser}
        currentOrdo={currentOrdo}
        onOrdoPlace={onOrdoPlace}
        onDelete={onDelete}
        onBackToMenu={onBackUp}
        onItemRemove={onItemRemove}
      />
      { (!ordoHistory.length || orderedItems.length > 0 || pendingOrdos.length > 0) && (
        <PreparingOrdos
          onClosingOrdoItemEdit={onClosingOrdoItemEdit}
          orderedItems={orderedItems}
          onBackToMenu={onBackUp}
          pendingOrdos={pendingOrdos}
          userId={userId}
        />
      )}
      { !!ordoHistory.length && (
        <OrdoHistory
          ordoHistory={ordoHistory}
          userId={userId}
        />
      )}
    </Ordo>
  );
};

MyOrdo.propTypes = {
  onClosingOrdoItemEdit: func.isRequired,
  onToastClose: func.isRequired,
  hasServerError: bool.isRequired,
  isOrdoPlacedByCurrentUser: bool.isRequired,
  isOrdoPlaced: bool.isRequired,
  closingAt: string.isRequired,
  isLoading: bool.isRequired,
  userId: number.isRequired,
  onItemRemove: func.isRequired,
  orderedItems: arrayOf(exact({
    id: number,
    menuItemId: number,
    name: string,
    price: number,
    size: string,
    specialInstructions: string,
    optionValues: arrayOf(exact({
      name: string,
      price: number,
      quantity: number,
      isRequired: bool,
    })),
  })).isRequired,
  pendingOrdos: arrayOf(exact({
    deliversAt: string,
    orderedAt: string,
    ordoUserFirstName: string,
    ordoUserId: number,
    ordoItems: arrayOf(exact({
      size: string,
      name: string,
      price: number,
      specialInstructions: string,
      isVoided: bool,
      optionValues: arrayOf(exact({
        name: string,
        price: number,
        quantity: number,
        isRequired: bool,
      })),
    })),
  })).isRequired,
  currentOrdo: arrayOf(exact({
    id: number,
    name: string,
    ordoItemKey: string,
    price: number,
    size: string,
    specialInstructions: string,
    optionValues: arrayOf(exact({
      name: string,
      price: number,
      quantity: number,
      isRequired: bool,
    })),
  })).isRequired,
  ordoHistory: arrayOf(exact({
    deliversAt: string,
    orderedAt: string,
    ordoUserFirstName: string,
    ordoUserId: number,
    ordoItems: arrayOf(exact({
      name: string,
      price: number,
      size: string,
      specialInstructions: string,
      isVoided: bool,
      optionValues: arrayOf(exact({
        name: string,
        price: number,
        quantity: number,
        isRequired: bool,
      })),
    })),
  })).isRequired,
  onOrdoPlace: func.isRequired,
  onDelete: func.isRequired,
  onBackUp: func.isRequired,
};

export default MyOrdo;
