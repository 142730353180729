import { apiEndpoints } from '@constants';
import { client } from '@utils';

const placeOrdo = data => (
  client.authorized.post(apiEndpoints.ordo, data)
);

const closeAllBills = () => (
  client.authorized.post(apiEndpoints.closeAllBillsMyOrdo)
);

const addItemToOrdoByStaff = (data, token) => (
  client.unauthorized.post(apiEndpoints.addItemToOrdoByStaff, data, {
    headers: {
      token,
    },
  })
);

const editItemInOrdo = (data, token) => (
  client.unauthorized.put(apiEndpoints.editItemInOrdo, data, {
    headers: {
      token,
    },
  })
);

export {
  placeOrdo,
  closeAllBills,
  addItemToOrdoByStaff,
  editItemInOrdo,
};
