import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  string, exact, arrayOf, func, number, oneOfType, oneOf,
} from 'prop-types';
import { Flex, Typography } from '@atoms';
import { ItemSize } from '@molecules';

const MenuItemSizes = ({
  sizes,
  sizeSelected,
  onSizeSelect,
}) => {
  const memoizedSizes = useMemo(() => sizes.map(({
    id, name, price, specialPrice,
  }) => (
    <ItemSize
      id={id}
      key={id}
      name={name}
      price={specialPrice || specialPrice === 0 ? specialPrice : price}
      isSelected={sizeSelected === id}
      onSizeSelect={onSizeSelect}
    />
  )), [sizeSelected]);

  return (
    <Wrapper shrink={0} column paddingTop="xl" paddingBottom="xl">
      <Typography as="span" size="md" weight="black">
        SIZES
      </Typography>
      <Container
        paddingLeft="xl4"
        paddingRight="xl4"
        marginTop="md"
      >
        {memoizedSizes}
      </Container>
    </Wrapper>
  );
};

MenuItemSizes.propTypes = {
  sizeSelected: number.isRequired,
  sizes: arrayOf(exact({
    id: number,
    name: string,
    price: number,
    specialPrice: oneOfType([
      number,
      oneOf([null]),
    ]),
  })).isRequired,
  onSizeSelect: func.isRequired,
};

const Container = styled(Flex)`
  overflow: auto;
  white-space: nowrap;
  display: inline-block;

  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  &::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
`;

const Wrapper = styled(Flex)`
  border-top: 1px solid #d9d9d9;
  text-align: center;
`;

export default MenuItemSizes;
