import { getItemPrice, getOptionValues } from '@helpers/myOrdo';

export const getSessionOrdos = (users, items, optionValues, sizes, userId, isUserLeavingEarlier, options) => {
  const sortedUsers = users
    .sort((firstUser, secondUser) => { // Move current user at the beginning of array
      if (firstUser.id === userId) { return -1; }
      if (secondUser.id === userId) { return 1; }
      return 0;
    });

  const ordoUsers = sortedUsers.map(user => ({
    isPayedUp: user.isPayedUp && userId !== user.id,
    ordoUserId: user.id,
    ordoUserFirstName: user.userName,
    ordoToPay: user.items.map(item => ({
      id: item.id,
      name: items[item.menuItemId].name,
      price: getItemPrice(sizes, item.size.id, item.size.options, optionValues),
      isVoided: !!item.voidReason,
      specialInstructions: item.specialInstructions,
      size: sizes[item.size.id].name,
      optionValues: getOptionValues(optionValues, item.size.options, options),
    })),
  }));

  return isUserLeavingEarlier ? ordoUsers.filter(ordo => ordo.ordoUserId === userId) : ordoUsers;
};

const getUserItemPrice = (sizePrice, options, discountAmount) => (discountAmount ? -discountAmount : 0) + sizePrice
  + options.reduce((totalPrice, option) => totalPrice
  + option.values.reduce((totalOptionPrice, value) => totalOptionPrice + value.price * value.quantity, 0),
  0);

const getUserOptionValues = (optionValues, itemOptions) => itemOptions.reduce((values, option) => {
  option.values.forEach(value => {
    if (value.price) {
      values.push({
        price: value.price,
        name: optionValues[value.id].name,
        quantity: value.quantity,
      });
    }
  });
  return values;
}, []);

export const getUserItems = (userItems, items, optionValues, sizes) => (Object.keys(items).length
  ? userItems.map(item => ({
    name: items[item.id].name,
    price: sizes[item.size.id].specialPrice ?? item.size.price,
    size: sizes[item.size.id].name,
    specialInstructions: item.specialInstructions,
    voidReason: item.voidReason,
    discountAmount: item.discountAmount,
    discountReason: item.discountReason,
    totalItemPrice: getUserItemPrice(sizes[item.size.id].specialPrice
    || sizes[item.size.id].specialPrice === 0 ? sizes[item.size.id].specialPrice : item.size.price,
    item.options, item.discountAmount),
    optionValues: getUserOptionValues(optionValues, item.options),
  }))
  : []);
