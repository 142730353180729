import React from 'react';
import {
  bool, func, number,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Flex, Button, Typography, Icon, Circle,
} from '@atoms';
import { ReactComponent as PlaceOrdoMenu } from '@assets/place-ordo-menu.svg';

const PlaceOrdo = ({
  selectedMenuItemsQuantity, onOrdoPlace, isPlaceOrdoVisible,
}) => (
  <Wrapper
    selfCenter
    column
    itemsCenter
    isPlaceOrdoVisible={isPlaceOrdoVisible}
    grow={0}
  >
    <Container>
      {parseInt(selectedMenuItemsQuantity, 10) > 0 && (
        <Quantity itemsCenter justifyCenter diameter={23} backgroundColor="strawberry">
          <Typography color="white" size="sm" weight="black">
            {selectedMenuItemsQuantity}
          </Typography>
        </Quantity>
      )}
      <Button.Circular
        data-cy="placeOrdo"
        onClick={onOrdoPlace}
        diameter={58}
        secondary
      >
        <Icon SVG={PlaceOrdoMenu} width="26px" height="26px" />
      </Button.Circular>
    </Container>
    <Button.Rectangular
      padding="xs"
      secondary
      marginTop="xxs"
      onClick={onOrdoPlace}
    >
      <Typography size="sm" weight="bold">
        ORDO
      </Typography>
    </Button.Rectangular>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ isPlaceOrdoVisible }) => css`
    visibility: ${isPlaceOrdoVisible ? 'visible' : 'hidden'}
  `}
`;

const Container = styled(Flex)`
  position: relative;
`;

const Quantity = styled(Circle)`
  position: absolute;
  top: 0;
  right: -3px;
`;

PlaceOrdo.propTypes = {
  isPlaceOrdoVisible: bool.isRequired,
  selectedMenuItemsQuantity: number.isRequired,
  onOrdoPlace: func.isRequired,
};

export default PlaceOrdo;
