import React, { useEffect, useMemo, useState } from 'react';
import { validate } from 'email-validator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PersonalDetails as View } from '@views';
import {
  LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { submitPersonalDetails } from '@store/registration/duck';
import { routes, common } from '@constants';
import { isClientError } from '@helpers/errorHandling';

const PersonalDetails = () => {
  const {
    user,
    seatNumber,
    tableNumber,
    verificationCode,
    submitPersonalDetailsPhase,
    submitPersonalDetailsError,
  } = useSelector(store => store.registrationStore);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [error, setError] = useState('');
  const [isTooltipOpened, setTooltipOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const isFirstNameValid = useMemo(() => (
    Boolean(firstName.length)
  ), [firstName]);

  const isEmailValid = useMemo(() => (
    validate(email) || !email
  ), [email]);

  const handleFirstNameChange = e => {
    if (e.target.validity.valid) {
      setFirstName(e.target.value);
    }
  };

  const handleLastNameChange = e => {
    if (e.target.validity.valid) {
      setLastName(e.target.value);
    }
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
    setIsError(false);
  };

  const handlePersonalDetailsSubmit = () => {
    const { phoneNumber } = user;

    dispatch(submitPersonalDetails({
      firstName,
      ...(lastName && { lastName }),
      ...(email && { email }),
      phoneNumber,
      tableNumber,
      verificationCode,
    }));
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(prevIsTooltipOpen => !prevIsTooltipOpen);
  };

  const handleBackUp = () => {
    history.push(routes.verificationCode);
  };

  useEffect(() => {
    if (submitPersonalDetailsPhase === SUCCESS) {
      if (user.preauthStatus === common.PREAUTH_SUCCESS || !common.isPreauthAvailable) {
        history.push({
          pathname: routes.menu,
          state: {
            shouldFetchMenu: true,
          },
        });
      } else {
        history.push(routes.preAuthorization);
      }
    } else if (submitPersonalDetailsPhase === FAILURE) {
      if (submitPersonalDetailsError
        && isClientError(submitPersonalDetailsError.data.status)) { // Case when we get 4** error
        setIsError(true);
        setError(submitPersonalDetailsError.data.error);
      } else {
        setHasServerError(true);
      }
    }
  }, [submitPersonalDetailsPhase]);

  return (
    <View
      isEmailValid={isEmailValid}
      seatNumber={seatNumber}
      tableNumber={tableNumber}
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      isLoading={submitPersonalDetailsPhase === LOADING}
      isFirstNameValid={isFirstNameValid}
      isError={isError}
      error={error}
      firstName={firstName}
      lastName={lastName}
      email={email}
      isTooltipOpened={isTooltipOpened}
      onTooltipOpen={handleTooltipOpen}
      onFirstNameChange={handleFirstNameChange}
      onLastNameChange={handleLastNameChange}
      onEmailChange={handleEmailChange}
      onPersonalDetailsSubmit={handlePersonalDetailsSubmit}
      onBackUp={handleBackUp}
    />
  );
};

export default PersonalDetails;
