const color = {
  black: '#000000',
  dust: '#d6d6d6',
  grannySmith: '#1bba45',
  pumpkinOrange: '#fd6f24',
  stone: '#bcbcbc',
  strawberry: '#f5333f',
  warmGrey: '#a0a0a0',
  lightGrey: '#f8f8f8',
  darkGrey: '#242424',
  white: '#ffffff',
  grey: '#f0f0f0',
  sunYellow: '#ffde17',
};

const theme = {
  border: {
    radius: {
      circular: '50%',
      default: '44px',
      ordoItem: '23px',
      modal: '23px',
      hint: '16px',
      tip: '12px',
      stripeElement: '6px',
    },
  },
  boxShadow: {
    default: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    stripeElement: '0 1px 6px 0 rgba(0, 0, 0, 0.12)',
  },
  color,
  spacing: {
    none: '0px',
    xxxs: '2px',
    xxs: '4px',
    xs: '6px',
    sm: '8px',
    md: '12px',
    lg: '14px',
    xl: '16px',
    xl2: '18px',
    xl3: '20px',
    xl4: '24px',
    xl5: '32px',
    xl6: '48px',
    xl7: '64px',
    xl8: '72px',
  },
  sizing: {
    dot: {
      diameter: '17px',
    },
    footer: {
      height: '108.4px',
    },
    loader: {
      width: '95px',
    },
    option: {
      width: '57px',
    },
    tip: {
      maxWidth: '53px',
    },
    zigzag: {
      height: '10px',
    },
    tile: {
      height: '50vw',
    },
    modal: {
      buttonClose: {
        top: '10px',
        right: '10px',
      },
      left: '27px',
      right: '27px',
    },
    menuBar: {
      height: 56,
    },
    payForTheWholeTableButton: {
      borderRadius: '20px 20px 0px 0px',
      right: '7px',
    },
  },
  typography: {
    family: 'CeraRoundBasic, sans-serif',
    size: {
      xxs: '8px',
      xs: '10px',
      sm: '12px',
      md: '14px',
      lg: '16px',
      xl: '18px',
      xl2: '20px',
      xl3: '22px',
      xl4: '24px',
      xl5: '32px',
      xl6: '48px',
    },
    weight: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
  },
};

export default theme;
