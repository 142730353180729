import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '@atoms';

const Loader = () => (
  <Wrapper itemsCenter justifyCenter>
    <AnimationContainer justifyBetween grow={0}>
      <JumpingDot />
      <JumpingDot />
      <JumpingDot />
    </AnimationContainer>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const JumpingDot = styled.div`
  ${({ theme: { color, sizing } }) => css`
    display: inline-block;
    width: ${sizing.dot.diameter};
    height: ${sizing.dot.diameter};
    border-radius: 50%;
    background-color: ${color.strawberry};

    animation-name: jumpingDots;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes jumpingDots {
      20% {
        transform: translateY(0px);
      }
      50% {
        transform: translateY(-20px);
      }
    }
  `}
`;

const AnimationContainer = styled(Flex)`
  ${({ theme: { sizing } }) => css`
    width: ${sizing.loader.width};

    ${JumpingDot}:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    ${JumpingDot}:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  `}
`;

export default Loader;
