import React, { useCallback } from 'react';
import {
  arrayOf, exact, string, func, number, bool,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  Flex, Button, Typography, Icon,
} from '@atoms';
import { OrdoItem, EmptyOrdo, CurrentOrdoTimer } from '@molecules';
import { ReactComponent as PlaceOrdo } from '@assets/place-my-ordo.svg';

const CurrentOrdo = ({
  isOrdoPlacedByCurrentUser,
  currentOrdo,
  onBackToMenu,
  onOrdoPlace,
  onItemRemove,
  isOrdoPlaced,
  closingAt,
}) => {
  const currentOrdoText = useCallback(() => {
    if (isOrdoPlacedByCurrentUser && closingAt) {
      return (
        <Typography centered weight="bold" size="md" marginBottom="md" marginTop="xl5">
          You&apos;ve placed an ordo. Your table mates have
          <CurrentOrdoTimer closingAt={closingAt} />
          to add items before it&apos;s sent to the kitchen!
        </Typography>
      );
    }
    if (isOrdoPlaced && closingAt) {
      return (
        <Typography centered weight="bold" size="md" marginBottom="xl4" marginTop="xl5">
          Someone at your table has placed an ordo. You have
          <CurrentOrdoTimer closingAt={closingAt} />
          to add items before it&apos;s sent to the kitchen!
        </Typography>
      );
    }
    return (
      <Typography centered weight="bold" size="md" marginBottom="xl4" marginTop="xl5">
        You&apos;ve started an ordo. You can let your table mates
        know they&apos;ll only have
        {' '}
        <Typography as="span" weight="bold" size="md" color="strawberry">
          2 minutes
        </Typography>
        {' '}
        to add to this ordo once you place this ordo!
      </Typography>
    );
  }, [isOrdoPlacedByCurrentUser, isOrdoPlaced]);

  const ordo = (
    <Flex column>
      <Typography weight="bold" color="warmGrey" size="md" marginBottom="xl4">
        Items your table hasn’t sent yet
      </Typography>
      <Flex itemsCenter column>
        {currentOrdo.map(
          ({
            name, price, optionValues, id, ordoItemKey, size, isRequired, specialInstructions,
          }) => (
            <OrdoItem
              isRequired={isRequired}
              size={size}
              ordoItemKey={ordoItemKey}
              key={uuidv4()}
              id={id}
              canBeDeleted
              onItemRemove={onItemRemove}
              specialInstructions={specialInstructions}
              name={name}
              price={price}
              optionValues={optionValues}
            />
          ),
        )}
        <Button.Rectangular marginTop="xxs" padding="md" onClick={onBackToMenu}>
          <Typography size="md">
            Back to menu
          </Typography>
        </Button.Rectangular>
        {currentOrdoText()}
        <Button.Rectangular
          onClick={onOrdoPlace}
          secondary
          fullWidth
          padding="xl"
          marginBottom="xl2"
        >
          <Flex justifyCenter itemsCenter>
            <Icon SVG={PlaceOrdo} width="25px" height="18px" />
            <Typography data-cy="place-this-ordo" marginLeft="xl" weight="black">
              Place this ordo
            </Typography>
          </Flex>
        </Button.Rectangular>
        <Typography centered weight="bold" color="warmGrey" size="md">
          Tap this to send these items to kitchen/bar to be prepared and delivered to your table
        </Typography>
      </Flex>
    </Flex>
  );

  return (
    <Wrapper padding="xl5" column>
      <Typography weight="black" size="xl" marginBottom="sm">
        Items in my current ordo:
      </Typography>
      {currentOrdo.length
        ? ordo
        : (
          <>
            <EmptyOrdo isCurrent onBackToMenu={onBackToMenu} />
            {(isOrdoPlacedByCurrentUser || isOrdoPlaced) && currentOrdoText()}
          </>
        )}
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
    border-bottom: 1px solid ${color.dust};
    background-color: ${color.lightGrey};
  `}
`;

CurrentOrdo.propTypes = {
  isOrdoPlacedByCurrentUser: bool.isRequired,
  onBackToMenu: func.isRequired,
  isOrdoPlaced: bool.isRequired,
  closingAt: string.isRequired,
  currentOrdo: arrayOf(exact({
    name: string,
    id: number,
    ordoItemKey: string,
    price: number,
    size: string,
    specialInstructions: string,
    optionValues: arrayOf(exact({
      name: string,
      quantity: number,
      price: number,
      isRequired: bool,
    })),
  })).isRequired,
  onOrdoPlace: func.isRequired,
  onItemRemove: func.isRequired,
};

export default CurrentOrdo;
