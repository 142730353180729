import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'debounce';

import { MenuItems as View } from '@views';
import { routes } from '@constants';
import {
  getMenuCategories, getMenuSubcategories, getMenuItems, getIsItemInViewPort,
} from '@helpers/menuItems';
import { usePrevious } from '@hooks';
import { setSelectedCategory } from '@store/menu/duck';

// 112px is a header height and we assume that menu item is in viewport
// when it's seen below header
const headerHeight = 115;
const footerHeight = 106;
const categoriesContainerLeftPadding = 12;

const StaticMenuItems = () => {
  const {
    categories, subcategories, items, sizes, selectedCategoryId,
  } = useSelector(state => state.menuStore);

  const history = useHistory();
  const dispatch = useDispatch();

  const [activeCategoryId, setActiveCategoryId] = useState(selectedCategoryId);

  const initialSubcategoryId = categories.byId[activeCategoryId].subcategoryIds[0];
  const initialSecondSubcategoryId = categories.byId[activeCategoryId].subcategoryIds[1];

  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(initialSubcategoryId);
  const [nextSubcategoryId, setNextSubcategoryId] = useState(initialSecondSubcategoryId);

  const [scrollYCoord, setScrollYCoord] = useState(0);
  const previousScrollYCoord = usePrevious(scrollYCoord);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeCategoryId]);

  useEffect(() => {
    if (!selectedSubcategoryId) return null;

    const debouncedScrollEventHandler = debounce(handleScrollEvent, 5);
    document.addEventListener('scroll', debouncedScrollEventHandler);

    return () => {
      document.removeEventListener('scroll', debouncedScrollEventHandler);
    };
  }, [selectedSubcategoryId, nextSubcategoryId]);

  // scrolling handler
  useEffect(() => {
    if (scrollYCoord <= previousScrollYCoord) {
      handleScrollDownEvent();
    } else {
      handleScrollUpEvent();
    }
  }, [scrollYCoord]);

  const handleScrollEvent = () => {
    const menuItemsContainer = document.getElementById('menu-items-container');
    if (!menuItemsContainer) return;

    setScrollYCoord(menuItemsContainer.getBoundingClientRect().top);
  };

  const handleScrollUpEvent = () => {
    // in order not to use complicated logic to find a previous subcategory and then find last item
    // of that category we can just find first item of current category and get previous item
    // the same type (previousSibling) from DOM (f.e. here we get previous <div> in same container)
    const itemOfPrevCategory = document.getElementById(`menu-item-from-subcategory-${selectedSubcategoryId}`)
      ? document.getElementById(`menu-item-from-subcategory-${selectedSubcategoryId}`).previousSibling
      : null;

    if (itemOfPrevCategory && getIsItemInViewPort(itemOfPrevCategory, headerHeight, footerHeight)) {
      const prevItemId = itemOfPrevCategory.id.split('-');
      const prevSubcategoryId = +prevItemId[prevItemId.length - 1];
      handleSubcategorySelect(prevSubcategoryId)();
    }
  };

  const handleScrollDownEvent = () => {
    const itemOfNextCategory = document.getElementById(`menu-item-from-subcategory-${nextSubcategoryId}`);
    if (itemOfNextCategory && getIsItemInViewPort(itemOfNextCategory, headerHeight, footerHeight)) {
      handleSubcategorySelect(nextSubcategoryId)();
    }
  };

  const handleMenuItemSelect = id => () => {
    dispatch(setSelectedCategory({
      categoryId: activeCategoryId,
    }));

    history.push(`${routes.staticMenuItems}/${id}`);
  };

  const setSubcategoriesInState = subcategoryId => {
    // set selected subcategory id and the next one's id
    const subcategoriesOfCurrentCategory = categories.byId[selectedCategoryId].subcategoryIds;
    const currentSubcategoryIndex = subcategoriesOfCurrentCategory.findIndex(id => id === subcategoryId);
    const nextSelectedSubcategoryId = categories.byId[selectedCategoryId].subcategoryIds[currentSubcategoryIndex + 1];

    setSelectedSubcategoryId(subcategoryId);
    setNextSubcategoryId(nextSelectedSubcategoryId);
  };

  const handleSubcategorySelect = subcategoryId => () => {
    setSubcategoriesInState(subcategoryId);

    // scroll subcategory to the left side of it container
    const selectedSubcategoryElement = document.getElementById(`subcategory-${subcategoryId}`);
    const subcategoriesContainerElement = document.getElementById('subcategories-container');

    // 12px is a left padding in container
    const leftOffset = selectedSubcategoryElement.offsetLeft - categoriesContainerLeftPadding;
    subcategoriesContainerElement.scrollTo({ left: leftOffset, behavior: 'smooth' });
  };

  const handleCategorySelect = categoryId => () => {
    setActiveCategoryId(categoryId);
    const selectedCategoryElement = document.getElementById(`category-${categoryId}`);
    const categoriesContainerElement = document.getElementById('categories-container');

    // 12px is a left padding in container
    const leftOffset = selectedCategoryElement.offsetLeft - categoriesContainerLeftPadding;
    categoriesContainerElement.scrollTo({ left: leftOffset, behavior: 'smooth' });

    // set first subcategory in category as selected
    const firstSubcategoryInCategoryId = categories.byId[categoryId].subcategoryIds[0];
    setSubcategoriesInState(firstSubcategoryInCategoryId);
  };

  const handleBackUp = () => {
    history.push(routes.staticMenu);
  };

  const memoizedCategories = useMemo(() => (
    getMenuCategories(categories).filter(category => category.title !== 'Assistance')
  ), [activeCategoryId]);

  const memoizedSubcategories = useMemo(() => (
    getMenuSubcategories(subcategories, categories, activeCategoryId)
  ), [activeCategoryId, subcategories]);

  const memoizedItems = useMemo(() => (
    getMenuItems(categories, activeCategoryId, items, sizes, subcategories)
  ), [activeCategoryId, items, subcategories]);

  return (
    <View
      isPlaceOrdoVisible={false}
      categories={memoizedCategories}
      subcategories={memoizedSubcategories}
      selectedCategoryId={activeCategoryId}
      selectedSubcategoryId={selectedSubcategoryId}
      menuItems={memoizedItems}
      onMenuItemSelect={handleMenuItemSelect}
      onBackUp={handleBackUp}
      onCategorySelect={handleCategorySelect}
      onSubcategorySelect={handleSubcategorySelect}
      isStatic
    />
  );
};

export default StaticMenuItems;
