import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TableMateWelcome as View } from '@views';
import { routes } from '@constants';

const TableMateWelcome = () => {
  const { headOfTheTableFirstName, tableNumber } = useSelector(store => store.registrationStore);

  const history = useHistory();

  const handleTableJoin = () => {
    history.push(routes.tableMatePin);
  };

  const handleWrongTable = () => {
    history.push(routes.tableNumber);
  };

  return (
    <View
      headOfTheTableFirstName={headOfTheTableFirstName}
      tableNumberToJoin={tableNumber}
      onTableJoin={handleTableJoin}
      onWrongTable={handleWrongTable}
    />
  );
};

export default TableMateWelcome;
