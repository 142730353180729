import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { HelpMe as View } from '@views';

const HelpMe = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = 'Help me';
  }, []);

  const handleBackUp = () => {
    history.goBack();
  };

  return (
    <View
      onBackUp={handleBackUp}
    />
  );
};

export default HelpMe;
