import React, { useMemo } from 'react';
import {
  arrayOf, oneOf, oneOfType, bool, exact, func, string, number,
} from 'prop-types';
import { Flex } from '@atoms';
import { MenuHeader, MenuItem } from '@molecules';
import { Menu } from '@templates';

const MenuItems = ({
  isPlaceOrdoVisible,
  tableNumber,
  tablePin,
  seatNumber,
  onBackUp,
  onMenuItemSelect,
  menuItems,
  categories,
  subcategories,
  onCategorySelect,
  onSubcategorySelect,
  selectedCategoryId,
  selectedSubcategoryId,
  onOrdoPlace,
  ordoClosingAt,
  selectedMenuItemsQuantity,
  isStatic,
}) => {
  const memoizedItems = useMemo(() => (
    menuItems.map(({
      id,
      imageSrc,
      isMustTry,
      name,
      price,
      specialPrice,
      subtitle,
      subcategoryId,
    }) => (
      <MenuItem
        subcategoryId={subcategoryId}
        subtitle={subtitle}
        key={id}
        imageSrc={imageSrc}
        isMustTry={isMustTry}
        name={name}
        price={price}
        specialPrice={specialPrice}
        onMenuItemSelect={onMenuItemSelect(id)}
        isStatic={isStatic}
      />
    ))
  ), [menuItems]);

  return (
    <Menu
      isPlaceOrdoVisible={isPlaceOrdoVisible}
      tableNumber={tableNumber}
      tablePin={tablePin}
      seatNumber={seatNumber}
      onBackUp={onBackUp}
      hasBackToTopButton
      selectedMenuItemsQuantity={selectedMenuItemsQuantity}
      onOrdoPlace={onOrdoPlace}
      ordoClosingAt={ordoClosingAt}
      isStatic={isStatic}
    >
      <MenuHeader
        categories={categories}
        subcategories={subcategories}
        onCategorySelect={onCategorySelect}
        onSubcategorySelect={onSubcategorySelect}
        selectedCategoryId={selectedCategoryId}
        selectedSubcategoryId={selectedSubcategoryId}
      />
      <Flex id="menu-items-container" wrapFlex>
        {memoizedItems}
      </Flex>
    </Menu>
  );
};

MenuItems.defaultProps = {
  onSubcategorySelect: () => {},
  selectedSubcategoryId: null,
  seatNumber: '',
  tablePin: '',
  tableNumber: '',
  onOrdoPlace: () => {},
  selectedMenuItemsQuantity: 0,
  ordoClosingAt: '',
  isStatic: false,
};

MenuItems.propTypes = {
  isPlaceOrdoVisible: bool.isRequired,
  seatNumber: string,
  tableNumber: string,
  tablePin: string,
  onBackUp: func.isRequired,
  onMenuItemSelect: func.isRequired,
  onOrdoPlace: func,
  menuItems: arrayOf(exact({
    subcategoryId: string,
    name: string,
    price: number,
    specialPrice: oneOfType([
      number,
      oneOf([null]),
    ]),
    subtitle: string,
    imageSrc: string,
    id: number,
    isMustTry: bool,
  })).isRequired,
  categories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  subcategories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  onCategorySelect: func.isRequired,
  onSubcategorySelect: func,
  selectedCategoryId: number.isRequired,
  selectedSubcategoryId: number,
  selectedMenuItemsQuantity: number,
  ordoClosingAt: string,
  isStatic: bool,
};

export default MenuItems;
