import React from 'react';
import { bool, func } from 'prop-types';
import {
  Flex, Typography, Icon, Button,
} from '@atoms';
import { ReactComponent as MyOrdoIcon } from '@assets/my-ordo.svg';

const EmptyOrdo = ({ isCurrent, onBackToMenu }) => (
  <Flex itemsCenter={!isCurrent} marginTop="lg">
    <Icon SVG={MyOrdoIcon} width="58px" height="58px" />
    <Flex itemsStart column marginLeft="xl3">
      <Typography size="xl" weight="black" color="strawberry">
        Nothin&apos; here
        {isCurrent ? '.' : '...yet!'}
      </Typography>
      {isCurrent && (
        <>
          <Typography size="xl" weight="black" color="strawberry">
            ordo something!
          </Typography>
          <Button.Rectangular marginTop="lg" padding="md" onClick={onBackToMenu}>
            <Typography size="md">
              Back to menu
            </Typography>
          </Button.Rectangular>
        </>
      )}
    </Flex>
  </Flex>
);

EmptyOrdo.defaultProps = {
  isCurrent: false,
};

EmptyOrdo.propTypes = {
  isCurrent: bool,
  onBackToMenu: func.isRequired,
};

export default EmptyOrdo;
