import React from 'react';
import {
  func, number, bool, string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex } from '@atoms';
import { BillSection } from '@molecules';
import { CustomTipForm } from '@organisms';
import { Ordo } from '@templates';
import { ReactComponent as HeaderIcon } from '@assets/my-bill.svg';

const CustomTip = ({
  hasServerError,
  onToastClose,
  onRangeChange,
  subtotal,
  isLoading,
  onBackUp,
  onConfirmTip,
  onTipPercentChange,
  onTipInCashChange,
  tipInCash,
  tipPercent,
}) => (
  <Ordo
    pageTitle="Custom tip"
    isLoading={isLoading}
    title="Pay my bill"
    headerIconSrc={HeaderIcon}
    onBackUp={onBackUp}
    onToastClose={onToastClose}
    hasServerError={hasServerError}
  >
    <Wrapper column>
      <BillSection
        tip
        title="SUBTOTAL"
        amount={subtotal}
      />
      <CustomTipForm
        subtotal={subtotal}
        onConfirmTip={onConfirmTip}
        onTipInCashChange={onTipInCashChange}
        onRangeChange={onRangeChange}
        onTipPercentChange={onTipPercentChange}
        tipInCash={tipInCash}
        tipPercent={tipPercent}
      />
    </Wrapper>
  </Ordo>
);

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
    text-align: center;
  `}
`;

CustomTip.propTypes = {
  hasServerError: bool.isRequired,
  onToastClose: func.isRequired,
  onRangeChange: func.isRequired,
  tipInCash: string.isRequired,
  tipPercent: string.isRequired,
  onTipPercentChange: func.isRequired,
  onTipInCashChange: func.isRequired,
  isLoading: bool.isRequired,
  onBackUp: func.isRequired,
  subtotal: number.isRequired,
  onConfirmTip: func.isRequired,
};

export default CustomTip;
