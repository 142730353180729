import React, { useMemo } from 'react';
import {
  arrayOf, bool, exact, func, string, number,
} from 'prop-types';
import { Flex } from '@atoms';
import { MenuCategory } from '@molecules';
import { Menu } from '@templates';

const MenuCategories = ({
  isPlaceOrdoVisible,
  tableNumber,
  tablePin,
  seatNumber,
  hasServerError,
  onToastClose,
  onCategorySelect,
  categories,
  isLoading,
  selectedMenuItemsQuantity,
  onOrdoPlace,
  ordoClosingAt,
}) => {
  const memoizedCategories = useMemo(() => (
    categories.map(({ title, imageSrc, id }) => (
      <MenuCategory
        key={id}
        title={title}
        imageSrc={imageSrc}
        onCategorySelect={onCategorySelect(id)}
      />
    ))
  ), [categories]);

  return (
    <Menu
      isPlaceOrdoVisible={isPlaceOrdoVisible}
      tableNumber={tableNumber}
      tablePin={tablePin}
      seatNumber={seatNumber}
      isLoading={isLoading}
      hasBackToTopButton
      hasBackUpButton={false}
      selectedMenuItemsQuantity={selectedMenuItemsQuantity}
      onOrdoPlace={onOrdoPlace}
      ordoClosingAt={ordoClosingAt}
      hasServerError={hasServerError}
      onToastClose={onToastClose}
    >
      <Flex wrapFlex>
        {memoizedCategories}
      </Flex>
    </Menu>
  );
};

MenuCategories.defaultProps = {
  tableNumber: '',
  selectedMenuItemsQuantity: 0,
  onOrdoPlace: () => {},
  ordoClosingAt: '',
  tablePin: '',
  seatNumber: '',
};

MenuCategories.propTypes = {
  isPlaceOrdoVisible: bool.isRequired,
  seatNumber: string,
  tableNumber: string,
  hasServerError: bool.isRequired,
  onToastClose: func.isRequired,
  selectedMenuItemsQuantity: number,
  onOrdoPlace: func,
  ordoClosingAt: string,
  isLoading: bool.isRequired,
  onCategorySelect: func.isRequired,
  categories: arrayOf(exact({
    title: string,
    imageSrc: string,
    id: number,
  })).isRequired,
  tablePin: string,
};

export default MenuCategories;
