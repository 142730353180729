import React from 'react';
import {
  bool, func, number, string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Typography, Icon, BackUpButton,
} from '@atoms';
import {
  QuantityCounter, SpecialInstructionsModal, AddToOrdoModal,
} from '@molecules';
import { ReactComponent as Note } from '@assets/note.svg';

const ItemDetailsActionPanel = ({
  isBackUpButtonVisible,
  onCompleteOrdo,
  isAddToOrdoModalOpen,
  onAddToOrdoModalClose,
  onModalChangeState,
  isAddToOrdoDisabled,
  specialInstructions,
  onSpecialInstructionsChange,
  onSpecialInstructionsClose,
  quantity,
  onDecrement,
  onIncrement,
  onMenuItemAdd,
  isModalOpen,
  onModalAddMoreItems,
  onBackUp,
}) => (
  <Wrapper justifyEnd shrink={0} column padding="xl">
    <Flex grow={0} justifyBetween marginBottom="md">
      <BackUpButton onBackUp={onBackUp} isShown={isBackUpButtonVisible} />
      <QuantityCounter
        quantity={quantity}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
      />
      <Flex grow={0} column itemsCenter>
        <Flex
          column
          grow={0}
          selfStart
          itemsCenter
        >
          <Button.Circular
            diameter={58}
            primaryInverted
            onClick={onModalChangeState}
            marginBottom="xxs"
          >
            <Flex paddingLeft="xxs" itemsCenter justifyCenter>
              <Icon SVG={Note} width="29px" height="29px" />
            </Flex>
          </Button.Circular>
          <Button.Rectangular padding="xs" onClick={onModalChangeState}>
            <Typography size="sm" weight="bold">
              ADD NOTE
            </Typography>
          </Button.Rectangular>
        </Flex>
        <SpecialInstructionsModal
          isModalOpen={isModalOpen}
          onSpecialInstructionsClose={onSpecialInstructionsClose}
          onModalChangeState={onModalChangeState}
          specialInstructions={specialInstructions}
          onSpecialInstructionsChange={onSpecialInstructionsChange}
        />
      </Flex>
    </Flex>
    <Typography centered marginBottom="md" size="sm" color="strawberry" weight="bold">
      {isAddToOrdoDisabled
        ? 'Select options needed before you can add this to your ordo'
        : 'Items selected. Add it to your ordo!'}
    </Typography>
    <Button.Rectangular
      disabled={isAddToOrdoDisabled}
      secondary
      padding="xl"
      onClick={onMenuItemAdd}
    >
      {isBackUpButtonVisible ? 'Add to ordo' : 'Confirm'}
    </Button.Rectangular>
    <AddToOrdoModal
      onCompleteOrdo={onCompleteOrdo}
      isAddToOrdoModalOpen={isAddToOrdoModalOpen}
      onAddToOrdoModalClose={onAddToOrdoModalClose}
      onGoBack={onModalAddMoreItems}
    />
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
    background: ${color.white};
  `}
`;

ItemDetailsActionPanel.propTypes = {
  isBackUpButtonVisible: bool.isRequired,
  onCompleteOrdo: func.isRequired,
  isAddToOrdoModalOpen: bool.isRequired,
  onAddToOrdoModalClose: func.isRequired,
  isAddToOrdoDisabled: bool.isRequired,
  onModalChangeState: func.isRequired,
  specialInstructions: string.isRequired,
  onSpecialInstructionsChange: func.isRequired,
  onSpecialInstructionsClose: func.isRequired,
  quantity: number.isRequired,
  onDecrement: func.isRequired,
  onIncrement: func.isRequired,
  onMenuItemAdd: func.isRequired,
  isModalOpen: bool.isRequired,
  onBackUp: func.isRequired,
  onModalAddMoreItems: func.isRequired,
};

export default ItemDetailsActionPanel;
