import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Icon, Typography } from '@atoms';
import { ReactComponent as Logo } from '@assets/check-mark.svg';

const CheckoutSuccess = () => {
  useEffect(() => {
    document.title = 'Success';
  }, []);

  return (
    <Wrapper column justifyCenter itemsCenter>
      <Icon
        SVG={Logo}
        width="210px"
        height="210px"
        marginBottom="xl6"
      />
      <Flex grow={0}>
        <Typography centered size="lg" weight="bold" marginBottom="sm">
          Payment successful.
          <br />
          Thanks for visiting our restaurant, hope to see you again!
        </Typography>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${() => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
  `}
`;

export default CheckoutSuccess;
