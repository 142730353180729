import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button, Flex, Typography, Input, Circle,
} from '@atoms';
import { Tooltip } from '@molecules';
import { Registration } from '@templates';

const hint = 'Your table number is located on the table and should look something like this:';

const PhoneNumber = ({
  isLoading,
  isFormValid,
  hasServerError,
  isError,
  error,
  tableNumber,
  isTooltipOpened,
  onTooltipOpen,
  onTableNumberChange,
  onTableNumberSubmit,
  onRedirectToStaticMenu,
}) => (
  <Registration
    hasBackUpButton={false}
    progressValue={0}
    isLoading={isLoading}
    hasServerError={hasServerError}
  >
    <Flex column itemsCenter paddingTop="xl6">
      <Typography size="xl4" weight="black">
        Howdy! Enter your table number, please.
      </Typography>
      {isError && (
        <Flex paddingTop="md">
          <Typography color="strawberry" size="lg" weight="bold">
            {error}
          </Typography>
        </Flex>
      )}
      <Input
        marginTop="xl5"
        marginBottom="xl2"
        type="text"
        id="tableNumber"
        name="tableNumber"
        placeholder="P22"
        value={tableNumber}
        isError={isError || !isFormValid}
        onChange={onTableNumberChange}
        data-cy="tableNumber-input"
      />
      <Button.Rectangular
        primary
        gradient
        fullWidth
        padding="xl2"
        marginBottom="xl2"
        disabled={isError || !isFormValid}
        onClick={onTableNumberSubmit(tableNumber)}
        data-cy="next"
      >
        Next
      </Button.Rectangular>
      <Button.Ghost
        padding="xl2"
        marginBottom="xl2"
        data-cy="next"
        onClick={onRedirectToStaticMenu}
      >
        Table Service Menu
      </Button.Ghost>
      <Tooltip isTooltipOpened={isTooltipOpened} onTooltipOpen={onTooltipOpen}>
        <Flex itemsCenter column>
          <Typography data-cy="tooltip-hint" color="white" size="lg" weight="bold">
            {hint}
          </Typography>
          <Circle itemsCenter justifyCenter backgroundColor="white" diameter={122} marginTop="xl4">
            <Typography weight="bold" size="xl5">P10</Typography>
          </Circle>
        </Flex>
      </Tooltip>
    </Flex>
  </Registration>
);

PhoneNumber.propTypes = {
  isLoading: bool.isRequired,
  isFormValid: bool.isRequired,
  hasServerError: bool.isRequired,
  isError: bool.isRequired,
  error: string.isRequired,
  tableNumber: string.isRequired,
  isTooltipOpened: bool.isRequired,
  onTooltipOpen: func.isRequired,
  onTableNumberChange: func.isRequired,
  onTableNumberSubmit: func.isRequired,
  onRedirectToStaticMenu: func.isRequired,
};

export default PhoneNumber;
