import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { common } from '@constants';
import { prepareToPrint } from '@helpers/printing';
import { resetStoreToInitialStateAction } from '@store/registration/duck';
import { storage } from '@utils';
import * as api from './api';

// < ----- ACTIONS ----- > //
const placeOrdoRoutine = createRoutine(
  'PLACE_ORDO',
);

const closeAllBillsRoutine = createRoutine(
  'CLOSE_ALL_BILLS',
);

const addItemToOrdoByStaffRoutine = createRoutine(
  'ADD_ITEM_TO_ORDO_BY_STAFF',
);

const editItemInOrdoRoutine = createRoutine(
  'EDIT_ITEM_IN_ORDO',
);

const addItemToOrdoAction = 'ADD_ITEM_TO_ORDO';
const setMyOrdoAction = 'SET_MY_ORDO';
const removeItemFromOrdoAction = 'REMOVE_ITEM_FROM_ORDO';
const setPendingOrdosAction = 'SET_PENDING_ORDOS';
const updateArrivalTimeAction = 'UPDATE_ARRIVAL_TIME';
const moveItemToOrdoHistoryAction = 'MOVE_ITEM_TO_ORDO_HISTORY';
const setOrdoStatusAction = 'SET_ORDO_STATUS';
const voidItemFromOrdoAction = 'VOID_ITEM_FROM_ORDO';
const updateOrdosAction = 'UPDATE_ORDOS';

// < ----- ACTION CREATORS ----- > //
export const resetStoreToInitialState = () => ({
  type: resetStoreToInitialStateAction,
});

export const addItemToOrdo = item => ({
  type: addItemToOrdoAction,
  payload: {
    item,
  },
});

export const setPendingOrdos = pendingOrdos => ({
  type: setPendingOrdosAction,
  payload: {
    pendingOrdos,
  },
});

export const setMyOrdo = data => ({
  type: setMyOrdoAction,
  payload: data,
});

export const removeItemFromOrdo = ordoItemKey => ({
  type: removeItemFromOrdoAction,
  payload: {
    ordoItemKey,
  },
});

export const updateArrivalTime = updatedTime => ({
  type: updateArrivalTimeAction,
  payload: updatedTime,
});

export const moveItemToOrdoHistory = ordoHistory => ({
  type: moveItemToOrdoHistoryAction,
  payload: ordoHistory,
});

export const setOrdoStatus = ordo => ({
  type: setOrdoStatusAction,
  payload: ordo,
});

export const voidItemFromOrdo = data => ({
  type: voidItemFromOrdoAction,
  payload: data,
});

export const updateOrdos = data => ({
  type: updateOrdosAction,
  payload: data,
});

export const placeOrdo = data => async dispatch => {
  try {
    dispatch(placeOrdoRoutine.request());
    const { menuItems, printDetails } = data;
    const response = await api.placeOrdo({ menuItems });
    const { sessionID } = response.data;
    prepareToPrint({ id: sessionID, ...printDetails });
    return dispatch(placeOrdoRoutine.success(response.data));
  } catch (error) {
    return dispatch(placeOrdoRoutine.failure(error.response));
  } finally {
    dispatch(placeOrdoRoutine.fulfill());
  }
};

export const closeAllBills = () => async dispatch => {
  try {
    dispatch(closeAllBillsRoutine.request());
    await api.closeAllBills();
    return dispatch(closeAllBillsRoutine.success());
  } catch (error) {
    return dispatch(closeAllBillsRoutine.failure(error.response));
  } finally {
    dispatch(closeAllBillsRoutine.fulfill());
  }
};

export const addItemToOrdoByStaff = (data, token) => async dispatch => {
  try {
    dispatch(addItemToOrdoByStaffRoutine.request());

    await api.addItemToOrdoByStaff(data, token);

    return dispatch(addItemToOrdoByStaffRoutine.success());
  } catch (error) {
    return dispatch(addItemToOrdoByStaffRoutine.failure(error.response));
  } finally {
    dispatch(addItemToOrdoByStaffRoutine.fulfill());
  }
};

export const editItemInOrdo = (data, token) => async dispatch => {
  try {
    dispatch(editItemInOrdoRoutine.request());

    await api.editItemInOrdo(data, token);

    return dispatch(editItemInOrdoRoutine.success());
  } catch (error) {
    return dispatch(editItemInOrdoRoutine.failure(error.response));
  } finally {
    dispatch(editItemInOrdoRoutine.fulfill());
  }
};

// < ----- STATE ----- > //
export const myOrdoStorePersistWhitelist = [
  'currentOrdo', 'orderedItems', 'pendingOrdos', 'ordoHistory', 'ordo',
];

const initialState = {
  currentOrdo: [],
  orderedItems: [],
  pendingOrdos: [],
  ordoHistory: [],

  ordo: {
    ordoId: 0,
    closingAt: '',
    status: '',
  },

  placeOrdoPhase: INIT,
  placeOrdoError: null,

  closeAllBillsPhase: INIT,
  closeAllBillsError: null,

  addItemToOrdoByStaffPhase: INIT,
  addItemToOrdoByStaffError: null,

  editItemInOrdoPhase: INIT,
  editItemInOrdoError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;

    // UPDATE ORDOS
    case updateOrdosAction: {
      const userId = storage.getSessionUserId();

      return {
        ...state,
        ...(payload.sessionUserId === parseInt(userId, 10) && payload.orderedItems
        && { orderedItems: payload.orderedItems }),
        ordoHistory: payload.ordoHistory,
        pendingOrdos: payload.pendingOrdos,
      };
    }

    // ADD ITEM TO ORDO
    case addItemToOrdoAction:
      return {
        ...state,
        currentOrdo: [
          ...state.currentOrdo,
          ...payload.item,
        ],
      };

    // SET MY ORDO
    case setMyOrdoAction:
      return {
        ...state,
        orderedItems: payload.orderedItems,
        pendingOrdos: payload.pendingOrdos,
        ordoHistory: payload.ordoHistory,
        ordo: {
          status: payload.closingAt ? common.ORDO_CLOSING : '',
          closingAt: payload.closingAt ? payload.closingAt : '',
        },
      };

    // SET PENDING ORDOS
    case setPendingOrdosAction:
      return {
        ...state,
        pendingOrdos: [
          ...payload.pendingOrdos,
          ...state.pendingOrdos,
        ],
        orderedItems: [],
        ordo: {
          status: '',
          closingAt: '',
        },
      };

    // REMOVE ITEM FROM ORDO
    case removeItemFromOrdoAction:
      return {
        ...state,
        currentOrdo: state.currentOrdo.filter(item => item.ordoItemKey !== payload.ordoItemKey),
      };

    // UPDATE ARRIVAL TIME
    case updateArrivalTimeAction: {
      const ordoToUpdateIndex = state.pendingOrdos.findIndex(ordo => ordo.id === payload.ordoId);

      const updatedPendingOrdos = state.pendingOrdos.map((ordo, index) => {
        if (index === ordoToUpdateIndex) {
          const updatedOrdo = {
            ...ordo,
            deliversAt: payload.deliversAt,
          };

          return updatedOrdo;
        }

        return ordo;
      });

      return {
        ...state,
        pendingOrdos: updatedPendingOrdos,
      };
    }

    // MOVE ITEM TO ORDO HISTORY
    case moveItemToOrdoHistoryAction:
      return {
        ...state,
        ordoHistory: payload.ordoHistory,
        pendingOrdos: payload.pendingOrdos,
      };

    // VOID ITEM FROM ORDO
    case voidItemFromOrdoAction: {
      const updatedPendingOrdos = state.pendingOrdos.map(ordo => ({
        ...ordo,
        users: ordo.users.map(user => ({
          ...user,
          items: user.items.map(item => {
            if (item.id === payload.ordoToUserItemId) {
              const updatedItem = {
                ...item,
                voidReason: payload.voidReason,
              };

              return updatedItem;
            }

            return item;
          }),
        })),
      }));

      const updatedOrdoHistory = state.ordoHistory.map(ordo => ({
        ...ordo,
        users: ordo.users.map(user => ({
          ...user,
          items: user.items.map(item => {
            if (item.id === payload.ordoToUserItemId) {
              const updatedItem = {
                ...item,
                voidReason: payload.voidReason,
              };

              return updatedItem;
            }

            return item;
          }),
        })),
      }));

      return {
        ...state,
        pendingOrdos: updatedPendingOrdos,
        ordoHistory: updatedOrdoHistory,
      };
    }

    // SET ORDO STATUS
    case setOrdoStatusAction:
      return {
        ...state,
        ordo: {
          ...state.ordo,
          status: payload.newStatus,
          closingAt: payload.orderedAt,
          ordoId: payload.ordoId ? payload.ordoId : 0,
        },
      };

    // PLACE ORDO
    case placeOrdoRoutine.REQUEST:
      return {
        ...state,
        placeOrdoPhase: LOADING,
      };
    case placeOrdoRoutine.SUCCESS:
      return {
        ...state,
        placeOrdoPhase: SUCCESS,
        orderedItems: payload.menuItems,
        currentOrdo: [],
      };
    case placeOrdoRoutine.FAILURE:
      return {
        ...state,
        placeOrdoPhase: FAILURE,
        placeOrdoError: payload,
      };
    case placeOrdoRoutine.FULFILL:
      return {
        ...state,
        placeOrdoPhase: initialState.placeOrdoPhase,
        placeOrdoError: initialState.placeOrdoError,
      };

    // CLOSE ALL BILLS
    case closeAllBillsRoutine.REQUEST:
      return {
        ...state,
        closeAllBillsPhase: LOADING,
      };
    case closeAllBillsRoutine.SUCCESS:
      return {
        ...state,
        closeAllBillsPhase: SUCCESS,
      };
    case closeAllBillsRoutine.FAILURE:
      return {
        ...state,
        closeAllBillsPhase: FAILURE,
        closeAllBillsError: payload,
      };
    case closeAllBillsRoutine.FULFILL:
      return {
        ...state,
        closeAllBillsPhase: initialState.closeAllBillsPhase,
        closeAllBillsError: initialState.closeAllBillsError,
      };

    // ADD ITEM TO ORDO BY STAFF
    case addItemToOrdoByStaffRoutine.REQUEST:
      return {
        ...state,
        addItemToOrdoByStaffPhase: LOADING,
      };
    case addItemToOrdoByStaffRoutine.SUCCESS:
      return {
        ...state,
        addItemToOrdoByStaffPhase: SUCCESS,
      };
    case addItemToOrdoByStaffRoutine.FAILURE:
      return {
        ...state,
        addItemToOrdoByStaffPhase: FAILURE,
        addItemToOrdoByStaffError: payload,
      };
    case addItemToOrdoByStaffRoutine.FULFILL:
      return {
        ...state,
        addItemToOrdoByStaffPhase: initialState.addItemToOrdoByStaffPhase,
        addItemToOrdoByStaffError: initialState.addItemToOrdoByStaffError,
      };

    // EDIT ITEM IN ORDO BY STAFF
    case editItemInOrdoRoutine.REQUEST:
      return {
        ...state,
        editItemInOrdoPhase: LOADING,
      };
    case editItemInOrdoRoutine.SUCCESS:
      return {
        ...state,
        editItemInOrdoPhase: SUCCESS,
      };
    case editItemInOrdoRoutine.FAILURE:
      return {
        ...state,
        editItemInOrdoPhase: FAILURE,
        editItemInOrdoError: payload,
      };
    case editItemInOrdoRoutine.FULFILL:
      return {
        ...state,
        editItemInOrdoPhase: initialState.editItemInOrdoPhase,
        editItemInOrdoError: initialState.editItemInOrdoError,
      };

    default:
      return state;
  }
};

export default store;
