import React from 'react';
import { func, number, string } from 'prop-types';
import {
  RangeSlider, Flex, Typography, Button, TipInput,
} from '@atoms';
import { common } from '@constants';

const CustomTipForm = ({
  tipInCash, onTipInCashChange, tipPercent, onTipPercentChange, onConfirmTip, subtotal, onRangeChange,
}) => (
  <Flex padding="xl5" column itemsCenter>
    <Typography as="h1" size="xl4" weight="black">
      Leave a tip for your server?
    </Typography>
    <TipInput
      marginTop="xl5"
      type="text"
      inputMode="decimal"
      value={`$${tipInCash}`}
      onChange={onTipInCashChange}
    />
    {Boolean(subtotal) && (
      <>
        <RangeSlider
          marginTop="xl5"
          value={Number(tipPercent)}
          type="range"
          min={common.RANGE_MIN_VALUE}
          max={common.RANGE_MAX_VALUE}
          onChange={onRangeChange}
        />
        <TipInput
          marginTop="xl5"
          type="text"
          inputMode="decimal"
          value={`${tipPercent}%`}
          percent
          onChange={onTipPercentChange}
        />
      </>
    )}
    <Button.Rectangular
      onClick={onConfirmTip}
      secondary
      fullWidth
      padding="xl"
      marginTop="xl6"
      marginBottom="xl6"
    >
      Confirm tip
    </Button.Rectangular>
  </Flex>
);

CustomTipForm.propTypes = {
  onRangeChange: func.isRequired,
  subtotal: number.isRequired,
  tipInCash: string.isRequired,
  onTipInCashChange: func.isRequired,
  tipPercent: string.isRequired,
  onTipPercentChange: func.isRequired,
  onConfirmTip: func.isRequired,
};

export default CustomTipForm;
