import React, { useEffect } from 'react';
import { bool, node, string } from 'prop-types';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { routes } from '@constants';
import { storage } from '@utils';
import { useSelector } from 'react-redux';

const UnauthorizedRoute = ({ exact, path, children }) => {
  const history = useHistory();
  const token = storage.getToken();
  const location = useLocation();

  const { user } = useSelector(store => store.registrationStore);

  useEffect(() => {
    if (token
      && (!location.search.includes(user.oneTimeToken) || !user.oneTimeToken)
      && !location.pathname === `/${routes.staticMenu}`) {
      history.push(routes.menu);
    }
  }, []);

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

UnauthorizedRoute.defaultProps = {
  exact: false,
};

UnauthorizedRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  children: node.isRequired,
};

export default UnauthorizedRoute;
