import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BillReview as View } from '@views';
import { routes, common } from '@constants';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { loadCurrentUserBill } from '@store/myBill/duck';
import { setSessionStatus } from '@store/registration/duck';
import { getUserItems } from '@helpers/myBill';
import { withSocket } from '@hocs';

const BillReview = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(store => store.registrationStore.user);
  const { userBill, loadCurrentUserBillPhase } = useSelector(store => store.myBillStore);
  const {
    items, optionValues, sizes, loadMenuPhase,
  } = useSelector(store => store.menuStore);

  const [hasServerError, setHasServerError] = useState(false);

  useEffect(() => {
    if (userBill.subtotal === null) {
      dispatch(loadCurrentUserBill());
    }
    if (user.isLeavingEarlier) {
      dispatch(setSessionStatus({ sessionStatus: common.CLOSING }));
    }
  }, []);

  useEffect(() => {
    if (loadCurrentUserBillPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [loadCurrentUserBillPhase]);

  const handleConfirm = () => (userBill.subtotal ? history.push(routes.tipForServer) : history.push(routes.customTip));

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const memoizedUserItems = useMemo(() => getUserItems(userBill.items, items, optionValues, sizes),
    [userBill.items, items, optionValues, sizes]);

  const bill = useMemo(() => ({
    subtotal: userBill.subtotal ? userBill.subtotal : 0,
    items: memoizedUserItems,
  }), [userBill, memoizedUserItems]);

  return (
    <View
      hasServerError={hasServerError}
      onToastClose={handleToastClose}
      isLoading={loadCurrentUserBillPhase === LOADING || loadMenuPhase === LOADING}
      onConfirm={handleConfirm}
      bill={bill}
      hasBackUpButton={false}
    />
  );
};

export default withSocket(BillReview);
