import { apiEndpoints } from '@constants';
import { client } from '@utils';

const loadBill = () => (
  client.authorized.get(apiEndpoints.myBill)
);

const moveItem = data => (
  client.authorized.put(apiEndpoints.myBill, data)
);

const payForTheWholeTable = () => (
  client.authorized.post(apiEndpoints.myBill)
);

const closeAllBills = () => (
  client.authorized.post(apiEndpoints.closeAllBillsMyBill)
);

const loadCurrentUserBill = () => (
  client.authorized.get(apiEndpoints.payMyBill)
);

const confirmTip = data => (
  client.authorized.post(apiEndpoints.payMyBill, data)
);

export {
  loadBill,
  moveItem,
  closeAllBills,
  loadCurrentUserBill,
  confirmTip,
  payForTheWholeTable,
};
