export const getItemPrice = (sizes, sizeSelected, itemOptions, optionValues) => (sizes[sizeSelected].specialPrice
|| sizes[sizeSelected].specialPrice === 0 ? sizes[sizeSelected].specialPrice : sizes[sizeSelected].price
  + itemOptions.reduce((totalPrice, option) => totalPrice
  + option.values.reduce((totalOptionPrice, value) => totalOptionPrice
  + optionValues[value.id].price * value.quantity, 0),
  0));

export const getOptionValues = (optionValues, itemOptions, options) => itemOptions.reduce((values, option) => {
  option.values.forEach(value => {
    values.push({
      price: optionValues[value.id].price,
      name: optionValues[value.id].name,
      quantity: value.quantity,
      isRequired: options[option.id].requiredValue,
    });
  });
  return values;
}, [])
  .sort((firstValue, secondValue) => firstValue.price - secondValue.price);

const getTimeOfOrdo = data => {
  const minutes = new Date(data).getMinutes();
  const minuteOfOrdo = (minutes / 10 < 1) ? `0${minutes}` : minutes;
  const hourOfOrdo = new Date(data).getHours();
  return `${hourOfOrdo}:${minuteOfOrdo}`;
};

// Creating an array of items from CurrentOrdo
export const getCurrentOrdo = (currentOrdo, items, optionValues, sizes, options) => currentOrdo.map(item => ({
  name: items[item.id].name,
  id: item.id,
  size: sizes[item.size.id].name,
  ordoItemKey: item.ordoItemKey,
  price: item.price % 1
    ? item.price.toFixed(2)
    : item.price,
  specialInstructions: item.specialInstructions,
  optionValues: getOptionValues(optionValues, item.size.options, options),
}));

export const getOrderedItems = (orderedItems, sizes, items, optionValues, options) => orderedItems.map(item => ({
  menuItemId: item.menuItemId,
  id: item.id,
  name: items[item.menuItemId].name,
  price: getItemPrice(sizes, item.size.id, item.size.options, optionValues),
  size: sizes[item.size.id].name,
  specialInstructions: item.specialInstructions,
  optionValues: getOptionValues(optionValues, item.size.options, options),
}));

export const getClosedOrdos = (pendingOrdos, sizes, items, optionValues, options) => pendingOrdos.flatMap(ordo => (
  ordo.users.reduce((userOrdo, user) => {
    if (user.items.length) {
      userOrdo.push({
        deliversAt: ordo.deliversAt ? ordo.deliversAt : null,
        orderedAt: getTimeOfOrdo(ordo.orderedAt),
        ordoUserFirstName: user.userName,
        ordoUserId: user.id,
        ordoItems: user.items.map(item => ({
          name: items[item.menuItemId].name,
          isVoided: !!item.voidReason,
          price: getItemPrice(sizes, item.size.id, item.size.options, optionValues),
          specialInstructions: item.specialInstructions,
          size: sizes[item.size.id].name,
          optionValues: getOptionValues(optionValues, item.size.options, options),
        })),
      });
    }
    return userOrdo;
  }, [])
));

export const formatPrice = price => (
  (price / 100) % 1
    ? (price / 100).toFixed(2)
    : price / 100
);

const formatTime = (minutes, seconds) => {
  switch (true) {
    case seconds === 60:
      return {
        minutes: minutes > 0 ? minutes + 1 : minutes - 1,
        seconds: '00',
      };
    case seconds < 10:
      return {
        minutes,
        seconds: '0'.concat(seconds),
      };
    default:
      return { minutes, seconds };
  }
};

export const calculateTimeLeft = dateTo => {
  // UPD: substraction increased to 2000ms because of NaN:NaN time bug hotfig
  const now = new Date().getTime() - 2000; // We substract here 1000ms to sync stopwatch and timer
  const difference = (new Date(dateTo) - now) / 1000;

  const minutes = Math.floor(difference / 60);
  const seconds = Math.abs(Math.floor(difference % 60));

  return formatTime(minutes, seconds);
};
