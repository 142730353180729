import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // need to use setTimeout here because scroll function is being called
    // before huge component like Menu is mounted and scroll doesn't work
    setTimeout(() => window.scrollTo(0, 0), 50);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
