import React from 'react';
import { func, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { Typography, ImageBackground } from '@atoms';

const MenuCategory = ({
  imageSrc,
  title,
  onCategorySelect,
}) => (
  <Wrapper
    padding="md"
    justifyStart
    itemsEnd
    imageSrc={imageSrc}
    onClick={onCategorySelect}
  >
    <StyledTypography size="xl3" weight="black" color="white">
      {title}
    </StyledTypography>
  </Wrapper>
);

const Wrapper = styled(ImageBackground)`
  ${({ theme: { color, sizing } }) => css`
    width: 50%;
    max-width: 50%;
    height: ${sizing.tile.height};
    border: 1px solid ${color.warmGrey};
  `}
`;

const StyledTypography = styled(Typography)`
  ${({ theme: { color } }) => css`
    text-shadow: 1px 1px ${color.black};
  `}
`;

MenuCategory.defaultProps = {
  imageSrc: '',
};

MenuCategory.propTypes = {
  imageSrc: string,
  title: string.isRequired,
  onCategorySelect: func.isRequired,
};

export default MenuCategory;
