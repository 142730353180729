import { resetStoreToInitialStateAction } from '@store/registration/duck';

// < ----- ACTIONS ----- > //
export const CONNECT_SOCKET = 'CONNECT_SOCKET';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';

export const SET_TOKEN_ERROR = 'SET_TOKEN_ERROR';

export const CONNECT_PRE_AUTH_SOCKET = 'CONNECT_PRE_AUTH_SOCKET';
export const SOCKET_PRE_AUTH_CONNECTED = 'SOCKET_PRE_AUTH_CONNECTED';
export const SOCKET_PRE_AUTH_DISCONNECTED = 'SOCKET_PRE_AUTH_DISCONNECTED';

// < ----- ACTION CREATORS ----- > //
export const connectSocket = () => ({
  type: CONNECT_SOCKET,
});

export const connectSocketForPreAuth = () => ({
  type: CONNECT_PRE_AUTH_SOCKET,
});

export const socketConnected = () => ({
  type: SOCKET_CONNECTED,
});

export const socketPreAuthConnected = () => ({
  type: SOCKET_PRE_AUTH_CONNECTED,
});

export const disconnectSocket = () => ({
  type: DISCONNECT_SOCKET,
});

export const socketDisconnected = () => ({
  type: SOCKET_DISCONNECTED,
});

export const setTokenError = error => ({
  type: SET_TOKEN_ERROR,
  payload: {
    error,
  },
});

// < ----- STATE ----- > //
export const socketStorePersistWhitelist = [];
export const socketStorePersistBlacklist = [];

const initialState = {
  isConnected: false,
  isPreAuthConnected: false,
  tokenError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;

    // SOCKET SUCCESSFULLY CONNECTED
    case SOCKET_CONNECTED:
      return {
        ...state,
        isConnected: true,
        tokenError: null,
      };

    // SOCKET SUCCESSFULLY CONNECTED FOR PRE-AUTH
    case SOCKET_PRE_AUTH_CONNECTED:
      return {
        ...state,
        isPreAuthConnected: true,
        tokenError: null,
      };

    // SOCKET SUCCESSFULLY DISCONNECTED
    case SOCKET_DISCONNECTED:
      return {
        ...state,
        isConnected: false,
      };

    // SOCKET SUCCESSFULLY DISCONNECTED AFTER PRE-AUTH
    case SOCKET_PRE_AUTH_DISCONNECTED:
      return {
        ...state,
        isPreAuthConnected: false,
      };

    // RECEIVE TOKEN ERROR WHILE SOCKET CONNECTION
    case SET_TOKEN_ERROR:
      return {
        ...state,
        isConnected: false,
        tokenError: payload.error,
      };

    default:
      return state;
  }
};

export default store;
