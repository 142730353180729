import React, { useEffect } from 'react';
import {
  bool, node, string, elementType, func,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Flex, Icon, Loader, Typography,
} from '@atoms';
import { ErrorToast } from '@molecules';
import { Footer } from '@organisms';

const Ordo = ({
  isPreauthSeen,
  pageTitle,
  hasServerError,
  onToastClose,
  title,
  headerIconSrc,
  isLoading,
  onBackUp,
  footerChild,
  hasBackUpButton,
  children,
}) => {
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      <Wrapper column>
        <Flex grow={0} itemsCenter justifyBetween padding="xl5">
          <Typography weight="black" color="strawberry" size="xl5">
            {title}
          </Typography>
          <Flex itemsCenter grow={0}>
            <PreauthFunds isPreauthSeen={isPreauthSeen} marginRight="lg" weight="black" size="xl">$100</PreauthFunds>
            <Icon SVG={headerIconSrc} width="42px" height="42px" />
          </Flex>
        </Flex>
        <Flex grow={0} column>
          {children}
        </Flex>
        <Footer hasBackUpButton={hasBackUpButton} onBackUp={onBackUp}>
          {footerChild}
        </Footer>
      </Wrapper>
      {isLoading && <Loader />}
      {hasServerError && <ErrorToast onToastClose={onToastClose} />}
    </>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { sizing } }) => css`
    position: relative;
    min-height: calc(100% - ${sizing.footer.height});
    margin-bottom: ${sizing.footer.height};
  `}
`;

const PreauthFunds = styled(Typography)`
  ${({ isPreauthSeen }) => css`
    opacity: ${isPreauthSeen ? '100%' : '0'}
  `}
`;

Ordo.defaultProps = {
  hasServerError: false,
  onToastClose: () => {},
  onBackUp: () => {},
  isLoading: false,
  footerChild: null,
  hasBackUpButton: true,
  isPreauthSeen: false,
};

Ordo.propTypes = {
  isPreauthSeen: bool,
  pageTitle: string.isRequired,
  onToastClose: func,
  hasServerError: bool,
  hasBackUpButton: bool,
  title: string.isRequired,
  headerIconSrc: elementType.isRequired,
  isLoading: bool,
  onBackUp: func,
  children: node.isRequired,
  footerChild: node,
};

export default Ordo;
