import React, { useMemo } from 'react';
import {
  arrayOf, exact, func, string, number, bool,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Flex, Typography } from '@atoms';
import { OrdoItem, EmptyOrdo, PendingOrdoTimer } from '@molecules';

const PreparingOrdos = ({
  orderedItems, pendingOrdos, onBackToMenu, userId, onClosingOrdoItemEdit,
}) => {
  const orderedItemsText = `Your ordo is being processed, we will soon estimate the arriving time.
  You cannot start a new ordo until this one has been sent to the kitchen/bar (about 2 minutes)`;

  const memoizedOrderedItems = useMemo(() => orderedItems.map(({
    id, name, price, optionValues, size, isRequired, specialInstructions,
  }) => (
    <OrdoItem
      id={id}
      onItemClick={onClosingOrdoItemEdit}
      specialInstructions={specialInstructions}
      isRequired={isRequired}
      size={size}
      key={uuidv4()}
      name={name}
      price={price}
      optionValues={optionValues}
    />
  )), [orderedItems]);

  const memoizedPendingOrdos = useMemo(() => pendingOrdos.map(({
    deliversAt, orderedAt, ordoUserFirstName, ordoUserId, ordoItems,
  }, index) => (
    <Flex key={uuidv4()} column marginBottom="xl2">
      { deliversAt && <PendingOrdoTimer deliversAt={deliversAt} />}
      <Typography data-cy="pending-ordo-data" size="md" weight="bold" marginBottom="sm">
        {`At ${orderedAt}, ${ordoUserId === userId ? 'You' : ordoUserFirstName} ordo'd:`}
      </Typography>
      {ordoItems.map(({
        name, price, optionValues, size, isRequired, specialInstructions, isVoided,
      }) => (
        <OrdoItem
          isPayedUp={isVoided}
          specialInstructions={specialInstructions}
          isRequired={isRequired}
          size={size}
          key={uuidv4()}
          name={name}
          price={price}
          optionValues={optionValues}
        />
      ))}
      {index !== pendingOrdos.length - 1 && <Divider />}
    </Flex>
  )), [pendingOrdos]);

  return (
    <Flex data-cy="preparing-ordos" column padding="xl5" paddingBottom="xl2">
      <Typography weight="black" size="xl" marginBottom="sm">
        ordo&apos;d items:
      </Typography>
      {Boolean(orderedItems.length) && (
        <Flex column marginBottom="xl2">
          <Typography data-cy="ordered-items-text" color="warmGrey" size="md" weight="bold" marginBottom="xl2">
            {orderedItemsText}
          </Typography>
          {memoizedOrderedItems}
        </Flex>
      )}
      {Boolean(pendingOrdos.length) && memoizedPendingOrdos}
      {(!orderedItems.length && !pendingOrdos.length) && <EmptyOrdo onBackToMenu={onBackToMenu} />}
    </Flex>
  );
};

const Divider = styled.div`
  ${({ theme: { color, spacing } }) => css`
  width: calc(100% - 24px);
  margin: ${spacing.none} ${spacing.md};
  height: 1px;
  background-color: ${color.dust}`}
`;

PreparingOrdos.propTypes = {
  onClosingOrdoItemEdit: func.isRequired,
  userId: number.isRequired,
  onBackToMenu: func.isRequired,
  pendingOrdos: arrayOf(exact({
    deliversAt: string,
    orderedAt: string,
    ordoUserFirstName: string,
    ordoUserId: number,
    ordoItems: arrayOf(exact({
      name: string,
      price: number,
      size: string,
      specialInstructions: string,
      isVoided: bool,
      optionValues: arrayOf(exact({
        name: string,
        price: number,
        quantity: number,
        isRequired: bool,
      })),
    })),
  })).isRequired,
  orderedItems: arrayOf(exact({
    id: number,
    menuItemId: number,
    name: string,
    price: number,
    size: string,
    specialInstructions: string,
    optionValues: arrayOf(exact({
      name: string,
      price: number,
      quantity: number,
      isRequired: bool,
    })),
  })).isRequired,
};

export default PreparingOrdos;
