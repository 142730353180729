import React, { useMemo } from 'react';
import {
  arrayOf, exact, number, string, func, bool,
} from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Typography, Icon,
} from '@atoms';
import { ReactComponent as Remove } from '@assets/remove-item.svg';
import { formatPrice } from '@helpers/myOrdo';

const OrdoItem = ({
  id,
  onItemClick,
  specialInstructions,
  canBeDeleted,
  isPayedUp,
  ordoItemKey,
  name,
  price,
  optionValues,
  size,
  onItemRemove,
}) => {
  const getPrice = (valuePrice, quantity) => {
    if (quantity > 1) {
      return `$${formatPrice(valuePrice)} each`;
    }
    return `$${formatPrice(valuePrice)}`;
  };

  const memoizedOptionValues = useMemo(() => (
    optionValues.map(value => (
      <Typography key={uuidv4()} as="span" color={value.isRequired ? 'black' : 'grannySmith'}>
        &nbsp;
        {value.price
          ? `+ ${value.name} ${value.quantity > 1 ? ` x${value.quantity}` : ''}
          (${getPrice(value.price, value.quantity)})`
          : `• ${value.name} ${value.quantity > 1 ? ` x${value.quantity}` : ''}`}
      </Typography>
    ))
  ), [optionValues]);

  return (
    <Container
      onClick={onItemClick(id)}
      isPayedUp={isPayedUp}
      wrapFlex
      justifyCenter
      padding="md"
      data-cy="ordo-item"
    >
      {canBeDeleted && (
        <Button.Circular
          primary
          diameter={18}
          onClick={onItemRemove(ordoItemKey)}
          data-cy="ordo-item-remove"
        >
          <Flex itemsCenter justifyCenter>
            <Icon SVG={Remove} width="10.5px" height="10.5px" />
          </Flex>
        </Button.Circular>
      )}
      <Typography color="strawberry">
        {name}
      </Typography>
      <Typography>
        &nbsp;
        {`• ${size}`}
      </Typography>
      {Boolean(optionValues.length) && memoizedOptionValues}
      {specialInstructions && (
        <Typography color="warmGrey">
        &nbsp;
          {`• ${specialInstructions}`}
        </Typography>
      )}
      <Typography color="strawberry">
          &nbsp;
        {`$${formatPrice(price)}`}
      </Typography>
    </Container>
  );
};

const Container = styled(Flex)`
  ${({
    isPayedUp,
    theme: {
      color, border, spacing, typography, boxShadow,
    },
  }) => css`
    position: relative;
    user-select: none;
    width: 100%;
    background-color: ${isPayedUp ? color.dust : color.white};
    border-radius: ${border.radius.ordoItem};
    margin-bottom: ${spacing.md};
    box-shadow: ${isPayedUp ? 'none' : boxShadow.default};

    ${Button.Circular} {
      position: absolute;
      top: -5px;
      left: -5px;
    }

    ${Typography} {
      font-size: ${typography.size.md};
      font-weight: ${typography.weight.bold};

      ${isPayedUp && `
        color: ${color.warmGrey};
      `}
    }
  `}
`;

OrdoItem.defaultProps = {
  canBeDeleted: false,
  isPayedUp: false,
  onItemRemove: () => {},
  ordoItemKey: '',
  specialInstructions: '',
  onItemClick: () => {},
  id: null,
};

OrdoItem.propTypes = {
  id: number,
  onItemClick: func,
  size: string.isRequired,
  canBeDeleted: bool,
  isPayedUp: bool,
  ordoItemKey: string,
  name: string.isRequired,
  specialInstructions: string,
  price: number.isRequired,
  optionValues: arrayOf(exact({
    name: string,
    price: number,
    quantity: number,
    isRequired: bool,
  })).isRequired,
  onItemRemove: func,
};

export default OrdoItem;
