import React from 'react';
import { node, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex, Typography } from '@atoms';

const LabeledShadowFlex = ({ label, children }) => (
  <Flex column selfStretch marginBottom="xl5">
    <Typography size="xl" weight="regular" marginBottom="sm">
      {label}
    </Typography>
    <ShadowWrapper column>
      {children}
    </ShadowWrapper>
  </Flex>
);

LabeledShadowFlex.propTypes = {
  label: string.isRequired,
  children: node.isRequired,
};

const ShadowWrapper = styled(Flex)`
  ${({ theme: { border, boxShadow } }) => css`
    border: 1px solid #d9d9d9;
    border-radius: ${border.radius.stripeElement};
    box-shadow: ${boxShadow.stripeElement};
  `}
`;

export default LabeledShadowFlex;
