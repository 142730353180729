import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StaticMenuItemDetails as View } from '@views';
import { routes } from '@constants';
import {
  getMenuCategories,
  getMenuSubcategories,
  getMenuOptions,
  getMenuSizes,
  getItemPrices,
  getOptionsWithValuesSelected,
  incrementOptionValueQuantity,
  decrementOptionValueQuantity,
} from '@helpers/menuItemDetails';
import { useViewport } from '@hooks';
import { setSelectedCategory } from '@store/menu/duck';

const StaticMenuItemDetails = () => {
  const {
    categories, subcategories, options, optionValues, items, sizes, selectedCategoryId,
  } = useSelector(state => state.menuStore);

  const screenSize = useViewport();
  const history = useHistory();
  const dispatch = useDispatch();
  const { itemId } = useParams();

  const [isDrawerOpened, setDrawerOpen] = useState(true);
  const [sizeSelected, setSizeSelected] = useState(
    items[itemId].sizeIds.find(sizeId => sizes[sizeId].specialPrice)
  || items[itemId].sizeIds.find(sizeId => sizes[sizeId].preSelected)
  || items[itemId].sizeIds[0],
  );
  const [isAddToOrdoModalOpen, setAddToOrdoModalOpen] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionsList, setOptionsList] = useState(null);

  const [itemPrice, itemSpecialPrice] = optionsList ? getItemPrices(sizes, sizeSelected, optionsList) : [0, 0];

  useEffect(() => {
    const selectedSizeOptions = getMenuOptions(options, sizes, sizeSelected, optionValues, []);
    setOptionsList(selectedSizeOptions);
  }, [sizeSelected]);

  const handleSizeSelect = sizeId => () => {
    setSizeSelected(sizeId);
  };

  const handleOptionValueSelect = (optionId, valueId) => () => {
    setOptionsList(prevOptions => getOptionsWithValuesSelected(prevOptions, optionId, valueId));
  };

  const handleModalChangeState = () => {
    setIsModalOpen(modalState => !modalState);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerChangeState = () => {
    setDrawerOpen(prevState => !prevState);
  };

  const handleCategorySelect = categoryId => () => {
    dispatch(setSelectedCategory({
      categoryId,
    }));

    history.push(routes.staticMenuItems);
  };

  const handleSubcategorySelect = subcategoryId => () => {
    const categoryIds = Object.keys(categories.byId);

    // Searching for category id of selected subcategory
    const categoryId = categoryIds.filter(id => (
      categories.byId[id].subcategoryIds.includes(subcategoryId)));

    dispatch(setSelectedCategory({
      categoryId,
    }));

    history.push(routes.staticMenuItems);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleOptionQuantityDecrement = (valueId, optionId) => () => {
    setOptionsList(prevOptions => decrementOptionValueQuantity(prevOptions, optionId, valueId));
  };

  const handleOptionQuantityIncrement = (valueId, optionId) => () => {
    setOptionsList(prevOptions => incrementOptionValueQuantity(prevOptions, optionId, valueId));
  };

  const handleAddToOrdoModalClose = () => {
    setAddToOrdoModalOpen(false);
    history.push(routes.staticMenu);
  };

  const handleBackUp = () => {
    history.push(routes.staticMenuItems);
  };

  const handleSpecialIntructionsChange = e => {
    setSpecialInstructions(e.target.value);
  };

  const handleSpecialIntructionsClose = () => {
    setIsModalOpen(false);
    setSpecialInstructions('');
  };

  const handleGoBack = () => {
    history.push(routes.staticMenu);
  };

  const memoizedCategories = useMemo(() => (
    getMenuCategories(categories).filter(category => category.title !== 'Assistance')
  ), [selectedCategoryId]);

  const memoizedSubcategories = useMemo(() => (
    getMenuSubcategories(subcategories, categories, selectedCategoryId)
  ), [selectedCategoryId]);

  const memoizedSize = useMemo(() => (
    getMenuSizes(items, itemId, sizes, sizeSelected)
  ), [sizeSelected]);

  // Disable 'Add to ordo button' if item's options are not loaded yet
  const isAddToOrdoDisabled = !optionsList || !!(optionsList.find(option => (
    option.requiredValue && !option.valuesSelected.length
  )));

  return (
    <View
      isBackUpButtonVisible
      isPlaceOrdoVisible
      onDrawerChangeState={handleDrawerChangeState}
      onOptionQuantityIncrement={handleOptionQuantityIncrement}
      onOptionQuantityDecrement={handleOptionQuantityDecrement}
      onAddToOrdoModalClose={handleAddToOrdoModalClose}
      isAddToOrdoModalOpen={isAddToOrdoModalOpen}
      onGoBack={handleGoBack}
      onDrawerClose={handleDrawerClose}
      screenHeight={screenSize.height}
      categories={memoizedCategories}
      subcategories={memoizedSubcategories}
      selectedCategoryId={selectedCategoryId}
      isAddToOrdoDisabled={isAddToOrdoDisabled}
      // selectedSubcategory
      isModalOpen={isModalOpen}
      size={memoizedSize}
      onSpecialInstructionsClose={handleSpecialIntructionsClose}
      onModalChangeState={handleModalChangeState}
      onSizeSelect={handleSizeSelect}
      onOptionValueSelect={handleOptionValueSelect}
      onCategorySelect={handleCategorySelect}
      onSpecialInstructionsChange={handleSpecialIntructionsChange}
      specialInstructions={specialInstructions}
      onSubcategorySelect={handleSubcategorySelect}
      title={items[itemId].name}
      imageSrc={items[itemId].image[0]?.src}
      price={itemPrice}
      specialPrice={itemSpecialPrice}
      description={items[itemId].description}
      isDrawerOpened={isDrawerOpened}
      optionsList={optionsList || []}
      quantity={1}
      onDrawerOpen={handleDrawerOpen}
      onBackUp={handleBackUp}
    />
  );
};

export default StaticMenuItemDetails;
