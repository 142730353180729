import React from 'react';
import {
  func, bool, exact, string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  CardElement, PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import {
  Button, Flex, Icon, Typography, LabeledShadowFlex,
  Loader,
} from '@atoms';
import { theme } from '@styles';
import { getRestaurantRelatedData } from '@helpers/registration';

const ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontFamily: theme.typography.family,
      fontSize: theme.typography.size.xl,
      fontWeight: theme.typography.weight.regular, // TODO: investigate why weight looks bigger

      '::placeholder': {
        color: theme.color.warmGrey,
      },
    },
  },
};

const Checkout = ({
  onPay, isLoading, error, onCardChange, isConfirmDisabled, paymentRequest,
}) => {
  const { logo } = getRestaurantRelatedData();

  return (
    <>
      {isLoading && <Loader />}
      <Wrapper column itemsCenter padding="xl5">
        <Icon
          SVG={logo}
          width="210px"
          height="30px"
          marginTop="xl6"
          marginBottom="xl3"
        />
        <ErrorTypography error={error} size="xl" weight="black" marginBottom="xl3" color="strawberry">
          {error}
        </ErrorTypography>
        <Typography as="h1" size="lg" weight="bold" marginBottom="sm">
          Madison&apos;s 1212
        </Typography>
        <Typography as="span" size="xl" weight="regular" marginBottom="xl">
          $100
        </Typography>
        {!!paymentRequest && (
          <>
            <PaymentRequestButtonElement options={{ paymentRequest }} />
            <Typography margin="xl2">Or pay with card</Typography>
          </>
        )}
        <LabeledShadowFlex label="Card Information">
          <CardElement onChange={onCardChange} options={ELEMENT_OPTIONS} />
        </LabeledShadowFlex>
        <Button.Rectangular
          disabled={isConfirmDisabled}
          secondary
          fullWidth
          padding="xl"
          onClick={onPay}
        >
          Pre-authorize
        </Button.Rectangular>
      </Wrapper>
    </>
  );
};

const ErrorTypography = styled(Typography)`
${({ error }) => css`
    opacity: ${error ? '100%' : '0'};
  `}
`;

const Wrapper = styled(Flex)`
  ${({ theme: { color, spacing } }) => css`
    .StripeElement {
      width: 100%;
      padding: ${spacing.sm};
    }

    .bottomBordered {
      border-bottom: 1px solid ${color.dust};
      border-radius: 0px;
    }

    .rightBordered {
      border-right: 1px solid ${color.dust};
    }
  `}
`;

Checkout.defaultProps = {
  paymentRequest: null,
};

Checkout.propTypes = {
  paymentRequest: exact({
    applePay: bool,
    googlePay: bool,
  }),
  isConfirmDisabled: bool.isRequired,
  onCardChange: func.isRequired,
  error: string.isRequired,
  isLoading: bool.isRequired,
  onPay: func.isRequired,
};

export default Checkout;
