import React from 'react';
import { func, string } from 'prop-types';
import {
  Button, Flex, Typography, Icon,
} from '@atoms';
import { Registration } from '@templates';
import { ReactComponent as Crown } from '@assets/crown.svg';

const HeadOfTheTableWelcome = ({ onProceed, tableNumber, seatNumber }) => (
  <Registration tableNumber={tableNumber} seatNumber={seatNumber} progressValue={80} hasBackUpButton={false}>
    <Flex column itemsCenter paddingTop="xl4">
      <Typography size="xl4" weight="black" paddingBottom="xl4">
        Looks like you&apos;re the first one here! That makes you the
        {' '}
        <Icon SVG={Crown} width="20px" height="20px" />
        {' '}
        <Typography as="span" color="strawberry" size="xl4" weight="black">
          Head of the Table
        </Typography>
      </Typography>
      <Typography size="xl" weight="bold" marginBottom="sm">
        That means you’re in charge of letting the other guests at your table in and splitting the bill if necessary.
      </Typography>
      <Typography
        color="warmGrey"
        size="xl"
        weight="bold"
        marginBottom="xl4"
      >
        *If the people who dine with you don’t pay, or you order
        for everyone, you are ultimately responsible for the bill
      </Typography>
      <Button.Rectangular
        primary
        gradient
        fullWidth
        padding="xl2"
        onClick={onProceed}
        data-cy="next"
      >
        Next
      </Button.Rectangular>
    </Flex>
  </Registration>
);

HeadOfTheTableWelcome.propTypes = {
  tableNumber: string.isRequired,
  seatNumber: string.isRequired,
  onProceed: func.isRequired,
};

export default HeadOfTheTableWelcome;
