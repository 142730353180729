import styled, { css } from 'styled-components';
import { Flex } from '@atoms';
import { marginMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const Circle = styled(Flex)`
  ${({
    diameter,
    backgroundColor,
    theme: {
      border,
      color,
      boxShadow,
    },
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    width: ${diameter}px;
    height: ${diameter}px;

    box-shadow: ${boxShadow.default};
    background-color: ${color[backgroundColor]};

    border: none;
    border-radius: ${border.radius.circular};
    padding: 0px;
  `}
`;

export default Circle;
