import { common } from '@constants';

const setToken = token => localStorage.setItem(common.TOKEN, token);

const getToken = () => localStorage.getItem(common.TOKEN);

const removeToken = () => localStorage.removeItem(common.TOKEN);

const clear = () => localStorage.clear();

const setTableNumber = tableNumber => localStorage.setItem(common.TABLE_NUMBER, tableNumber);

const getTableNumber = () => localStorage.getItem(common.TABLE_NUMBER);

const setSessionUserId = userId => localStorage.setItem(common.SESSION_USER_ID, userId);

const getSessionUserId = () => localStorage.getItem(common.SESSION_USER_ID);

const setUserId = userId => localStorage.setItem(common.USER_ID, userId);

const getUserId = () => localStorage.getItem(common.USER_ID);

export default {
  setToken,
  getToken,
  removeToken,
  clear,
  setTableNumber,
  getTableNumber,
  setSessionUserId,
  getSessionUserId,
  setUserId,
  getUserId,
};
