import React from 'react';
import { func, number } from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Icon, Typography, Circle,
} from '@atoms';
import { ReactComponent as Minus } from '@assets/minus.svg';
import { ReactComponent as Plus } from '@assets/plus.svg';

const QuantityCounter = ({ quantity, onDecrement, onIncrement }) => (
  <Wrapper quantity={quantity} selfEnd grow={0} column itemsCenter>
    <Flex grow={0} itemsCenter>
      <Button.Circular primary diameter={30} onClick={onDecrement}>
        <Flex itemsCenter justifyCenter>
          <Icon SVG={Minus} width="8.7px" height="2.2px" className="strawberry" />
        </Flex>
      </Button.Circular>
      <QuantityWrapper
        itemsCenter
        justifyCenter
        marginLeft="xs"
        marginRight="xs"
        backgroundColor="white"
        diameter={34}
      >
        <Typography size="xl" weight="bold">
          {quantity}
        </Typography>
      </QuantityWrapper>
      <Button.Circular secondary diameter={30} onClick={onIncrement}>
        <Flex itemsCenter justifyCenter>
          <Icon SVG={Plus} width="10px" height="10px" className="grannySmith" />
        </Flex>
      </Button.Circular>
    </Flex>
    <StyledTypography marginTop="xl" size="sm" weight="bold" padding="xs">
      QUANTITY
    </StyledTypography>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ quantity }) => css`
    visibility: ${quantity ? 'visible' : 'hidden'};
  `}
`;

const QuantityWrapper = styled(Circle)`
  ${({ theme: { color } }) => css`
    box-shadow: 0 3px 6px 0 ${color.warmGrey};
  `}
`;

const StyledTypography = styled(Typography)`
  ${({ theme: { boxShadow, border, color } }) => css`
    box-shadow: ${boxShadow.default};
    border-radius: ${border.radius.default};
    background-color: ${color.white};
  `}
`;

QuantityCounter.defaultProps = {
  quantity: 0,
  onDecrement: () => {},
  onIncrement: () => {},
};

QuantityCounter.propTypes = {
  quantity: number,
  onDecrement: func,
  onIncrement: func,
};

export default QuantityCounter;
