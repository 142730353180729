import React, { useCallback, useMemo } from 'react';
import {
  arrayOf, bool, exact, func, number, oneOf, oneOfType, string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Flex, Typography, ImageBackground, Circle,
} from '@atoms';
import { Drawer, MenuHeader } from '@molecules';
import { ItemDetailsActionPanel, MenuItemOptions, MenuItemSizes } from '@organisms';
import { Menu } from '@templates';
import { formatPrice } from '@helpers/myOrdo';

const MenuItemDetails = ({
  isBackUpButtonVisible,
  isLoading,
  isPlaceOrdoVisible,
  onCompleteOrdo,
  onDrawerChangeState,
  onOptionQuantityDecrement,
  onOptionQuantityIncrement,
  isAddToOrdoModalOpen,
  onAddToOrdoModalClose,
  tableNumber,
  tablePin,
  seatNumber,
  screenHeight,
  onModalChangeState,
  isAddToOrdoDisabled,
  specialInstructions,
  ordoClosingAt,
  onSpecialInstructionsChange,
  onSizeSelect,
  onSpecialInstructionsClose,
  size,
  onOrdoPlace,
  categories,
  subcategories,
  onCategorySelect,
  onSubcategorySelect,
  selectedCategoryId,
  isModalOpen,
  onOptionValueSelect,
  selectedMenuItemsQuantity,
  // selectedSubcategory
  title,
  price,
  specialPrice,
  imageSrc,
  description,
  isDrawerOpened,
  optionsList,
  quantity,
  onDrawerOpen,
  onDrawerClose,
  onDecrement,
  onIncrement,
  onMenuItemAdd,
  onBackUp,
  onModalAddMoreItems,
}) => {
  const memoizedOptionsList = useMemo(() => (
    optionsList.map(({
      id,
      name,
      maxRequiredValuesAmount,
      allowValueMultipleSelection,
      values,
      valuesSelected,
      requiredValue,
    }) => (
      <MenuItemOptions
        requiredValue={requiredValue}
        allowValueMultipleSelection={allowValueMultipleSelection}
        onOptionQuantityDecrement={onOptionQuantityDecrement}
        onOptionQuantityIncrement={onOptionQuantityIncrement}
        key={id}
        id={id}
        name={name}
        maxRequiredValuesAmount={maxRequiredValuesAmount}
        values={values}
        valuesSelected={valuesSelected}
        onOptionValueSelect={onOptionValueSelect}
      />
    ))
  ), [optionsList]);

  const getItemPriceComponent = useCallback(() => {
    if (!specialPrice && specialPrice !== 0) {
      return (
        <StyledCircle
          diameter={47}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="darkGrey"
        >
          <Typography color="white" size={(price / 100) % 1 ? 'md' : 'xl2'} weight="bold">
            {`$${formatPrice(price)}`}
          </Typography>
        </StyledCircle>
      );
    }

    return (
      <StyledPriceWrapper row justifyEnd>
        <StyledCircle
          diameter={47}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="strawberry"
        >
          <Typography color="white" size={(price / 100) % 1 ? 'md' : 'xl2'} weight="medium">
            {`$${formatPrice(price)}`}
          </Typography>
        </StyledCircle>
        <StyledCircle
          diameter={47}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="white"
        >
          <Typography color="grannySmith" size={(specialPrice / 100) % 1 ? 'md' : 'xl2'} weight="bold">
            {`$${formatPrice(specialPrice)}`}
          </Typography>
        </StyledCircle>
      </StyledPriceWrapper>
    );
  }, [price, specialPrice]);

  return (
    <Menu
      isLoading={isLoading}
      isPlaceOrdoVisible={isPlaceOrdoVisible}
      seatNumber={seatNumber}
      tablePin={tablePin}
      tableNumber={tableNumber}
      onBackUp={onBackUp}
      isFooterVisible={false}
      selectedMenuItemsQuantity={selectedMenuItemsQuantity}
      ordoClosingAt={ordoClosingAt}
      onOrdoPlace={onOrdoPlace}
      hasMarginBottom={false}
    >
      {!isDrawerOpened && (
        <MenuHeader
          categories={categories}
          subcategories={subcategories}
          onCategorySelect={onCategorySelect}
          onSubcategorySelect={onSubcategorySelect}
          selectedCategoryId={selectedCategoryId}
        />
      )}
      <Wrapper
        isDrawerOpened={isDrawerOpened}
        hasSubcategories={Boolean(subcategories.length)}
        screenHeight={screenHeight}
        column
        justifyEnd
        imageSrc={imageSrc}
      >
        <Flex onClick={onDrawerClose} grow={0} column padding="xl5">
          <Flex
            justifyBetween
            itemsCenter
            marginBottom="lg"
          >
            <StyledTypography color="white" size="xl4" weight="black">
              {title.toUpperCase()}
            </StyledTypography>
            {getItemPriceComponent()}
          </Flex>
          <StyledTypography
            color="white"
            size="lg"
            weight="bold"
          >
            {description}
          </StyledTypography>
        </Flex>
        <Drawer isDrawerOpened={isDrawerOpened} onDrawerChangeState={onDrawerChangeState}>
          <Options onClick={onDrawerOpen} grow={0} isDrawerOpened={isDrawerOpened} column>
            {Boolean(size.sizes.length) && (
              <MenuItemSizes
                sizes={size.sizes}
                sizeSelected={size.sizeSelected}
                onSizeSelect={onSizeSelect}
              />
            )}
            {memoizedOptionsList}
          </Options>
          <ItemDetailsActionPanel
            onCompleteOrdo={onCompleteOrdo}
            isBackUpButtonVisible={isBackUpButtonVisible}
            onBackUp={onBackUp}
            isAddToOrdoModalOpen={isAddToOrdoModalOpen}
            isAddToOrdoDisabled={isAddToOrdoDisabled}
            isModalOpen={isModalOpen}
            onModalChangeState={onModalChangeState}
            quantity={quantity}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            onMenuItemAdd={onMenuItemAdd}
            onSpecialInstructionsClose={onSpecialInstructionsClose}
            onSpecialInstructionsChange={onSpecialInstructionsChange}
            specialInstructions={specialInstructions}
            onAddToOrdoModalClose={onAddToOrdoModalClose}
            onModalAddMoreItems={onModalAddMoreItems}
          />
        </Drawer>
      </Wrapper>
    </Menu>
  );
};

const Wrapper = styled(ImageBackground)`
  ${({
    screenHeight, isDrawerOpened, hasSubcategories, theme: { sizing },
  }) => css`
    width: 100%;
    height: calc(${screenHeight}px - ${hasSubcategories
  ? `${sizing.menuBar.height * 2}px`
  : `${sizing.menuBar.height}px`});
    ${isDrawerOpened && `height: ${screenHeight}px;`}
  `}
`;

const Options = styled(Flex)`
  ${({ isDrawerOpened, theme: { color } }) => css`
    overflow-y: ${isDrawerOpened ? 'scroll' : 'hidden'};

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 9px;
      background-color: ${color.warmGrey};
    }
  `}
`;

const StyledTypography = styled(Typography)`
  ${({ theme: { color } }) => css`
    text-shadow: 1px 1px ${color.black};
  `}
`;

const StyledCircle = styled(Circle)`
  ${() => css`
    min-width: 47px;
  `}
`;

const StyledPriceWrapper = styled(Flex)`
  ${({ theme: { spacing } }) => css`
    div:first-child {
      margin-right: ${spacing.md};

      p {
        text-decoration: line-through;
      }
    }
  `}
`;

MenuItemDetails.defaultProps = {
  specialPrice: null,
  imageSrc: '',
  tableNumber: '',
  tablePin: '',
  seatNumber: '',
  onOrdoPlace: () => {},
  ordoClosingAt: '',
  selectedMenuItemsQuantity: 0,
  onCompleteOrdo: () => {},
  isAddToOrdoModalOpen: false,
  quantity: 0,
  onIncrement: () => {},
  onDecrement: () => {},
  onAddToOrdoModalClose: () => {},
  isLoading: false,
  onDrawerClose: () => {},
  onDrawerChangeState: () => {},
};

MenuItemDetails.propTypes = {
  isBackUpButtonVisible: bool.isRequired,
  isLoading: bool,
  isPlaceOrdoVisible: bool.isRequired,
  onCompleteOrdo: func,
  onDrawerChangeState: func,
  onOptionQuantityDecrement: func.isRequired,
  onOptionQuantityIncrement: func.isRequired,
  isAddToOrdoModalOpen: bool,
  tableNumber: string,
  onDrawerClose: func,
  tablePin: string,
  seatNumber: string,
  onModalChangeState: func.isRequired,
  screenHeight: number.isRequired,
  ordoClosingAt: string,
  specialInstructions: string.isRequired,
  size: exact({
    sizeSelected: number,
    sizes: arrayOf(exact({
      name: string,
      price: number,
      specialPrice: oneOfType([
        number,
        oneOf([null]),
      ]),
      id: number,
    })),
  }).isRequired,
  isAddToOrdoDisabled: bool.isRequired,
  onSizeSelect: func.isRequired,
  onOrdoPlace: func,
  onSpecialInstructionsChange: func.isRequired,
  categories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  subcategories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  onCategorySelect: func.isRequired,
  onSubcategorySelect: func.isRequired,
  onSpecialInstructionsClose: func.isRequired,
  selectedCategoryId: number.isRequired,
  onOptionValueSelect: func.isRequired,
  isModalOpen: bool.isRequired,
  selectedMenuItemsQuantity: number,
  title: string.isRequired,
  price: number.isRequired,
  specialPrice: number,
  imageSrc: string,
  description: string.isRequired,
  isDrawerOpened: bool.isRequired,
  optionsList: arrayOf(exact({
    allowValueMultipleSelection: bool,
    name: string,
    requiredValue: bool.isRequired,
    maxRequiredValuesAmount: number,
    id: number,
    valuesSelected: arrayOf(exact({
      id: number,
      quantity: number,
    })),
    values: arrayOf(exact({
      id: number,
      imageSrc: string,
      name: string,
      price: number,
    })),
  })).isRequired,
  quantity: number,
  onDrawerOpen: func.isRequired,
  onDecrement: func,
  onIncrement: func,
  onMenuItemAdd: func.isRequired,
  onBackUp: func.isRequired,
  onAddToOrdoModalClose: func,
  onModalAddMoreItems: func.isRequired,
};

export default MenuItemDetails;
