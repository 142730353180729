import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { resetStoreToInitialStateAction } from '@store/registration/duck';
import * as api from './api';

// < ----- ACTIONS ----- > //
const loadMenuRoutine = createRoutine(
  'LOAD_MENU',
);

const loadStaticMenuRoutine = createRoutine('LOAD_STATIC_MENU');

const loadMenuForStaffRoutine = createRoutine(
  'LOAD_MENU_FOR_STAFF',
);

const updateItemActivityAction = 'UPDATE_ITEM_ACTIVITY';
const updateCategoryActivityAction = 'UPDATE_CATEGORY_ACTIVITY';
const updateSubcategoryActivityAction = 'UPDATE_SUBCATEGORY_ACTIVITY';

const setSelectedCategoryAction = 'SET_SELECTED_CATEGORY';

// < ----- ACTION CREATORS ----- > //
export const resetStoreToInitialState = () => ({
  type: resetStoreToInitialStateAction,
});

export const setSelectedCategory = data => ({
  type: setSelectedCategoryAction,
  payload: data,
});

export const loadMenu = () => async dispatch => {
  try {
    dispatch(loadMenuRoutine.request());

    const response = await api.loadMenu();

    return dispatch(loadMenuRoutine.success(response.data));
  } catch (error) {
    return dispatch(loadMenuRoutine.failure(error.response));
  } finally {
    dispatch(loadMenuRoutine.fulfill());
  }
};

export const loadStaticMenu = () => async dispatch => {
  try {
    dispatch(loadStaticMenuRoutine.request());

    const response = await api.loadStaticMenu();

    return dispatch(loadStaticMenuRoutine.success(response.data));
  } catch (error) {
    return dispatch(loadStaticMenuRoutine.failure(error.response));
  } finally {
    dispatch(loadStaticMenuRoutine.fulfill());
  }
};

export const loadMenuForStaff = token => async dispatch => {
  try {
    dispatch(loadMenuForStaffRoutine.request());

    const response = await api.loadMenuForStaff(token);

    return dispatch(loadMenuForStaffRoutine.success(response.data));
  } catch (error) {
    return dispatch(loadMenuForStaffRoutine.failure(error.response));
  } finally {
    dispatch(loadMenuForStaffRoutine.fulfill());
  }
};

export const updateItemActivity = data => ({
  type: updateItemActivityAction,
  payload: data,
});

export const updateCategoryActivity = data => ({
  type: updateCategoryActivityAction,
  payload: data,
});

export const updateSubcategoryActivity = data => ({
  type: updateSubcategoryActivityAction,
  payload: data,
});

// < ----- STATE ----- > //
export const menuStorePersistWhitelist = [
  'categories', 'options', 'optionValues', 'subcategories', 'sizes', 'items', 'selectedCategoryId',
];

const initialState = {
  categories: {
    byId: {},
    sortedIds: [],
  },
  options: {},
  optionValues: {},
  subcategories: {},
  sizes: {},
  items: {},
  selectedCategoryId: 0,

  loadMenuPhase: INIT,
  loadMenuError: null,

  loadStaticMenuPhase: INIT,
  loadStaticMenuError: null,

  loadMenuForStaffPhase: INIT,
  loadMenuForStaffError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;

    // SET SELECTED CATEGORY
    case setSelectedCategoryAction:
      return {
        ...state,
        selectedCategoryId: payload.categoryId,
      };
    // LOAD MENU
    case loadMenuRoutine.REQUEST:
      return {
        ...state,
        loadMenuPhase: LOADING,
      };
    case loadMenuRoutine.SUCCESS:
      return {
        ...state,
        loadMenuPhase: SUCCESS,
        categories: {
          ...state.categories,
          ...payload.categories,
        },
        subcategories: {
          ...state.subcategories,
          ...payload.subcategories,
        },
        items: {
          ...state.items,
          ...payload.items,
        },
        sizes: {
          ...state.sizes,
          ...payload.sizes,
        },
        options: {
          ...state.options,
          ...payload.options,
        },
        optionValues: {
          ...state.optionValues,
          ...payload.optionValues,
        },
      };
    case loadMenuRoutine.FAILURE:
      return {
        ...state,
        loadMenuPhase: FAILURE,
        loadMenuError: payload,
      };
    case loadMenuRoutine.FULFILL:
      return {
        ...state,
        loadMenuPhase: initialState.loadMenuPhase,
        loadMenuError: initialState.loadMenuError,
      };

      // LOAD MENU
    case loadStaticMenuRoutine.REQUEST:
      return {
        ...state,
        loadStaticMenuPhase: LOADING,
      };
    case loadStaticMenuRoutine.SUCCESS:
      return {
        ...state,
        loadStaticMenuPhase: SUCCESS,
        categories: {
          ...state.categories,
          ...payload.categories,
        },
        subcategories: {
          ...state.subcategories,
          ...payload.subcategories,
        },
        items: {
          ...state.items,
          ...payload.items,
        },
        sizes: {
          ...state.sizes,
          ...payload.sizes,
        },
        options: {
          ...state.options,
          ...payload.options,
        },
        optionValues: {
          ...state.optionValues,
          ...payload.optionValues,
        },
      };
    case loadStaticMenuRoutine.FAILURE:
      return {
        ...state,
        loadStaticMenuPhase: FAILURE,
        loadStaticMenuError: payload,
      };
    case loadStaticMenuRoutine.FULFILL:
      return {
        ...state,
        loadStaticMenuPhase: initialState.loadStaticMenuPhase,
        loadStaticMenuError: initialState.loadStaticMenuError,
      };

    // LOAD MENU FOR STAFF
    case loadMenuForStaffRoutine.REQUEST:
      return {
        ...state,
        loadMenuForStaffPhase: LOADING,
      };
    case loadMenuForStaffRoutine.SUCCESS:
      return {
        ...state,
        loadMenuForStaffPhase: SUCCESS,
        categories: {
          ...state.categories,
          ...payload.categories,
        },
        subcategories: {
          ...state.subcategories,
          ...payload.subcategories,
        },
        items: {
          ...state.items,
          ...payload.items,
        },
        sizes: {
          ...state.sizes,
          ...payload.sizes,
        },
        options: {
          ...state.options,
          ...payload.options,
        },
        optionValues: {
          ...state.optionValues,
          ...payload.optionValues,
        },
      };
    case loadMenuForStaffRoutine.FAILURE:
      return {
        ...state,
        loadMenuForStaffPhase: FAILURE,
        loadMenuForStaffError: payload,
      };
    case loadMenuForStaffRoutine.FULFILL:
      return {
        ...state,
        loadMenuForStaffPhase: initialState.loadMenuForStaffPhase,
        loadMenuForStaffError: initialState.loadMenuForStaffError,
      };
    // UPDATE ITEM ACTIVITY
    case updateItemActivityAction:
      return {
        ...state,
        items: {
          ...state.items,
          [payload.id]: {
            ...state.items[payload.id],
            active: payload.active,
          },
        },
      };
    // UPDATE CATEGORY ACTIVITY
    case updateCategoryActivityAction:
      return {
        ...state,
        categories: {
          ...state.categories,
          byId: {
            ...state.categories.byId,
            [payload.id]: {
              ...state.categories.byId[payload.id],
              active: payload.active,
            },
          },
        },
      };
    // UPDATE SUBCATEGORY ACTIVITY
    case updateSubcategoryActivityAction:
      return {
        ...state,
        subcategories: {
          ...state.subcategories,
          [payload.id]: {
            ...state.subcategories[payload.id],
            active: payload.active,
          },
        },
      };

    default:
      return state;
  }
};

export default store;
