import React from 'react';
import styled, { css } from 'styled-components';
import { string } from 'prop-types';
import {
  Flex, Typography, Icon, Circle,
} from '@atoms';
import { useTimer } from '@hooks';
import { ReactComponent as Timer } from '@assets/timer.svg';

const closingOrdoStatusColor = {
  0: 'strawberry',
  1: 'strawberry',
  2: 'sunYellow',
  3: 'grannySmith',
};

const FooterTimer = ({ ordoClosingAt }) => {
  const { minutes, seconds } = useTimer(ordoClosingAt);

  const time = minutes < 0 ? 'SENDING' : `${minutes}:${seconds}`;

  return (
    <Wrapper
      isVisible={!Number.isNaN(minutes) && !Number.isNaN(seconds)}
      closingIn={minutes + 1}
      selfCenter
      column
      itemsCenter
    >
      <Circle
        itemsCenter
        justifyCenter
        diameter={58}
        backgroundColor="white"
        marginBottom="xxs"
      >
        <Icon SVG={Timer} width="24px" height="24px" />
      </Circle>
      <Typography padding="xs" size="sm" weight="bold" color="grannySmith">
        {time}
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  ${({ isVisible, closingIn, theme: { color, border, boxShadow } }) => css`
    visibility: ${isVisible ? 'visible' : 'hidden'};
    pointer-events: all;

    ${Typography} {
      background-color: ${color.white};
      color: ${color[closingOrdoStatusColor[closingIn]]};
      border-radius: ${border.radius.default};
      box-shadow: ${boxShadow.default};
    }

    svg {
      path {
        fill: ${color[closingOrdoStatusColor[closingIn]]};
      }
    }
  `}
`;

FooterTimer.propTypes = {
  ordoClosingAt: string.isRequired,
};

export default FooterTimer;
