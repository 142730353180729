import styled, { css } from 'styled-components';
import { marginMixin } from '@mixins';
import SliderIcon from '@assets/slider.svg'; // TODO: use proper icon

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const RangeSlider = styled.input`
  ${({
    theme: {
      border, color, boxShadow,
    },
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    background: ${color.grannySmith};
    border-radius: 15px;

    :focus {
      outline: none;
    }

    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      user-select: none;
      height: 40px;
      width: 40px;
      box-shadow: ${boxShadow.default};
      border: 0px;
      border-radius: ${border.radius.circular};
      background: url(${SliderIcon}) ${color.white};
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  `}
`;

export default RangeSlider;
