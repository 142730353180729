import { css, createGlobalStyle } from 'styled-components';

const fontFace = (family, src, weight) => css`
  @font-face {
    font-family: ${family};
    src: url('/fonts/${src}.eot') format('embedded-opentype'),
      url('/fonts/${src}.ttf') format('truetype'),
      url('/fonts/${src}.woff') format('woff');
    font-display: swap;
    font-weight: ${weight};
  }
`;

const FontStyle = createGlobalStyle`
  ${fontFace('CeraRoundBasic', 'CeraRoundBasic-Black', 900)};
  ${fontFace('CeraRoundBasic', 'CeraRoundBasic-Bold', 700)};
  ${fontFace('CeraRoundBasic', 'CeraRoundBasic-Light', 300)};
  ${fontFace('CeraRoundBasic', 'CeraRoundBasic-Medium', 500)};
  ${fontFace('CeraRoundBasic', 'CeraRoundBasic-Regular', 400)};
  ${fontFace('CeraRoundBasic', 'CeraRoundBasic-Thin', 100)};
`;

export default FontStyle;
