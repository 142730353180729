import styled, { css } from 'styled-components';
import { marginMixin, paddingMixin, variant } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Circular = styled.button`
  ${({
    diameter,
    theme: {
      border,
      typography,
      boxShadow,
    },
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    ${variant.circular}

    ${diameter && `
      width: ${diameter}px;
      height: ${diameter}px;
    `}

    box-shadow: ${boxShadow.default};
    display: inline-block;
    border: none;
    border-radius: ${border.radius.circular};
    padding: 0px;
    font-family: ${typography.family};
    font-size: ${typography.size.xl2};
    font-weight: ${typography.weight.black};
    line-height: normal;
    outline: none;
  `}
`;

const Rectangular = styled.button`
  ${({
    fullWidth,
    theme: { border, typography, boxShadow },
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    padding: 0px;

    ${padding}
    ${paddingTop}
    ${paddingRight}
    ${paddingBottom}
    ${paddingLeft}

    ${variant.rectangular}

    ${fullWidth && `
      width: 100%;
    `}

    box-shadow: ${boxShadow.default};
    display: inline-block;
    border: none;
    border-radius: ${border.radius.default};
    font-family: ${typography.family};
    font-size: ${typography.size.xl};
    font-weight: ${typography.weight.black};
    line-height: normal;
    outline: none;
  `}
`;

const Ghost = styled.button`
  ${({ theme: { typography } }) => css`
   ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    padding: 0px;

    ${padding}
    ${paddingTop}
    ${paddingRight}
    ${paddingBottom}
    ${paddingLeft}

    ${variant.ghost}

    display: inline-block;
    border: none;
    font-family: ${typography.family};
    font-size: ${typography.size.xl};
    font-weight: ${typography.weight.black};
    line-height: normal;
    text-decoration: underline;
    outline: none;
  `}
`;

export default {
  Circular,
  Rectangular,
  Ghost,
};
