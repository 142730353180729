import React from 'react';
import styled, { css } from 'styled-components';
import {
  bool, func, string, number,
} from 'prop-types';
import { Flex, Typography, Circle } from '@atoms';
import { OptionQuantityCounter } from '@molecules';
import { formatPrice } from '@helpers/myOrdo';

const ItemOptionValue = ({
  onOptionQuantityDecrement,
  onOptionQuantityIncrement,
  quantity,
  id,
  optionId,
  imageSrc,
  name,
  price,
  isSelected,
  onOptionValueSelect,
  allowValueMultipleSelection,
}) => (
  <Wrapper grow={0} display="inline-flex" wrapFlex column>
    <OptionQuantityCounter
      onOptionQuantityDecrement={onOptionQuantityDecrement}
      onOptionQuantityIncrement={onOptionQuantityIncrement}
      quantity={quantity}
      allowValueMultipleSelection={allowValueMultipleSelection}
      optionId={optionId}
      id={id}
    />
    <ImageFlex
      marginTop="xxs"
      imageSrc={imageSrc}
      className={isSelected && 'selected-value'}
      onClick={onOptionValueSelect(optionId, id)}
      diameter={57}
      selfCenter
      grow={0}
    >
      {allowValueMultipleSelection && !!quantity && (
        <StyledCircle
          backgroundColor="strawberry"
          diameter={18}
          justifyCenter
          itemsCenter
        >
          <Typography color="white" size="sm" weight="black">
            {quantity}
          </Typography>
        </StyledCircle>
      )}
    </ImageFlex>
    <StyledTypography size="xs" weight="black" marginTop="sm">
      {name}
      {' '}
      {price > 0 && (
        <Typography as="span" color="strawberry" size="xs" weight="black">
          {`($${formatPrice(price)})`}
        </Typography>
      )}
    </StyledTypography>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ theme: { spacing, sizing, color } }) => css`
    max-width: ${sizing.option.width};
    text-align: center;
    white-space: normal;

    :not(:first-child) {
      margin-left: ${spacing.md};
    }

    .selected-value {
      border: 4px solid ${color.grannySmith};
    }
  `}
`;

const ImageFlex = styled(Circle)`
  ${({
    imageSrc,
  }) => css`
    background: url(${imageSrc});
    background-size: cover;
    position: relative;
    background-size: cover;
  `}
`;

const StyledTypography = styled(Typography)`
  ${({ theme: { sizing } }) => css`
    text-overflow: ellipsis;
    overflow: hidden;
    width: ${sizing.option.width};
  `}
`;

const StyledCircle = styled(Circle)`
  ${() => css`
    position: absolute;
    top: -4px;
    left: -4px;
  `}
`;

ItemOptionValue.propTypes = {
  allowValueMultipleSelection: bool.isRequired,
  quantity: number.isRequired,
  onOptionQuantityDecrement: func.isRequired,
  onOptionQuantityIncrement: func.isRequired,
  optionId: number.isRequired,
  id: number.isRequired,
  imageSrc: string.isRequired,
  name: string.isRequired,
  price: number.isRequired,
  isSelected: bool.isRequired,
  onOptionValueSelect: func.isRequired,
};

export default ItemOptionValue;
