import React from 'react';
import { string } from 'prop-types';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';

const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
};

const Modal = styled(ReactModalAdapter)`
  ${({
    theme: {
      boxShadow, color, spacing, border, sizing,
    },
  }) => css`
    &__overlay {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &__content {
      background-color: ${color.white};
      position: absolute;
      top: 35%;
      left: ${sizing.modal.left};
      right: ${sizing.modal.right};
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      border-radius: ${border.radius.modal};
      box-shadow: ${boxShadow.default};
      outline: none;
      padding: ${spacing.xl4};
      padding-top: ${spacing.xl6};
    }
  `}
`;

ReactModalAdapter.defaultProps = {
  className: 'Modal',
};

ReactModalAdapter.propTypes = {
  className: string,
};

export default Modal;
