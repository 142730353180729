import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { BillReview as View } from '@views';
import { apiEndpoints, common, routes } from '@constants';
import { client } from '@utils';
import { getUserItems } from '@helpers/myBill';
import { withSocket } from '@hocs';
import { reportError } from '../../sentry';

const FinalBillReview = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector(store => store.registrationStore);
  const { userBill } = useSelector(store => store.myBillStore);
  const { items, optionValues, sizes } = useSelector(store => store.menuStore);

  const handleBackUp = () => {
    history.goBack();
  };

  const handleConfirm = async () => {
    if (userBill.total) {
      setIsLoading(true);

      const stripe = await loadStripe(common.STRIPE_PUBLIC_KEY);

      const response = await client.authorized.get(apiEndpoints.billSession);
      const { sessionId } = response.data;

      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      setIsLoading(false);

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        // eslint-disable-next-line no-console
        reportError('Failed redirecting to Stripe checkout', 'userId', user.sessionUserId);
      }
    } else {
      history.push(routes.checkoutSuccess);
    }

    setIsLoading(false);
  };

  const memoizedUserItems = useMemo(() => getUserItems(userBill.items, items, optionValues, sizes), [userBill.items]);

  const finalBill = useMemo(() => ({
    total: userBill.total,
    subtotal: userBill.subtotal,
    items: memoizedUserItems,
    tax: userBill.tax,
    tip: userBill.tip,
  }), [userBill]);

  return (
    <View
      isLoading={isLoading}
      onBackUp={handleBackUp}
      onConfirm={handleConfirm}
      bill={finalBill}
    />
  );
};

export default withSocket(FinalBillReview);
