import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MyBill as View } from '@views';
import { routes } from '@constants';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { loadBill, moveItem, payForTheWholeTable } from '@store/myBill/duck';
import { withSocket } from '@hocs';
import { getSessionOrdos } from '@helpers/myBill';

const MyBill = () => {
  const {
    loadBillPhase,
    moveItemPhase,
    payForTheWholeTablePhase,
    users,
  } = useSelector(store => store.myBillStore);
  const {
    items, sizes, optionValues, options,
  } = useSelector(store => store.menuStore);
  const { isHeadOfTheTable, user } = useSelector(store => store.registrationStore);

  const isLoading = (loadBillPhase === LOADING)
  || (moveItemPhase === LOADING)
  || (payForTheWholeTablePhase === LOADING);
  const headOfTheTableId = users.length ? users.find(currentUser => currentUser.isHeadOfTheTable).id : 0;
  const hasConfirmBillButton = user.isLeavingEarlier || isHeadOfTheTable;

  const [hasServerError, setHasServerError] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadBill());
  }, []);

  useEffect(() => {
    if (loadBillPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [loadBillPhase]);

  useEffect(() => {
    if (moveItemPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [moveItemPhase]);

  useEffect(() => {
    if (payForTheWholeTablePhase === FAILURE) {
      setHasServerError(true);
    }
  }, [payForTheWholeTablePhase]);

  const handleBackUp = () => {
    history.push(routes.myOrdo);
  };

  const handleDragEnd = ({ draggableId, destination, source }) => {
    if (destination) {
      const movingInfo = {
        ordoUserItemId: parseInt(draggableId, 10),
        menuItemFinalUserId: parseInt(destination.droppableId, 10),
      };

      if ((source.droppableId !== destination.droppableId) && isHeadOfTheTable) {
        dispatch(moveItem(movingInfo));
      }
    }
  };

  const handlePayForTheWholeTable = () => {
    dispatch(payForTheWholeTable());
  };

  const handleBillConfirm = () => {
    history.push(routes.tipForServer);
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const memoizedSessionOrdos = useMemo(() => (
    getSessionOrdos(users, items, optionValues, sizes, user.sessionUserId, user.isLeavingEarlier, options)
  ), [users, user.isLeavingEarlier]);

  return (
    <View
      hasServerError={hasServerError}
      onToastClose={handleToastClose}
      onBackUp={handleBackUp}
      hasBackUpButton={user.isLeavingEarlier}
      hasConfirmBillButton={hasConfirmBillButton}
      isLoading={isLoading}
      userId={user.sessionUserId}
      headOfTheTableId={headOfTheTableId}
      isHeadOfTheTable={isHeadOfTheTable}
      sessionOrdos={memoizedSessionOrdos}
      onDragEnd={handleDragEnd}
      onBillConfirm={handleBillConfirm}
      onPayForTheWholeTable={handlePayForTheWholeTable}
    />
  );
};

export default withSocket(MyBill);
