import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PreAuthorization as View } from '@views';
import { routes } from '@constants';

const PreAuthorization = () => {
  const { tableNumber, seatNumber } = useSelector(store => (
    store.registrationStore
  ));

  const history = useHistory();

  const [hasServerError, setHasServerError] = useState(false);
  const [isLoading] = useState(false);

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const handlePreauthorize = () => {
    history.push(routes.checkout);
  };

  const handleBackUp = () => {
    history.push(routes.tableNumber);
  };

  return (
    <View
      tableNumber={tableNumber}
      seatNumber={seatNumber}
      onToastClose={handleToastClose}
      hasServerError={hasServerError}
      onPreauthorize={handlePreauthorize}
      onBackUp={handleBackUp}
      isLoading={isLoading}
    />
  );
};

export default PreAuthorization;
