import React from 'react';
import { bool, func, node } from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, Flex } from '@atoms';

const Tooltip = ({
  isTooltipOpened,
  onTooltipOpen,
  children,
}) => (
  <Flex column itemsCenter>
    <Flex paddingBottom="xl3">
      <TooltipButton
        diameter={44}
        isActive={isTooltipOpened}
        onClick={onTooltipOpen}
        data-cy="tooltip"
      >
        ?
      </TooltipButton>
    </Flex>
    {isTooltipOpened && (
      <Hint column itemsCenter>
        {children}
      </Hint>
    )}
  </Flex>
);

const TooltipButton = styled(Button.Circular)`
  ${({ isActive, theme: { color } }) => css`
    color: ${color.strawberry};

    ${isActive && `
      background-color: ${color.strawberry};
      color: ${color.white};
    `}
  `}
`;

const Hint = styled(Flex)`
  ${({
    theme: {
      boxShadow, border, color, spacing,
    },
  }) => css`
    padding: ${spacing.xl3} ${spacing.md};
    border-radius: ${border.radius.hint};
    background-color: ${color.strawberry};
    box-shadow: ${boxShadow.default};
    text-align: center;
  `}
`;

Tooltip.propTypes = {
  isTooltipOpened: bool.isRequired,
  onTooltipOpen: func.isRequired,
  children: node.isRequired,
};

export default Tooltip;
