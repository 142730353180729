import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button, Flex, Typography,
} from '@atoms';
import { Registration } from '@templates';

const PreAuthorization = ({
  isLoading,
  seatNumber,
  tableNumber,
  onBackUp,
  onPreauthorize,
  hasServerError,
  onToastClose,
}) => (
  <Registration
    seatNumber={seatNumber}
    tableNumber={tableNumber}
    onToastClose={onToastClose}
    hasServerError={hasServerError}
    progressValue={80}
    onBackUp={onBackUp}
    isLoading={isLoading}
  >
    <Flex column itemsCenter padding="md" paddingTop="xl6">
      <Flex column grow={0} marginBottom="xl5">
        <Typography size="xl4" weight="black" marginBottom="xl5">
          To place an order, please pre-authorize.
        </Typography>
        <Typography size="lg" weight="bold">
          This is the same as pre-authorization at a gas station, you will only be charged for the amount you order.
        </Typography>
      </Flex>
      <Button.Rectangular
        primary
        gradient
        fullWidth
        padding="xl2"
        marginBottom="xl2"
        onClick={onPreauthorize}
        data-cy="next"
      >
        $100
      </Button.Rectangular>
    </Flex>
  </Registration>
);

PreAuthorization.propTypes = {
  isLoading: bool.isRequired,
  tableNumber: string.isRequired,
  seatNumber: string.isRequired,
  onToastClose: func.isRequired,
  hasServerError: bool.isRequired,
  onBackUp: func.isRequired,
  onPreauthorize: func.isRequired,
};

export default PreAuthorization;
