import React from 'react';
import {
  bool, func, string, number,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, Flex, Typography } from '@atoms';
import { TipOptions, BillSection } from '@molecules';
import { Ordo } from '@templates';
import { ReactComponent as HeaderIcon } from '@assets/my-bill.svg';

const TipForServer = ({
  subtotal,
  selectedAmount,
  onTipAmountSelect,
  onTipConfirm,
  onCustomAmountSelect,
  onBackUp,
  hasServerError,
  onToastClose,
  isLoading,
}) => (
  <Ordo
    pageTitle="Tip"
    isLoading={isLoading}
    title="Pay my bill"
    headerIconSrc={HeaderIcon}
    onBackUp={onBackUp}
    hasServerError={hasServerError}
    onToastClose={onToastClose}
  >
    <Wrapper column>
      <BillSection
        tip
        title="SUBTOTAL"
        amount={subtotal}
      />
      <Flex padding="xl5" column>
        <Typography as="h1" size="xl4" weight="black" marginBottom="xl5">
          Leave a tip for your server?
        </Typography>
        <Flex marginBottom="xl5">
          <TipOptions
            selectedAmount={selectedAmount}
            onTipAmountSelect={onTipAmountSelect}
          />
        </Flex>
        <Button.Rectangular
          secondary
          fullWidth
          padding="xl"
          marginBottom="md"
          onClick={onTipConfirm}
        >
          Confirm tip
        </Button.Rectangular>
        <Button.Rectangular
          primaryInverted
          fullWidth
          padding="xl"
          onClick={onCustomAmountSelect}
        >
          Custom amount
        </Button.Rectangular>
      </Flex>
    </Wrapper>
  </Ordo>
);

const Wrapper = styled(Flex)`
  ${({ theme: { color } }) => css`
    border-top: 1px solid ${color.dust};
    text-align: center;
  `}
`;

TipForServer.propTypes = {
  hasServerError: bool.isRequired,
  onToastClose: func.isRequired,
  subtotal: number.isRequired,
  isLoading: bool.isRequired,
  selectedAmount: string.isRequired,
  onTipAmountSelect: func.isRequired,
  onTipConfirm: func.isRequired,
  onCustomAmountSelect: func.isRequired,
  onBackUp: func.isRequired,
};

export default TipForServer;
