import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { connectSocketForPreAuth } from '@store/socket/duck';
import { routes } from '@constants';

const preAuthorizationSocket = Component => () => {
  const { isConnectedForPreAuth, tokenError } = useSelector(state => state.socketStore);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isConnectedForPreAuth) {
      dispatch(connectSocketForPreAuth());
    }
  }, []);

  useEffect(() => {
    if (tokenError) {
      history.push(routes.tableNumber);
    }
  }, [tokenError]);

  return (
    <Component />
  );
};

export default preAuthorizationSocket;
