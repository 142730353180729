import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { resetStoreToInitialStateAction } from '@store/registration/duck';
import { storage } from '@utils';
import * as api from './api';

// < ----- ACTIONS ----- > //
const loadBillRoutine = createRoutine(
  'LOAD_BILL',
);

const moveItemRoutine = createRoutine(
  'MOVE_ITEM',
);

const payForTheWholeTableRoutine = createRoutine(
  'PAY_FOR_THE_WHOLE_TABLE',
);

const closeAllBillsRoutine = createRoutine(
  'CLOSE_ALL_BILLS',
);

const loadCurrentUserBillRoutine = createRoutine(
  'LOAD_CURRENT_USER_BILL',
);

const confirmTipRoutine = createRoutine(
  'CONFIRM_TIP',
);

const refreshBillStateAction = 'REFRESH_BILL_STATE';

const applyDiscountAction = 'APPLY_DISCOUNT';

const voidItemFromBillAction = 'VOID_ITEM_FROM_BILL';

const updateUserBillAction = 'UPDATE_USER_BILL';

// < ----- ACTION CREATORS ----- > //
export const resetStoreToInitialState = () => ({
  type: resetStoreToInitialStateAction,
});

export const refreshBillState = data => ({
  type: refreshBillStateAction,
  payload: data,
});

export const voidItemFromBill = data => ({
  type: voidItemFromBillAction,
  payload: data,
});

export const loadBill = () => async dispatch => {
  try {
    dispatch(loadBillRoutine.request());

    const response = await api.loadBill();

    return dispatch(loadBillRoutine.success(response.data));
  } catch (error) {
    return dispatch(loadBillRoutine.failure(error.response));
  } finally {
    dispatch(loadBillRoutine.fulfill());
  }
};

export const moveItem = data => async dispatch => {
  try {
    dispatch(moveItemRoutine.request());

    await api.moveItem(data);

    return dispatch(moveItemRoutine.success());
  } catch (error) {
    return dispatch(moveItemRoutine.failure(error.response));
  } finally {
    dispatch(moveItemRoutine.fulfill());
  }
};

export const payForTheWholeTable = () => async dispatch => {
  try {
    dispatch(payForTheWholeTableRoutine.request());

    await api.payForTheWholeTable();

    return dispatch(payForTheWholeTableRoutine.success());
  } catch (error) {
    return dispatch(payForTheWholeTableRoutine.failure(error.response));
  } finally {
    dispatch(payForTheWholeTableRoutine.fulfill());
  }
};

export const closeAllBills = () => async dispatch => {
  try {
    dispatch(closeAllBillsRoutine.request());

    await api.closeAllBills();

    return dispatch(closeAllBillsRoutine.success());
  } catch (error) {
    return dispatch(closeAllBillsRoutine.failure(error.response));
  } finally {
    dispatch(closeAllBillsRoutine.fulfill());
  }
};

export const loadCurrentUserBill = () => async dispatch => {
  try {
    dispatch(loadCurrentUserBillRoutine.request());

    const response = await api.loadCurrentUserBill();

    return dispatch(loadCurrentUserBillRoutine.success(response.data));
  } catch (error) {
    return dispatch(loadCurrentUserBillRoutine.failure(error.response));
  } finally {
    dispatch(loadCurrentUserBillRoutine.fulfill());
  }
};

export const confirmTip = data => async dispatch => {
  try {
    dispatch(confirmTipRoutine.request());

    const response = await api.confirmTip(data);

    return dispatch(confirmTipRoutine.success(response.data));
  } catch (error) {
    return dispatch(confirmTipRoutine.failure(error.response));
  } finally {
    dispatch(confirmTipRoutine.fulfill());
  }
};

export const applyDiscount = data => ({
  type: applyDiscountAction,
  payload: data,
});

export const updateUserBill = data => ({
  type: updateUserBillAction,
  payload: data,
});

// < ----- STATE ----- > //
export const myBillStorePersistWhitelist = ['users', 'userBill'];

const initialState = {
  users: [],
  userBill: {
    subtotal: null,
    total: null,
    items: [],
    tax: {
      taxPercent: 0,
      taxInCash: 0,
    },
    tip: {
      tipPercent: 0,
      tipInCash: 0,
    },
  },

  closeAllBillsPhase: INIT,
  closeAllBillsError: null,

  moveItemPhase: INIT,
  moveItemError: null,

  payForTheWholeTablePhase: INIT,
  payForTheWholeTableError: null,

  loadBillPhase: INIT,
  loadBillError: null,

  loadCurrentUserBillPhase: INIT,
  loadCurrentUserBillError: null,

  confirmTipPhase: INIT,
  confirmTipError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;

    // LOAD BILL
    case loadBillRoutine.REQUEST:
      return {
        ...state,
        loadBillPhase: LOADING,
      };
    case loadBillRoutine.SUCCESS:
      return {
        ...state,
        loadBillPhase: SUCCESS,
        users: payload.users.sort((firstUser, secondUser) => firstUser.id - secondUser.id), // Sort users by user id,
      };
    case loadBillRoutine.FAILURE:
      return {
        ...state,
        loadBillPhase: FAILURE,
        loadBillError: payload,
      };
    case loadBillRoutine.FULFILL:
      return {
        ...state,
        loadBillPhase: initialState.loadBillPhase,
        loadBillError: initialState.loadBillError,
      };

    // REFRESH BILL
    case refreshBillStateAction:
      return {
        ...state,
        users: payload.users.sort((firstUser, secondUser) => firstUser.id - secondUser.id), // Sort users by user id,
      };

    // VOID ITEM FROM BILL
    case voidItemFromBillAction: {
      const updatedUsers = state.users.map(user => ({
        ...user,
        items: user.items.map(item => {
          if (item.id === payload.ordoToUserItemId) {
            const updatedItem = {
              ...item,
              voidReason: payload.voidReason,
            };

            return updatedItem;
          }

          return item;
        }),
      }));

      const updatedUserItems = state.userBill.items.map(item => {
        if (item.ordoItemId === payload.ordoToUserItemId) {
          const updatedItem = {
            ...item,
            voidReason: payload.voidReason,
          };

          return updatedItem;
        }

        return item;
      });

      let updatedUserBill = null;
      const userId = storage.getSessionUserId();

      if (payload.sessionUserId === parseInt(userId, 10)) {
        updatedUserBill = {
          subtotal: payload.newSubtotal ?? state.userBill.subtotal,
          tax: {
            taxInCash: payload.newTax ?? state.userBill.tax.taxInCash,
            taxPercent: state.userBill.tax.taxPercent,
          },
          total: payload.newTotal ?? state.userBill.total,
        };
      }

      return {
        ...state,
        users: updatedUsers,
        userBill: {
          ...state.userBill,
          ...updatedUserBill,
          items: updatedUserItems,
        },
      };
    }

    // MOVE ITEM
    case moveItemRoutine.REQUEST:
      return {
        ...state,
        moveItemPhase: LOADING,
      };
    case moveItemRoutine.SUCCESS:
      return {
        ...state,
        moveItemPhase: SUCCESS,
      };
    case moveItemRoutine.FAILURE:
      return {
        ...state,
        moveItemPhase: FAILURE,
        moveItemError: payload,
      };
    case moveItemRoutine.FULFILL:
      return {
        ...state,
        moveItemPhase: initialState.moveItemPhase,
        moveItemError: initialState.moveItemError,
      };

    // PAY FOR THE WHOLE TABLE
    case payForTheWholeTableRoutine.REQUEST:
      return {
        ...state,
        payForTheWholeTablePhase: LOADING,
      };
    case payForTheWholeTableRoutine.SUCCESS:
      return {
        ...state,
        payForTheWholeTablePhase: SUCCESS,
      };
    case payForTheWholeTableRoutine.FAILURE:
      return {
        ...state,
        payForTheWholeTablePhase: FAILURE,
        payForTheWholeTableError: payload,
      };
    case payForTheWholeTableRoutine.FULFILL:
      return {
        ...state,
        payForTheWholeTablePhase: initialState.payForTheWholeTablePhase,
        payForTheWholeTableError: initialState.payForTheWholeTableError,
      };

    // CLOSE ALL BILLS
    case closeAllBillsRoutine.REQUEST:
      return {
        ...state,
        closeAllBillsPhase: LOADING,
      };
    case closeAllBillsRoutine.SUCCESS:
      return {
        ...state,
        closeAllBillsPhase: SUCCESS,
      };
    case closeAllBillsRoutine.FAILURE:
      return {
        ...state,
        closeAllBillsPhase: FAILURE,
        closeAllBillsError: payload,
      };
    case closeAllBillsRoutine.FULFILL:
      return {
        ...state,
        closeAllBillsPhase: initialState.closeAllBillsPhase,
        closeAllBillsError: initialState.closeAllBillsError,
      };

    // UPDATE USER BILL
    case updateUserBillAction: {
      const userId = storage.getSessionUserId();
      return {
        ...state,
        ...(payload.sessionUserId === parseInt(userId, 10) && {
          userBill: {
            ...state.userBill,
            items: payload.menuItems,
            subtotal: payload.subtotal,
          },
        }),
      };
    }

    // LOAD CURRENT USER BILL
    case loadCurrentUserBillRoutine.REQUEST:
      return {
        ...state,
        loadCurrentUserBillPhase: LOADING,
      };
    case loadCurrentUserBillRoutine.SUCCESS:
      return {
        ...state,
        userBill: {
          ...state.userBill,
          items: payload.menuItems,
          subtotal: payload.subtotal,
        },
        loadCurrentUserBillPhase: SUCCESS,
      };
    case loadCurrentUserBillRoutine.FAILURE:
      return {
        ...state,
        loadCurrentUserBillPhase: FAILURE,
        loadCurrentUserBillError: payload,
      };
    case loadCurrentUserBillRoutine.FULFILL:
      return {
        ...state,
        loadCurrentUserBillPhase: initialState.loadCurrentUserBillPhase,
        loadCurrentUserBillError: initialState.loadCurrentUserBillError,
      };

    // CONFIRM TIP
    case confirmTipRoutine.REQUEST:
      return {
        ...state,
        confirmTipPhase: LOADING,
      };
    case confirmTipRoutine.SUCCESS:
      return {
        ...state,
        userBill: {
          ...state.userBill,
          items: payload.menuItems,
          subtotal: payload.subtotal,
          tax: payload.tax,
          tip: {
            tipInCash: payload.tipInCash,
            tipPercent: payload.subtotal ? parseFloat(((payload.tipInCash / payload.subtotal) * 100).toFixed(2)) : 0,
          },
          total: payload.total,
        },
        confirmTipPhase: SUCCESS,
      };
    case confirmTipRoutine.FAILURE:
      return {
        ...state,
        confirmTipPhase: FAILURE,
        confirmTipError: payload,
      };
    case confirmTipRoutine.FULFILL:
      return {
        ...state,
        confirmTipPhase: initialState.confirmTipPhase,
        confirmTipError: initialState.confirmTipError,
      };

    // APPLY DISCOUNT
    case applyDiscountAction: {
      const updatedUsers = state.users.map(user => ({
        ...user,
        items: user.items.map(item => {
          if (item.id === payload.ordoToUserItemId) {
            const updatedItem = {
              ...item,
              discountReason: payload.discountReason,
              discountAmount: payload.discountAmount,
            };
            return updatedItem;
          }

          return item;
        }),
      }));

      const updatedUserBillItems = state.userBill.items.map(item => {
        if (item.ordoItemId === payload.ordoToUserItemId) {
          return {
            ...item,
            discountReason: payload.discountReason,
            discountAmount: payload.discountAmount,
          };
        }

        return item;
      });

      let updatedUserBill = null;
      const userId = storage.getSessionUserId();

      if (payload.sessionUserId === parseInt(userId, 10)) {
        updatedUserBill = {
          subtotal: payload.newSubtotal ?? state.userBill.subtotal,
          tax: {
            taxInCash: payload.newTax ?? state.userBill.tax.taxInCash,
            taxPercent: state.userBill.tax.taxPercent,
          },
          total: payload.newTotal ?? state.userBill.total,
        };
      }

      return {
        ...state,
        users: updatedUsers,
        userBill: {
          ...state.userBill,
          ...updatedUserBill,
          items: updatedUserBillItems,
        },
      };
    }

    default:
      return state;
  }
};

export default store;
