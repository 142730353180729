import React, { useMemo } from 'react';
import {
  arrayOf, func, string, exact, number,
} from 'prop-types';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import { Button, Flex, Typography } from '@atoms';
import { theme } from '@styles';

const MenuHeader = ({
  categories,
  subcategories,
  onCategorySelect,
  onSubcategorySelect,
  selectedCategoryId,
  selectedSubcategoryId,
}) => {
  const memoizedСategories = useMemo(() => (
    categories.map(({ title, id }) => (
      <CategoryButton
        key={id}
        id={`category-${id}`}
        isActive={id === selectedCategoryId}
        onClick={onCategorySelect(id)}
      >
        <Typography size="lg" weight="bold">
          {title}
        </Typography>
      </CategoryButton>
    ))
  ), [categories]);

  const memoizedSubcategories = useMemo(() => (
    subcategories.map(({ title, id }) => (
      <Link
        id={`subcategory-${id}`}
        key={id}
        to={`menu-item-from-subcategory-${id}`}
        spy
        smooth
        offset={-(theme.sizing.menuBar.height * 2)}
      >
        <SubcategoryButton
          primary
          isActive={id === selectedSubcategoryId}
          onClick={onSubcategorySelect(id)}
        >
          <Typography size="lg" weight="bold">
            {title}
          </Typography>
        </SubcategoryButton>
      </Link>
    ))
  ), [subcategories, selectedSubcategoryId]);

  return (
    <Wrapper column>
      <Container id="categories-container" itemsCenter padding="md">
        {memoizedСategories}
      </Container>
      {Boolean(subcategories.length) && (
        <Container id="subcategories-container" itemsCenter padding="md" subcategories>
          {memoizedSubcategories}
        </Container>
      )}
    </Wrapper>
  );
};

const CategoryButton = styled(Button.Rectangular)`
  ${({ isActive, theme: { color } }) => css`
    color: ${color.strawberry};

    ${isActive && `
      background-color: ${color.strawberry};
      color: ${color.white};
    `}
  `}
`;

const SubcategoryButton = styled(Button.Rectangular)`
  ${({ isActive, theme: { color } }) => css`
    color: ${color.white};
    background-color: ${color.strawberry};

    ${isActive && `
      background-color: ${color.white};
      color: ${color.strawberry};
    `}
  `}
`;

const Wrapper = styled(Flex)`
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const Container = styled(Flex)`
  ${({
    subcategories,
    theme: { color, spacing },
  }) => css`
    display: inline-block;
    background-color: ${subcategories ? color.strawberry : color.white};
    overflow: auto;
    white-space: nowrap;

    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    &::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
      display: none;
    }

    a:not(:last-of-type) {
      margin-right: ${spacing.sm};
    }

    ${Button.Rectangular} {
      padding: ${spacing.sm} ${spacing.md};

      &:not(:first-of-type) {
        margin-left: ${spacing.xs};
      }
    }
  `}
`;

MenuHeader.defaultProps = {
  onSubcategorySelect: () => {},
  selectedSubcategoryId: null,
};

MenuHeader.propTypes = {
  categories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  subcategories: arrayOf(exact({
    title: string,
    id: number,
  })).isRequired,
  onCategorySelect: func.isRequired,
  onSubcategorySelect: func,
  selectedCategoryId: number.isRequired,
  selectedSubcategoryId: number,
};

export default MenuHeader;
