import React from 'react';
import styled, { css } from 'styled-components';
import { elementType, string } from 'prop-types';
import { marginMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const Icon = ({
  SVG, width, height, className,
}) => (
  <SVG className={className} width={width} height={height} />
);

Icon.defaultProps = {
  width: '24px',
  height: '24px',
};

Icon.propTypes = {
  SVG: elementType.isRequired,
  width: string,
  height: string,
  className: string.isRequired,
};

export default styled(Icon)`
  ${() => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}
  `}
`;
