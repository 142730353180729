import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button, Flex, Input, Typography,
} from '@atoms';
import { Registration } from '@templates';

const TableMatePin = ({
  onToastClose,
  hasServerError,
  onBackUp,
  isLoading,
  isFormValid,
  isError,
  error,
  tableNumber,
  tablePin,
  onTablePinChange,
  onTablePinSubmit,
}) => (
  <Registration
    tableNumber={tableNumber}
    onToastClose={onToastClose}
    hasServerError={hasServerError}
    progressValue={100}
    onBackUp={onBackUp}
    isLoading={isLoading}
  >
    <Flex column itemsCenter>
      <Typography color="strawberry" size="xl5" weight="black" marginBottom="xl5">
        {`TABLE ${tableNumber}`}
      </Typography>
      <Typography size="xl4" weight="black" marginBottom="xl3">
        Enter the secret pin
      </Typography>
      {isError && (
        <Flex>
          <Typography color="strawberry" size="lg" weight="bold" marginBottom="md">
            {error}
          </Typography>
        </Flex>
      )}
      <Input
        type="text"
        id="tablePin"
        name="tablePin"
        placeholder="529"
        maxLength="3"
        pattern="[0-9]*"
        value={tablePin}
        isError={isError || !isFormValid}
        onChange={onTablePinChange}
        data-cy="tablePin-input"
      />
      <Button.Circular
        disabled={isError || !isFormValid}
        primary
        diameter="208"
        marginTop="xl5"
        onClick={onTablePinSubmit}
        data-cy="join"
      >
        <Typography size="xl5" weight="black">
          Join the table
        </Typography>
      </Button.Circular>
    </Flex>
  </Registration>
);

TableMatePin.propTypes = {
  onToastClose: func.isRequired,
  hasServerError: bool.isRequired,
  onBackUp: func.isRequired,
  isLoading: bool.isRequired,
  isFormValid: bool.isRequired,
  isError: bool.isRequired,
  tableNumber: string.isRequired,
  error: string.isRequired,
  tablePin: string.isRequired,
  onTablePinChange: func.isRequired,
  onTablePinSubmit: func.isRequired,
};

export default TableMatePin;
