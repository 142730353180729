import React, { useMemo } from 'react';
import {
  arrayOf, bool, exact, func, string, number,
} from 'prop-types';
import { Flex } from '@atoms';
import { MenuCategory } from '@molecules';
import { StaticMenu } from '@templates';

const StaticMenuCategories = ({
  onCategorySelect,
  categories,
  isLoading,
  hasServerError,
}) => {
  const memoizedCategories = useMemo(() => (
    categories.map(({ title, imageSrc, id }) => (
      <MenuCategory
        key={id}
        title={title}
        imageSrc={imageSrc}
        onCategorySelect={onCategorySelect(id)}
      />
    ))
  ), [categories]);

  return (
    <StaticMenu
      isLoading={isLoading}
      hasBackToTopButton
      hasBackUpButton={false}
      hasServerError={hasServerError}
    >
      <Flex wrapFlex>
        {memoizedCategories}
      </Flex>
    </StaticMenu>
  );
};

StaticMenuCategories.propTypes = {
  isLoading: bool.isRequired,
  onCategorySelect: func.isRequired,
  hasServerError: bool.isRequired,
  categories: arrayOf(exact({
    title: string,
    imageSrc: string,
    id: number,
  })).isRequired,
};

export default StaticMenuCategories;
