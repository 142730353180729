/* eslint-disable no-nested-ternary */
import routes from './routes';

// < ----- CONSTANTS ----- > //
export const TABLE_PIN_LENGTH = 3;
export const RANGE_MIN_VALUE = 0;
export const RANGE_MAX_VALUE = 100;

// we're storing stripe key in constants because it's public key
// and there's no need to hide it anywhere
// eslint-disable-next-line max-len
// const STRIPE_DEV_PUBLIC_KEY = 'pk_test_51PB4eyGLjwZQ1439dUb3JGFPBvan7NXOCbSVKsZdIpT0Em3pDIXDhzYVH2bbkA3N0ZraRB4BinO3houm3SkjpetR00jBDnRC7K'//pk_test_51HzjxcAZIEc3NFgpEM9WSNxwuUNCLSWfsv9oBl7m9QL182MHbkpHKAf6xdrNdsM22L82nLAVp0bP6iTIP2VgsiEE00FIH2u1AP';
// eslint-disable-next-line max-len
const STRIPE_GOLFUTURE_PUBLIC_KEY = 'pk_live_51IgwnCHlUkqrnL8Tu9nl1LNWSQ6NikvcjoMnSdtENv6Ig8krX2NwTAOhW5t00gAx8gGf6OYb5SOD4bWIK6H3QE2A00T9PHlxTe';
// eslint-disable-next-line max-len
const STRIPE_PROD_PUBLIC_KEY = 'pk_live_51HzjxcAZIEc3NFgpq39qEQAQk64qHdLvexUQlOkbJ1o1WIiWbgYhIgXhzQcD3fuYB90DE5ArHnideWEqOOZAsx6O00YRE9reA2';
// eslint-disable-next-line max-len
const STRIPE_GEORGES_PUBLIC_KEY = 'pk_live_51JkFLBF56VpEr723Gh4j1jCQV0ISjwT0y55SSJVXhXa1cbwpFndgrOg3wfDKpKXQ1JeCeGnos1imS63tY2qZNW3w00iARNQ8OV';
// eslint-disable-next-line max-len
// const STRIPE_BOREDBREWING_PUBLIC_KEY = 'pk_live_51PB4eyGLjwZQ1439J8kAq8K49mEqhPCrSkKJMwObFNZABZSTAMy16BPLwVmdRbmzkTCCxr5QG4rFJu0MDANU63E100WUUcqduO';
// eslint-disable-next-line max-len
const STRIPE_BOREDBREWING_TEST_PUBLIC_KEY = 'pk_test_51PB4eyGLjwZQ1439dUb3JGFPBvan7NXOCbSVKsZdIpT0Em3pDIXDhzYVH2bbkA3N0ZraRB4BinO3houm3SkjpetR00jBDnRC7K';

const getStripePublicKey = () => {
  switch (true) {
    case window.location.host === 'golfuture.ordo.menu':
      // live Stripe version
      return STRIPE_GOLFUTURE_PUBLIC_KEY;
    case window.location.host === 'boredbrewing.ordo.menu':
      // live Stripe version
      return STRIPE_PROD_PUBLIC_KEY; // STRIPE_BOREDBREWING_PUBLIC_KEY;
    case window.location.host === 'georges.ordo.menu':
      // live Stripe version
      return STRIPE_GEORGES_PUBLIC_KEY;
    case ['madisons.ordo.menu', 'stage.ordo.menu'].includes(window.location.host):
      // live Stripe version
      return STRIPE_PROD_PUBLIC_KEY;
    default:
      // test Stripe version
      return STRIPE_BOREDBREWING_TEST_PUBLIC_KEY;
  }
};

export const STRIPE_PUBLIC_KEY = getStripePublicKey();

export const SENTRY_DSN = 'https://737273833c884713b15f979e5ac77139@o547679.ingest.sentry.io/5684495';

export const restaurantContactPhone = {
  madisons: {
    phoneNumberUrl: '+1-403-452-4970',
    phoneNumber: '403 452 4970',
  },
  golfuture: {
    phoneNumberUrl: '+1-403-640-3555',
    phoneNumber: '403 640 3555',
  },
  georges: {
    phoneNumberUrl: ' 1.800.661.2123',
    phoneNumber: '1.800.661.2123',
  },
  boredbrewing: {
    phoneNumberUrl: '+1-403-452-4970',
    phoneNumber: '403 452 4970',
  },
};

// < ----- NETWORK ----- > //
const getBaseUrl = hostName => {
  switch (true) {
    case hostName === 'golfuture.ordo.menu':
      return 'https://api-golfuture.ordo.menu';
    case hostName === 'boredbrewing.ordo.menu':
      return 'https://api-boredbrewing.ordo.menu';
    case hostName === 'georges.ordo.menu':
      return 'https://api-georges.ordo.menu';
    case hostName === 'madisons.ordo.menu':
      // TODO: update BE url to https://api-madisons.ordo.menu
      return 'https://api.ordo.menu';
    case hostName.includes('dev.'):
      return 'https://api-dev.ordo.menu';
    case hostName.includes('stage.'):
      return 'https://api-stage.ordo.menu';
    default:
      return 'http://localhost:3000';
  }
};

export const isPreauthAvailable = window.location.host === 'golfuture.ordo.menu'
  || window.location.host === 'georges.ordo.menu'
  || window.location.host === 'dev.ordo.menu';

export const BASE_URL = getBaseUrl(window.location.host);

export const UNAUTHORIZED = 401;

export const STRIPE_PAYMENT_REQUEST_OBJECT = {
  country: 'CA',
  currency: 'cad',
  total: {
    label: 'Pre-authorization',
    amount: 10000,
  },
  requestPayerName: true,
  requestPayerEmail: true,
};

// < ----- ENUM ----- > //
export const PREAUTH_FAILED = 'FAILED';
export const PREAUTH_SUCCESS = 'SUCCESS';

export const TOKEN = 'token';
export const TABLE_NUMBER = 'tableNumber';
export const SESSION_USER_ID = 'sessionUserId';
export const USER_ID = 'userId';

export const ORDO_CLOSING = 'closing';

export const OPEN = 'open';
export const BILL_SPLITTING = 'bill_splitting';
export const CLOSING = 'closing';
export const CLOSED = 'closed';
export const routeBySessionStatus = {
  [OPEN]: routes.menu,
  [BILL_SPLITTING]: routes.myBill,
  [CLOSING]: routes.billReview,
  [CLOSED]: routes.tableNumber,
};
