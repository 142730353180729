const apiEndpoints = {
  tableNumber: 'restaurant_table',
  phoneNumber: 'phone_number',
  verificationCode: 'verification_code',
  user: 'user',
  tablePin: 'table_pin',
  menu: 'menu',
  staticMenu: 'menu/static',
  menuStaff: 'menu/staff',
  ordo: 'ordo',
  closeAllBillsMyOrdo: 'close_all_bills/my_ordo',
  myBill: 'my_bill',
  closeAllBillsMyBill: 'close_all_bills/my_bill',
  payMyBill: 'pay_my_bill',
  billSession: 'bill/session',
  checkBillStatus: 'bill/check_bill_status',
  addItemToOrdoByStaff: 'add_item_to_ordo',
  editItemInOrdo: 'edit_item_in_ordo',
  getOneTimeToken: 'session_user/one_time_token',
  preAuthorization: 'pre_authorization',
};

export default apiEndpoints;

