import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addTableNumber, verifyTableNumber, setSessionStatus,
} from '@store/registration/duck';
import { routes } from '@constants';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { TableNumber as View } from '@views';
import { storage } from '@utils';
import { getParameterByName } from '@helpers/registration';
import { isClientError } from '@helpers/errorHandling';

const TableNumber = () => {
  const {
    verifyTableNumberPhase,
    verifyTableNumberError,
    tableNumber: tableNumberFromStore,
  } = useSelector(store => store.registrationStore);

  const [tableNumber, setTableNumber] = useState(tableNumberFromStore || '');
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [hasServerError, setHasServerError] = useState(false);
  const [isTooltipOpened, setTooltipOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setSessionStatus('');
    const tableNumberFromUrl = getParameterByName('tableNumber', window.location.href);
    if (tableNumberFromUrl) {
      // remove url with ?tableNumber parameter to allow user use browser 'go back' from phone-number screen
      history.replace(routes.tableNumber);

      const upperCaseTableNumber = tableNumberFromUrl.toUpperCase();
      setTableNumber(upperCaseTableNumber);
      handleTableNumberSubmit(upperCaseTableNumber)();
    }
  }, []);

  useEffect(() => {
    if (verifyTableNumberPhase === SUCCESS) {
      storage.setTableNumber(tableNumber);
      dispatch(addTableNumber({ tableNumber }));
      history.push(routes.phoneNumber);
    }

    if (verifyTableNumberPhase === FAILURE) {
      if (verifyTableNumberError && isClientError(verifyTableNumberError.data.status)) { // Case when we get 4** error
        setIsError(true);
        setError(verifyTableNumberError.data.error);
      } else { // Case when we get 5** error
        setHasServerError(true);
      }
    }
  }, [verifyTableNumberPhase]);

  const isTableNumberValid = useMemo(() => (
    Boolean(tableNumber.length)
  ), [tableNumber]);

  const handleTableNumberChange = e => {
    if (e.target.validity.valid) {
      const tableName = (e.target.value).toUpperCase();
      setTableNumber(tableName);
      setIsError(false);
    }
  };

  const handleTableNumberSubmit = tableNumberValue => () => {
    dispatch(verifyTableNumber({ tableNumber: tableNumberValue }));
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(prevIsTooltipOpen => !prevIsTooltipOpen);
  };

  const handleRedirectToStaticMenu = () => {
    history.push(routes.staticMenu);
  };

  return (
    <View
      isLoading={verifyTableNumberPhase === LOADING}
      isFormValid={isTableNumberValid}
      hasServerError={hasServerError}
      isError={isError}
      error={error}
      tableNumber={tableNumber}
      isTooltipOpened={isTooltipOpened}
      onTooltipOpen={handleTooltipOpen}
      onTableNumberChange={handleTableNumberChange}
      onTableNumberSubmit={handleTableNumberSubmit}
      onRedirectToStaticMenu={handleRedirectToStaticMenu}
    />
  );
};

export default TableNumber;
