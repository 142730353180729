import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import menuStore, {
  menuStorePersistWhitelist,
} from './menu/duck';
import myOrdoStore, {
  myOrdoStorePersistWhitelist,
} from './myOrdo/duck';
import registrationStore, {
  registrationStorePersistWhitelist,
} from './registration/duck';
import socketStore, {
  socketStorePersistWhitelist,
} from './socket/duck';
import myBillStore, {
  myBillStorePersistWhitelist,
} from './myBill/duck';

const menuStorePersistConfig = {
  key: 'menuStore',
  storage,
  whitelist: menuStorePersistWhitelist,
};

const registrationStorePersistConfig = {
  key: 'registrationStore',
  storage,
  whitelist: registrationStorePersistWhitelist,
};

const myOrdoStorePersistConfig = {
  key: 'myOrdoStore',
  storage,
  whitelist: myOrdoStorePersistWhitelist,
};

const socketStorePersistConfig = {
  key: 'socketStore',
  storage,
  whitelist: socketStorePersistWhitelist,
};

const myBillStorePersistConfig = {
  key: 'myBillStore',
  storage,
  whitelist: myBillStorePersistWhitelist,
};

const rootReducer = combineReducers({
  menuStore: persistReducer(menuStorePersistConfig, menuStore),
  myOrdoStore: persistReducer(myOrdoStorePersistConfig, myOrdoStore),
  registrationStore: persistReducer(registrationStorePersistConfig, registrationStore),
  socketStore: persistReducer(socketStorePersistConfig, socketStore),
  myBillStore: persistReducer(myBillStorePersistConfig, myBillStore),
});

export default rootReducer;
