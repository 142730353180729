import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItemDetails as View } from '@views';
import { LOADING, SUCCESS } from '@constants/requestPhase';
import { routes } from '@constants';
import {
  getMenuCategories,
  getMenuSubcategories,
  getMenuOptions,
  getMenuSizes,
  getItemPrices,
  getOptionsWithValuesSelected,
  incrementOptionValueQuantity,
  decrementOptionValueQuantity,
} from '@helpers/menuItemDetails';
import { useViewport } from '@hooks';
import { clearOneTimeToken } from '@store/registration/duck';
import { setSelectedCategory } from '@store/menu/duck';
import { addItemToOrdoByStaff, editItemInOrdo } from '@store/myOrdo/duck';
import { getValueFromQueryParams } from '@helpers/common';
import { reportError } from '../../sentry';

const ChangeOrdoMenuItemDetails = () => {
  const {
    categories, subcategories, options, optionValues, items, sizes, selectedCategoryId,
  } = useSelector(state => state.menuStore);

  const { addItemToOrdoByStaffPhase, editItemInOrdoPhase } = useSelector(state => state.myOrdoStore);

  const screenSize = useViewport();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { itemId: idFromParams } = useParams();
  const itemId = parseInt(idFromParams, 10);

  const token = getValueFromQueryParams(location.search, 'token');
  const sessionUserId = getValueFromQueryParams(location.search, 'sessionUserId');
  const ordoId = getValueFromQueryParams(location.search, 'ordoId');
  let itemToEdit;
  try {
    itemToEdit = JSON.parse(decodeURIComponent(getValueFromQueryParams(location.search, 'menuItem')));
  } catch (error) {
    reportError(
      'Failed to parse menu item from query params',
      'oneTimeToken',
      token,
      location.search,
    );
  }

  const [isDrawerOpened, setDrawerOpen] = useState(true);
  const [itemQuantity, setItemQuantity] = useState(itemToEdit ? 0 : 1);
  const [sizeSelected, setSizeSelected] = useState(
    itemToEdit ? itemToEdit.size.id
      : items[itemId].sizeIds.find(sizeId => sizes[sizeId].specialPrice)
  || items[itemId].sizeIds.find(sizeId => sizes[sizeId].preSelected)
  || items[itemId].sizeIds[0],
  );
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionsList, setOptionsList] = useState(null);

  const [itemPrice, itemSpecialPrice] = optionsList ? getItemPrices(sizes, sizeSelected, optionsList) : [0, 0];

  useEffect(() => {
    if (!token) {
      history.push(routes.tableNumber);
    }
  }, []);

  useEffect(() => {
    if (editItemInOrdoPhase === SUCCESS) {
      history.goBack();
    }
  }, [editItemInOrdoPhase]);

  useEffect(() => {
    const selectedSizeOptions = getMenuOptions(options, sizes, sizeSelected, optionValues,
      itemToEdit ? itemToEdit.size.options : []);
    setOptionsList(selectedSizeOptions);
  }, [sizeSelected]);

  const handleSizeSelect = sizeId => () => {
    setSizeSelected(sizeId);
  };

  const handleOptionValueSelect = (optionId, valueId) => () => {
    setOptionsList(prevOptions => getOptionsWithValuesSelected(prevOptions, optionId, valueId));
  };

  const handleDecrement = () => {
    setItemQuantity(prevQuantity => (
      prevQuantity === 1 ? prevQuantity : prevQuantity - 1
    ));
  };

  const handleIncrement = () => {
    setItemQuantity(prevQuantity => prevQuantity + 1);
  };

  const handleModalChangeState = () => {
    setIsModalOpen(modalState => !modalState);
  };

  const handleCategorySelect = categoryId => () => {
    dispatch(setSelectedCategory({
      categoryId,
    }));

    history.push(`${routes.changeOrdoMenuItems}${location.search}`);
  };

  const handleSubcategorySelect = subcategoryId => () => {
    const categoryIds = Object.keys(categories.byId);

    // Searching for category id of selected subcategory
    const categoryId = categoryIds.filter(id => (
      categories.byId[id].subcategoryIds.includes(subcategoryId)));

    dispatch(setSelectedCategory({
      categoryId,
    }));

    history.push(`${routes.changeOrdoMenuItems}${location.search}`);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleOptionQuantityDecrement = (valueId, optionId) => () => {
    setOptionsList(prevOptions => decrementOptionValueQuantity(prevOptions, optionId, valueId));
  };

  const handleOptionQuantityIncrement = (valueId, optionId) => () => {
    setOptionsList(prevOptions => incrementOptionValueQuantity(prevOptions, optionId, valueId));
  };

  const handleMenuItemAdd = () => {
    if (itemToEdit) {
      const item = {
        ...(sessionUserId && { sessionUserId: parseInt(sessionUserId, 10) }),
        ...(ordoId && { ordoId: parseInt(ordoId, 10) }),
        menuItem: {
          id: itemToEdit ? itemToEdit.id : parseInt(itemId, 10),
          ...(itemToEdit && { menuItemId: itemId }),
          specialInstructions: specialInstructions === ''
            ? null
            : specialInstructions,
          size: {
            id: parseInt(sizeSelected, 10),
            options: optionsList.map(option => ({
              id: option.id,
              values: option.valuesSelected,
            })),
          },
        },
      };
      dispatch(editItemInOrdo(item, token));
      dispatch(clearOneTimeToken());
    } else {
      const itemsToAdd = {
        ...(sessionUserId && { sessionUserId: parseInt(sessionUserId, 10) }),
        ...(ordoId && { ordoId: parseInt(ordoId, 10) }),
        menuItems: new Array(itemQuantity).fill().reduce(acc => {
          acc.push({
            id: itemToEdit ? itemToEdit.id : parseInt(itemId, 10),
            ...(itemToEdit && { menuItemId: itemId }),
            specialInstructions: specialInstructions === ''
              ? null
              : specialInstructions,
            size: {
              id: parseInt(sizeSelected, 10),
              options: optionsList.map(option => ({
                id: option.id,
                values: option.valuesSelected,
              })),
            },
          });

          return acc;
        }, []),
      };
      dispatch(addItemToOrdoByStaff(itemsToAdd, token));
    }
  };

  const handleBackUp = () => {
    history.push(`${routes.changeOrdoMenuItems}${location.search}`);
  };

  const handleSpecialIntructionsChange = e => {
    setSpecialInstructions(e.target.value);
  };

  const handleSpecialIntructionsClose = () => {
    setIsModalOpen(false);
    setSpecialInstructions('');
  };

  const handleDrawerClose = () => {
    if (sessionUserId) {
      setDrawerOpen(false);
    }
  };

  const handleDrawerChangeState = () => {
    if (sessionUserId) {
      setDrawerOpen(prevState => !prevState);
    }
  };

  const handleGoBack = () => {
    history.push(`${routes.changeOrdoMenuCategories}${location.search}`);
  };

  const memoizedCategories = useMemo(() => (
    !itemToEdit ? getMenuCategories(categories) : []
  ), [selectedCategoryId]);

  const memoizedSubcategories = useMemo(() => (
    !itemToEdit ? getMenuSubcategories(subcategories, categories, selectedCategoryId) : []
  ), [selectedCategoryId]);

  const memoizedSize = useMemo(() => (
    getMenuSizes(items, parseInt(itemId, 10), sizes, sizeSelected)
  ), [sizeSelected]);

  // Disable 'Add to ordo button' if item's options are not loaded yet
  const isAddToOrdoDisabled = !optionsList || !!(optionsList.find(option => (
    option.requiredValue && !option.valuesSelected.length
  )));

  return (
    <View
      quantity={itemQuantity}
      onIncrement={handleIncrement}
      onDecrement={handleDecrement}
      onDrawerClose={handleDrawerClose}
      onDrawerChangeState={handleDrawerChangeState}
      isBackUpButtonVisible={!!sessionUserId}
      isLoading={addItemToOrdoByStaffPhase === LOADING || editItemInOrdoPhase === LOADING}
      isPlaceOrdoVisible={false}
      onOptionQuantityIncrement={handleOptionQuantityIncrement}
      onOptionQuantityDecrement={handleOptionQuantityDecrement}
      onGoBack={handleGoBack}
      screenHeight={screenSize.height}
      categories={memoizedCategories}
      subcategories={memoizedSubcategories}
      selectedCategoryId={selectedCategoryId}
      isAddToOrdoDisabled={isAddToOrdoDisabled}
      isModalOpen={isModalOpen}
      size={memoizedSize}
      onSpecialInstructionsClose={handleSpecialIntructionsClose}
      onModalChangeState={handleModalChangeState}
      onSizeSelect={handleSizeSelect}
      onOptionValueSelect={handleOptionValueSelect}
      onCategorySelect={handleCategorySelect}
      onSpecialInstructionsChange={handleSpecialIntructionsChange}
      specialInstructions={specialInstructions}
      onSubcategorySelect={handleSubcategorySelect}
      title={items[itemId].name}
      imageSrc={items[itemId].image[0]?.src}
      price={itemPrice}
      specialPrice={itemSpecialPrice}
      description={items[itemId].description}
      isDrawerOpened={isDrawerOpened}
      optionsList={optionsList || []}
      onDrawerOpen={handleDrawerOpen}
      onMenuItemAdd={handleMenuItemAdd}
      onBackUp={handleBackUp}
    />
  );
};

export default ChangeOrdoMenuItemDetails;
