import styled, { css } from 'styled-components';
import { Input } from '@atoms';
import { marginMixin } from '@mixins';

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const TipInput = styled(Input)`
${({
    theme: {
      color, typography, spacing,
    },
    percent,
  }) => css`
    ${margin}
    ${marginTop}
    ${marginRight}
    ${marginBottom}
    ${marginLeft}

    width: ${percent ? '120px' : '100%'};
    padding: ${spacing.sm};
    color: ${color.black};
    font-size: ${percent ? typography.size.xl4 : typography.size.xl6};
    font-weight: ${typography.weight.bold};
    border-radius: ${percent ? '8px' : '16px'};
  `}
`;

export default TipInput;
