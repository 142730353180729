import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './App';
import { initSentry } from './sentry';

initSentry();

const root = document.getElementById('root');

render(
  <StrictMode>
    <App />
  </StrictMode>, root,
);
