import { apiEndpoints } from '@constants';
import { client } from '@utils';

const submitPhoneNumber = data => (
  client.unauthorized.post(apiEndpoints.phoneNumber, data)
);

const submitVerificationCode = data => (
  client.unauthorized.post(apiEndpoints.verificationCode, {
    ...data,
    currentUserDate: new Date().toISOString(),
    currentWeekDay: new Date().getDay(),
    userTimezoneOffset: new Date().getTimezoneOffset(),
  })
);

const submitPersonalDetails = data => (
  client.unauthorized.post(apiEndpoints.user, {
    ...data,
    currentUserDate: new Date().toISOString(),
    currentWeekDay: new Date().getDay(),
    userTimezoneOffset: new Date().getTimezoneOffset(),
  })
);

const submitTablePin = data => (
  client.unauthorized.post(apiEndpoints.tablePin, {
    ...data,
    currentUserDate: new Date().toISOString(),
    currentWeekDay: new Date().getDay(),
    userTimezoneOffset: new Date().getTimezoneOffset(),
  })
);

const verifyTableNumber = data => (
  client.unauthorized.post(apiEndpoints.tableNumber, data)
);

const getOneTimeToken = () => (
  client.authorized.get(apiEndpoints.getOneTimeToken)
);

export {
  submitPhoneNumber,
  submitVerificationCode,
  submitPersonalDetails,
  submitTablePin,
  verifyTableNumber,
  getOneTimeToken,
};
