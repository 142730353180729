import React, { useEffect } from 'react';
import {
  bool, node, func, number, string,
} from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex, Icon, Loader } from '@atoms';
import { ErrorToast } from '@molecules';
import { Footer } from '@organisms';

import { getRestaurantRelatedData } from '@helpers/registration';

const Registration = ({
  tablePin,
  tableNumber,
  seatNumber,
  children,
  isLoading,
  onBackUp,
  hasBackUpButton,
  progressValue,
  hasServerError,
  onToastClose,
  isTablePinVisible,
}) => {
  useEffect(() => {
    document.title = 'Registration';
  }, []);

  const { logo, logoWidth, logoHeight } = getRestaurantRelatedData();

  const isGeorges = window.location.host === 'georges.ordo.menu';

  return (
    <>
      <Wrapper column>
        <ProgressBar grow={0} progressValue={progressValue} />
        <Flex grow={0} justifyCenter padding={isGeorges ? 'xl3' : 'xl6'}>
          <Icon SVG={logo} width={logoWidth} height={logoHeight} />
        </Flex>
        <Flex grow={0} padding="xl3">
          {children}
        </Flex>
        <Footer
          tablePin={tablePin}
          tableNumber={tableNumber}
          seatNumber={seatNumber}
          isTablePinVisible={isTablePinVisible}
          onBackUp={onBackUp}
          hasBackUpButton={hasBackUpButton}
        />
      </Wrapper>
      {isLoading && <Loader />}
      {hasServerError && <ErrorToast onToastClose={onToastClose} />}
    </>
  );
};

const Wrapper = styled(Flex)`
  ${({ theme: { sizing } }) => css`
    position: relative;
    min-height: calc(100% - ${sizing.footer.height});
    margin-bottom: ${sizing.footer.height};
    text-align: center;
  `}
`;

const ProgressBar = styled(Flex)`
  ${({ theme: { color }, progressValue }) => css`
    width: ${progressValue}%;
    transition: translateX(-100%);
    height: 10px;
    background: linear-gradient(270deg, ${color.pumpkinOrange} 0%, ${color.strawberry} 43%);
  `}
`;

Registration.defaultProps = {
  isTablePinVisible: false,
  isLoading: false,
  onToastClose: () => {},
  onBackUp: () => {},
  hasBackUpButton: true,
  hasServerError: false,
  tableNumber: '',
  tablePin: '',
  seatNumber: '',
};

Registration.propTypes = {
  tablePin: string,
  tableNumber: string,
  seatNumber: string,
  isTablePinVisible: bool,
  onToastClose: func,
  hasServerError: bool,
  progressValue: number.isRequired,
  children: node.isRequired,
  hasBackUpButton: bool,
  isLoading: bool,
  onBackUp: func,
};

export default Registration;
