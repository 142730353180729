import React, { useCallback } from 'react';
import {
  bool, func, string, number,
} from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Button, Flex, Icon, Typography, Circle, ImageBackground,
} from '@atoms';
import { ReactComponent as Plus } from '@assets/plus.svg';
import { formatPrice } from '@helpers/myOrdo';

const MenuItem = ({
  imageSrc,
  isMustTry,
  name,
  price,
  specialPrice,
  subtitle,
  onMenuItemSelect,
  subcategoryId,
  isStatic,
}) => {
  const getItemPriceComponent = useCallback(() => {
    if (!specialPrice && specialPrice !== 0) {
      return (
        <Circle
          diameter={33}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="darkGrey"
        >
          <Typography size={(price / 100) % 1 ? 'xs' : 'lg'} color="white" weight="bold">
            {`$${formatPrice(price)}`}
          </Typography>
        </Circle>
      );
    }

    return (
      <StyledPriceWrapper row justifyEnd>
        <Circle
          diameter={33}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="strawberry"
        >
          <Typography size={(price / 100) % 1 ? 'xs' : 'lg'} color="white" weight="medium">
            {`$${formatPrice(price)}`}
          </Typography>
        </Circle>
        <Circle
          diameter={33}
          grow={0}
          justifyCenter
          itemsCenter
          backgroundColor="white"
        >
          <Typography size={(specialPrice / 100) % 1 ? 'xs' : 'lg'} color="grannySmith" weight="bold">
            {`$${formatPrice(specialPrice)}`}
          </Typography>
        </Circle>
      </StyledPriceWrapper>
    );
  }, [price, specialPrice]);

  return (
    <Wrapper
      column
      padding="md"
      imageSrc={imageSrc}
      onClick={onMenuItemSelect}
      id={`menu-item-from-subcategory-${subcategoryId}`}
    >
      <Flex justifyBetween={!isStatic} justifyEnd={isStatic}>
        {!isStatic && (
          <Button.Circular secondary onClick={onMenuItemSelect} diameter={33}>
            <Flex itemsCenter justifyCenter>
              <Icon SVG={Plus} width="11px" height="11px" />
            </Flex>
          </Button.Circular>
        )}
        {getItemPriceComponent()}
      </Flex>
      <Flex column justifyEnd>
        {isMustTry && (
          <Typography size="xs" weight="bold" color="white">
            [*A MUST TRY*]
          </Typography>
        )}
        <StyledTypography size="lg" weight="black" color="white">
          {name}
        </StyledTypography>
        {subtitle && (
          <SubtitleWrapper size="md" weight="bold" color="white">
            {subtitle}
          </SubtitleWrapper>
        )}
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled(ImageBackground)`
  ${({ theme: { color, sizing } }) => css`
    width: 50%;
    max-width: 50%;
    height: ${sizing.tile.height};
    border: 1px solid ${color.warmGrey};
  `}
`;

const StyledTypography = styled(Typography)`
  ${({ theme: { color } }) => css`
    text-shadow: 1px 1px ${color.black};
  `}
`;

const SubtitleWrapper = styled(Typography)`
  ${({ theme: { color } }) => css`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 1px 1px ${color.black};
  `}
`;

const StyledPriceWrapper = styled(Flex)`
  ${({ theme: { spacing } }) => css`
    div:first-child {
      margin-right: ${spacing.md};

      p {
        text-decoration: line-through;
      }
    }
  `}
`;

MenuItem.defaultProps = {
  isMustTry: false,
  imageSrc: '',
  specialPrice: null,
  isStatic: false,
};

MenuItem.propTypes = {
  imageSrc: string,
  isMustTry: bool,
  onMenuItemSelect: func.isRequired,
  name: string.isRequired,
  price: number.isRequired,
  specialPrice: number,
  subtitle: string.isRequired,
  subcategoryId: string.isRequired,
  isStatic: bool,
};

export default MenuItem;
