import { node } from 'prop-types';
import styled from 'styled-components';
import { flexbox, marginMixin, paddingMixin } from '@mixins';

const {
  flex,
  flexDisplay,
  flexDirection,
  flexWrap,
  alignContent,
  alignItems,
  alignSelf,
  justifyContent,
} = flexbox;

const {
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
} = marginMixin;

const {
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
} = paddingMixin;

const Flex = styled.div`
  ${alignContent}
  ${alignItems}
  ${alignSelf}
  ${flex}
  ${flexDirection}
  ${flexDisplay}
  ${flexWrap}
  ${justifyContent}

  ${margin}
  ${marginTop}
  ${marginRight}
  ${marginBottom}
  ${marginLeft}

  ${padding}
  ${paddingTop}
  ${paddingRight}
  ${paddingBottom}
  ${paddingLeft}
`;

Flex.propTypes = {
  children: node.isRequired,
};

export default Flex;
