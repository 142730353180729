import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStaticMenu, setSelectedCategory } from '@store/menu/duck';
import { StaticMenuCategories } from '@views';
import { routes } from '@constants';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { getMenuCategories } from '@helpers/menuCategories';
import { useHistory } from 'react-router-dom';

const StaticMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories, loadStaticMenuPhase } = useSelector(state => state.menuStore);
  const [hasServerError, setHasServerError] = useState(false);

  useEffect(() => {
    dispatch(loadStaticMenu());
  }, []);

  useEffect(() => {
    if (loadStaticMenuPhase === FAILURE) {
      setHasServerError(true);
    }
  }, [loadStaticMenuPhase]);

  const handleCategorySelect = categoryId => () => {
    dispatch(setSelectedCategory({
      categoryId,
    }));

    history.push(routes.staticMenuItems);
  };

  const handleToastClose = () => {
    setHasServerError(false);
  };

  const memoizedCategories = useMemo(() => getMenuCategories(categories)
    .filter(category => category.title !== 'Assistance'), [categories]);

  return (
    <StaticMenuCategories
      onCategorySelect={handleCategorySelect}
      categories={memoizedCategories}
      isLoading={loadStaticMenuPhase === LOADING}
      hasServerError={hasServerError}
      onToastClose={handleToastClose}
    />
  );
};

export default StaticMenu;
