import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  string, exact, arrayOf, func, number, bool,
} from 'prop-types';
import { Flex, Typography } from '@atoms';
import { ItemOptionValue } from '@molecules';

const MenuItemOptions = ({
  allowValueMultipleSelection,
  onOptionQuantityDecrement,
  onOptionQuantityIncrement,
  id,
  name,
  maxRequiredValuesAmount,
  values,
  valuesSelected,
  onOptionValueSelect,
  requiredValue,
}) => {
  const memoizedValues = useMemo(() => values.map(value => {
    const currentValue = valuesSelected.find(optionValue => optionValue.id === value.id);

    return (
      <ItemOptionValue
        onOptionQuantityDecrement={onOptionQuantityDecrement}
        onOptionQuantityIncrement={onOptionQuantityIncrement}
        allowValueMultipleSelection={allowValueMultipleSelection}
        quantity={currentValue
          ? currentValue.quantity
          : 0}
        id={value.id}
        optionId={id}
        key={value.id}
        imageSrc={value.imageSrc}
        name={value.name}
        price={value.price}
        isSelected={!!currentValue}
        onOptionValueSelect={onOptionValueSelect}
      />
    );
  }), [valuesSelected]);

  return (
    <Wrapper shrink={0} column paddingTop="xl" paddingBottom="xl">
      <Typography size="md" weight="black">
        {name}
        {requiredValue
          ? (
            <>
              <br />
              <Typography as="span" size="sm" weight="black">
                {`(REQUIRED, CHOOSE UP TO ${maxRequiredValuesAmount})`}
              </Typography>
            </>
          )
          : ''}
      </Typography>
      <Container
        paddingLeft="xl4"
        paddingRight="xl4"
        column
      >
        <Flex display="inline-flex" column={memoizedValues.length > 4}>
          <Flex>
            {memoizedValues.length > 4
              ? memoizedValues.filter((item, index) => !(index % 2))
              : memoizedValues}
          </Flex>
          {memoizedValues.length > 4 && (
            <Flex marginTop="sm">
              {memoizedValues.filter((item, index) => index % 2)}
            </Flex>
          )}
        </Flex>
      </Container>
    </Wrapper>
  );
};

const Container = styled(Flex)`
  overflow: auto;
  white-space: nowrap;
  display: inline-block;

  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  &::-webkit-scrollbar { /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
`;

const Wrapper = styled(Flex)`
  border-top: 1px solid #d9d9d9;
  text-align: center;
`;

MenuItemOptions.propTypes = {
  requiredValue: bool.isRequired,
  allowValueMultipleSelection: bool.isRequired,
  onOptionQuantityDecrement: func.isRequired,
  onOptionQuantityIncrement: func.isRequired,
  id: number.isRequired,
  name: string.isRequired,
  valuesSelected: arrayOf(exact({
    id: number,
    quantity: number,
  })).isRequired,
  maxRequiredValuesAmount: number.isRequired,
  values: arrayOf(exact({
    id: number,
    imageSrc: string,
    name: string,
    price: number,
  })).isRequired,
  onOptionValueSelect: func.isRequired,
};

export default MenuItemOptions;
