import React from 'react';
import styled, { css } from 'styled-components';
import {
  bool, func, string, number,
} from 'prop-types';
import { Flex, Typography, Circle } from '@atoms';
import { formatPrice } from '@helpers/myOrdo';

const ItemSize = ({
  id,
  name,
  price,
  isSelected,
  onSizeSelect,
}) => (
  <Wrapper display="inline-flex" wrapFlex column>
    <SizeFlex
      isSelected={isSelected}
      onClick={onSizeSelect(id)}
      diameter={57}
      justifyCenter
      itemsCenter
    >
      <Typography size="lg" weight="black" color="white">
        {`$${formatPrice(price)}`}
      </Typography>
    </SizeFlex>
    <Typography size="xxs" weight="black" marginTop="sm">
      {name.toUpperCase()}
    </Typography>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  ${({ theme: { spacing, sizing } }) => css`
    max-width: ${sizing.option.width};
    text-align: center;
    white-space: normal;

    :not(:first-child) {
      margin-left: ${spacing.sm};
    }
  `}
`;

const SizeFlex = styled(Circle)`
  ${({
    isSelected,
    theme: { color },
  }) => css`
    background: rgba(27, 186, 69, 0.5);
    border: ${isSelected ? `4px solid ${color.grannySmith}` : 'none'};
  `}
`;

ItemSize.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  price: number.isRequired,
  isSelected: bool.isRequired,
  onSizeSelect: func.isRequired,
};

export default ItemSize;
